import React, {SyntheticEvent, forwardRef, memo, useEffect, useState} from 'react';
import styles from "./input.module.scss";
import clsx from 'clsx';
import {Icon} from "../icon/Icon";
import {Status} from "../status/Status";

type InputProps = {
    readonly id: string;
    readonly name: string;
    readonly label?: string;
    readonly ariaLabel?: string;
    readonly ariaDescribedby?: string;
    readonly placeholder?: string;
    readonly value?: string;
    readonly type?: "email" | "number" | "password" | "text";
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly hasError?: boolean;
    readonly errorMessage?: string;
    readonly isRequired?: boolean;
    readonly maxLength?: number;
    readonly autoComplete?: "on" | "off";
    readonly inputProps?: any;
    readonly onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
    readonly onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
    readonly appearance?: "primary" | "secondary";
    readonly className?: string;
    readonly defaultValue?: string;
    readonly iconPrefix?: string | null;
    readonly pattern?: string | null;
};

export const Input = memo<InputProps>(
    forwardRef(
        (
            {
                type = "text",
                disabled = false,
                isRequired = false,
                iconPrefix = null,
                label,
                hasError,
                onBlur,
                defaultValue,
                onChange,
                className,
                autoComplete = "off",
                id,
                errorMessage,
                ...props
            },
            ref,
        ) => {

            const [focused, setFocused] = useState(false);
            const [typeLocal, setTypeLocal] = useState<string>(type);
            const [valueState, setValueState] = useState<string>("");

            function handleInputFocus(e: React.FormEvent<HTMLInputElement>) {
                setFocused(true);
                setValueState(e.currentTarget.value);
            }

            function handleInputBlur(e: React.FormEvent<HTMLInputElement>) {
                setFocused(false);
                setValueState(e.currentTarget.value);
                if (onBlur) {
                    onBlur(e);
                }
            }

            function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
                setFocused(true);
                setValueState(e.currentTarget.value);
                if (onChange) {
                    onChange(e);
                }
            }

            function handleShowPassword () {
                setTypeLocal("text");
            }

            function handleHidePassword () {
                setTypeLocal(type);
            }

            useEffect(() => {
                setValueState(props.value ? props.value : (defaultValue ? defaultValue : ""));
            }, [props.value, defaultValue]);


            return (
                <div
                    className={clsx(styles.wrapper, {
                        [styles.wrapperFilled]: valueState.length > 0 || focused,
                        [styles.wrapperIconPrefix]: iconPrefix,

                    })}
                >
                    {
                        label &&
                        <label className={styles.label} htmlFor={id}>
                            {label}
                            {
                                isRequired && <span className={styles.inputRequired}>*</span>
                            }
                        </label>
                    }
                    <div
                        className={clsx(styles.field, {
                            [styles.fieldError]: hasError,
                            [styles.fieldTypePassword]: type === "password",
                        })}
                    >
                        {
                            iconPrefix &&
                            <div className={styles.inputIcon}>
                                <Icon icon={iconPrefix} />
                            </div>
                        }
                        <input
                            id={id}
                            className={clsx(styles.input, className, {
                                [styles.inputDisabled]: disabled,
                                [styles.inputError]: hasError,
                                [styles.inputIconPrefix]: iconPrefix,
                                [styles.inputTypePassword]: type === "password",
                            })}
                            onFocus={(e) => handleInputFocus(e)}
                            onBlur={(e) => handleInputBlur(e)}
                            onChange={(e) => handleOnChange(e)}
                            disabled={disabled}
                            //@ts-ignore
                            ref={ref}
                            autoComplete={autoComplete}
                            defaultValue={defaultValue}
                            type={typeLocal}
                            {...props}
                        />
                        {
                            type === "password" &&
                            <div
                                className={styles.fieldPasswordIcon}
                                onMouseDown={handleShowPassword}
                                onMouseUp={handleHidePassword}
                                onTouchStart={handleShowPassword}
                                onTouchEnd={handleHidePassword}
                            >
                                <Icon icon={"eye"} />
                            </div>
                        }
                    </div>
                    {
                        hasError && errorMessage &&
                            <Status type={"error"} message={errorMessage} />
                    }
                </div>

            );
        })
);
