import React, {useState} from "react";
import styles from "./question.module.scss";
import {Box} from "../box/Box";
import {QuestionInterface} from "ts/interfaces/QuestionInterface";
import {Button} from "../../ui/button/Button";
import {QuestionTwo} from "../questions/questions/questionTwo/QuestionTwo";
import {QuestionOne} from "../questions/questions/questionOne/QuestionOne";
import {QuestionThree} from "../questions/questions/questionThree/QuestionThree";
import {QuestionFour} from "../questions/questions/questionFour/QuestionFour";
import {QuestionFive} from "../questions/questions/questionFive/QuestionFive";
import {QuestionSix} from "../questions/questions/questionSix/QuestionSix";
import {QuestionSeven} from "../questions/questions/questionSeven/QuestionSeven";
import {QuestionEight} from "../questions/questions/questionEight/QuestionEight";
import {QuestionNine} from "../questions/questions/questionNine/QuestionNine";
import {QuestionTen} from "../questions/questions/questionTen/QuestionTen";
import {QuestionEleven} from "../questions/questions/questionEleven/QuestionEleven";


type QuestionProps = {
    question: QuestionInterface,
    goPrev: () => void,
    goNext: () => void,
    step: number,
    allSteps: number,
};

export const Question = ({
                             question,
                             step,
                             allSteps,
                             goPrev,
                             goNext
                         }: QuestionProps) => {

    const {
        title,
        description,
        isRequired,
    } = question;

    const [questionIsValid, setQuestionIsValid] = useState(false);

    function handleClickNext () {
        if (questionIsValid) {
            goNext();
        }
    }

    function createMarkup (text: string) {
        return {
            __html: text
        }
    }

    return (
        <div className={styles.questionWrapper}>
            <Box size={"full"}>
                <header className={styles.questionHeader}>
                    <span className={styles.questionStep}>Question {step}</span>
                    <h1 className={"title"}>{title}</h1>
                    {
                        description &&
                        <p className={styles.questionStepDescription}>
                            <span dangerouslySetInnerHTML={createMarkup(description)} />
                        </p>
                    }
                </header>

                <div className={styles.questionMain}>

                    {
                        step === 1 &&
                            <QuestionOne
                                question={question}
                                onValid={setQuestionIsValid}
                            />
                    }
                    {
                        step === 2 &&
                        <QuestionTwo
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }
                    {
                        step === 3 &&
                        <QuestionThree
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }
                    {
                        step === 4 &&
                        <QuestionFour
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }
                    {
                        step === 5 &&
                        <QuestionFive
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }
                    {
                        step === 6 &&
                        <QuestionSix
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }
                    {
                        step === 7 &&
                        <QuestionSeven
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }
                    {
                        step === 8 &&
                        <QuestionEight
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }
                    {
                        step === 9 &&
                        <QuestionNine
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }
                    {
                        step === 10 &&
                        <QuestionTen
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }
                    {
                        step === 11 &&
                        <QuestionEleven
                            question={question}
                            onValid={setQuestionIsValid}
                        />
                    }

                </div>

                <footer className={styles.questionFooter}>
                    {
                        step > 1 &&
                        <div className={styles.questionFooterStart}>
                            <Button onClick={goPrev} typeStyle={"link"} buttonStyle={"dark"}
                                    size={"auto"}
                                    iconLeft={"arrow-left"}>Previous Question</Button>
                        </div>
                    }

                    {
                        step < allSteps &&
                        <div className={styles.questionFooterEnd}>
                            {
                                !isRequired &&
                                <Button onClick={goNext} type={"submit"} typeStyle={"link"} buttonStyle={"ghost"} size={"auto"}>Skip
                                    Question</Button>

                            }
                            <Button
                                type={"button"}
                                onClick={handleClickNext}
                                disabled={!questionIsValid}>
                                {
                                    step < allSteps - 1 &&
                                    <>Next Question</>
                                }
                                {
                                    step === allSteps - 1 &&
                                    <>Summary</>
                                }
                            </Button>
                        </div>
                    }

                </footer>
            </Box>
        </div>
    );
}
