import {AppThunk} from "../store";
import {endpoints} from "../../config/endpoints";
import {Container} from "typedi";
import {ApiService} from "../../services/ApiService";
import {setVacanciesAll, setVacanciesIsLoading, setVacanciesIsLoadingSingle, setVacanciesMatched} from "ts/store/vacancies/vacanciesSlice";
import {VacancyExtendedInterface} from "../../interfaces/VacancyExtendedInterface";
import {RequestVacancyApplication, RequestWithdrawVacancyApplication} from "../../interfaces/api";

export const getVacanciesAll = (): AppThunk => (dispatch) => {

    const api = Container.get(ApiService);

    dispatch(
        setVacanciesIsLoading(true)
    );

    api
        .get(endpoints.vacancies.all)
        .then((res) => {
            dispatch(
                setVacanciesAll(res.data.vacancies)
            );
            dispatch(
                setVacanciesMatched(res.data.matched)
            );
        }).catch((err) => {
        console.log(err);
    }).finally(() => {
        dispatch(
            setVacanciesIsLoading(false)
        );
    });
};




export const getVacancy = (id: string, setVacancy: (vacancy: VacancyExtendedInterface) => void): AppThunk => (dispatch) => {

    const api = Container.get(ApiService);

    dispatch(
        setVacanciesIsLoadingSingle(true)
    );

    api
        .get(endpoints.vacancies.base + id)
        .then((res) => {
            setVacancy(res.data.vacancy);
        }).catch((err) => {
        console.log(err);
    }).finally(() => {
        dispatch(
            setVacanciesIsLoadingSingle(false)
        );
    });
};

export const sendVacancyQuestion = (
    data: {vacancyId: number, message: string},
    onSuccess: () => void
) : AppThunk => async (dispatch) => {
    const api = Container.get(ApiService);

    api
        .post(
            endpoints.vacancies.question,
            data
        )
        .then((res) => {
            onSuccess();
        }).catch((err) => {
        console.log(err);
    }).finally(() => {

    });
}

export const sendVacancyApplication = (
    data: RequestVacancyApplication,
    onSuccess: () => void,
    onError: () => void,
) : AppThunk => async (dispatch) => {

    const api = Container.get(ApiService);
    const formData = new FormData();

    formData.append("vacancyId", data.vacancyId.toString());
    formData.append("noticePeriod", data.noticePeriod.toString());
    formData.append("score", data.score.toString());
    formData.append("matched", data.matched.toString());

    if (data.cv) {
        formData.append("cv", data.cv[0]);
    }

    api
        .post(
            endpoints.application.add,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        .then((res) => {
            // console.log(res);
            if (res.data.state) {
                onSuccess();
            } else {
                onError();
            }
        }).catch((err) => {
        console.log(err);
    }).finally(() => {

    });
}

export const withdrawVacancyApplication = (
    data: RequestWithdrawVacancyApplication,
    onSuccess: () => void
) : AppThunk => async (dispatch) => {

    const api = Container.get(ApiService);


    api
        .put(
            endpoints.application.withdraw,
            {
                id: data.id
            }
        )
        .then((res) => {
            if (res.data.state) {
                onSuccess();
            }
        }).catch((err) => {
        console.log(err);
    }).finally(() => {

    });
}

export const getVacancyApplicationCV = (
    a: number,
    onSuccess: () => void
) : AppThunk => async (dispatch) => {

    const api = Container.get(ApiService);


    api
        .get(
            endpoints.application.cv,
            {
                params: { a: a },
                responseType: 'blob'
            },
        )
        .then((res) => {
            const blob = res.data;
            // const link=document.createElement('a');
            // link.href=window.URL.createObjectURL(blob);
            window.open(window.URL.createObjectURL(blob), '_blank');
            // link.download="Counselpath_your_cv_" + new Date() + ".pdf";
            // link.click();

        }).catch((err) => {
        console.log(err);
    }).finally(() => {

    });
}
