import React, {useEffect, useRef, useState} from "react";
import {QuestionInterface} from "ts/interfaces";
import {useAppDispatch, useAppSelector} from "ts/hooks";
import {selectUserFieldStartPqe, setUserFieldStartPqe} from "ts/store/user/userSlice";
import styles from "./questionSix.module.scss";
// @ts-ignore
import Picker from 'react-month-picker';
import "react-month-picker/css/month-picker.css";
import {MonthDateInterface} from "../../../../../interfaces/MonthDateInterface";
import {Input} from "../../../../ui/input/Input";

type QuestionSixProps = {
    question: QuestionInterface,
    onValid: (isValid: boolean) => void,
    fieldSlug?: string,
};

export const QuestionSix = ({
                                onValid,
                                question,
                                fieldSlug = question.fieldSlug
                         }: QuestionSixProps) => {


    const value:MonthDateInterface | null = useAppSelector(selectUserFieldStartPqe) || null;
    const dispatch = useAppDispatch();
    const currentDate = new Date();
    const [label, setLabel] = useState("Select date");

    const pickerLang = {
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        from: 'From', to: 'To',
    }

    const [singleValue, setValue] = useState(value);
    const refPicker = useRef(null);

    function handleAMonthChange (year: number, month: number) {
        setValue({
            year: year,
            month: month
        });

        dispatch(
            setUserFieldStartPqe(
                {
                    month: month,
                    year: year,
                }
            )
        );

        // @ts-ignore
        refPicker.current.dismiss();
    }

    function handleClickMonthBox () {
        // @ts-ignore
        refPicker.current.show();
    }

    function addZeroPrefix (number: number) {
        if (number < 10) return "0"+number;
        return number;
    }

    useEffect(() => {
        onValid(!!value);

        if (value) {
            setLabel("Qualification date");
        }
    }, [value]);

    return (
        <div className={styles.wrapper}>
            <Picker
            ref={refPicker}
            years={{min: {year: 2000, month: 1}, max: {year: currentDate.getFullYear(), month: currentDate.getMonth()+1}}}
            value={value?.month ? value : {
            month: 1,
            year: 2021
        }}
            lang={pickerLang.months}
            onChange={handleAMonthChange}
            >
            <div onClick={handleClickMonthBox} className={styles.monthInput}>
                <Input
                    readOnly={true}
                    isRequired={false}
                    type={"text"}
                    label={label}
                    iconPrefix={"calendar"}
                    id={question.fieldSlug}
                    name={question.fieldSlug}
                    value={value?.month ? `${addZeroPrefix(value.month)}/${value.year}` : ""}
                />

                <span className={styles.monthInputIcon} />
            </div>

        </Picker>
        </div>
    );
}
