import React, {useEffect, useState} from 'react';
import styles from "./searchable.module.scss";
import {Input} from "../input/Input";
import {useDetectClickOutside} from 'react-detect-click-outside';
import {Scrollbars} from 'rc-scrollbars';
import {TermInterface} from "../../../interfaces/TermInterface";
import clsx from "clsx";
import {TermStateInterface} from "../../../interfaces/TermStateInterface";


type DropdownProps = {
    readonly closeToggle: (e: any) => void;
    readonly onSelect: (term: TermInterface) => void;
    readonly name: string;
    readonly label: string;
    readonly disabled?: boolean;
    readonly suggestions: Array<TermInterface>
    readonly excerpt?: Array<TermStateInterface>
};


export function Searchable({
                             closeToggle,
                             suggestions,
                               disabled = false,
                             onSelect,
                               excerpt = [],
    label,
                             name
                         }: DropdownProps) {
    const ref = useDetectClickOutside({
        onTriggered: () => setIsVisible(false)
    });
    const [searchValue, setSearchValue] = useState<string>("");
    const filteredSuggestions = suggestions.filter(
        suggestion =>
            suggestion.name.toLowerCase().indexOf(
                searchValue.toLowerCase()
            ) > -1 &&
            !excerpt.some(e => e.term_id === suggestion.term_id)
    );
    const [isVisible, setIsVisible] = useState(false);

    function handleChangeSearchValue(e: React.FormEvent<HTMLInputElement>) {
        setSearchValue(e.currentTarget.value);
    }

    function handleSelectOption (suggestion: TermInterface) {
        onSelect(suggestion);
        setSearchValue("");
    }

    function onInputBlur () {
        // setIsVisible(false);
    }

    useEffect(() => {
        setIsVisible(
            searchValue.length > 0
        );
    }, [searchValue]);

    return (
        <div
            className={styles.searchable}
            ref={ref}
        >
            <Input onChange={handleChangeSearchValue}
                   value={searchValue}
                   id={`${name}_dropdown`}
                   name={`${name}_dropdown`}
                   label={label}
                   iconPrefix={"glass"}
                   onBlur={onInputBlur}
                   disabled={disabled}
            />

            <div
                className={clsx(styles.window, {
                    [styles.windowVisible]: isVisible && !disabled,
                })}
            >
                <div
                    className={clsx(styles.windowInside, {
                    })}
                >
                    <Scrollbars style={{width: "100%", height: 152}}>
                        <ul className={styles.list}>
                            {
                                filteredSuggestions.map(suggestion =>
                                    <li key={suggestion.term_id} className={styles.item}>
                                        <button onClick={(e) => handleSelectOption(suggestion)} className={styles.button}
                                                value={suggestion.term_id}>{suggestion.name}</button>
                                    </li>
                                )
                            }
                            {
                                filteredSuggestions.length === 0 &&
                                <span className={styles.dropdownEmptyMessage}>No results...</span>
                            }
                        </ul>
                    </Scrollbars>
                </div>
            </div>
        </div>
    )
}
