import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {initialState} from "./initialState";
import {PreferencesOptionsInterface} from "../../interfaces/PreferencesOptionsInterface";

export const preferencesSlice = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setPreferencesOptions: (state, action: PayloadAction<PreferencesOptionsInterface>) => {
            state.options = action.payload
        },
    },
});
export const {
    setIsLoading,
    setPreferencesOptions,
} = preferencesSlice.actions;

export const selectIsLoading = (state: RootState) => state.preferences.isLoading;
export const selectPreferences = (state: RootState) => state.preferences.options;

export default preferencesSlice.reducer;
