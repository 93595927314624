import React, {forwardRef, memo} from 'react';
import styles from "./switch.module.scss";
import clsx from 'clsx';

type CheckboxProps = {
    readonly id: string;
    readonly name: string;
    readonly label?: string;
    readonly ariaLabel?: string;
    readonly type?: "email" | "number" | "password" | "text";
    readonly disabled?: boolean;
    readonly isRequired?: boolean;
    readonly checked?: boolean;
    readonly defaultChecked?: boolean;
    readonly hasError?: boolean;
    readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readonly className?: string;
    readonly children?: React.ReactNode;
};

export const Switch = memo<CheckboxProps>(
    forwardRef(
        (
            {
                id,
                name,
                checked,
                defaultChecked,
                hasError,
                onChange,
                className,
                ...props
            },
            ref,
        ) => {
            return (
                <label className={clsx(styles.switch, className, {
                    [styles.switchError]: hasError,
                    [styles.switchChecked]: checked,
                })} htmlFor={id}>

                    <input
                        className={clsx(styles.switchInput, className)}
                        name={name}
                        id={id}
                        type={"checkbox"}
                        defaultChecked={defaultChecked}
                        //@ts-ignore
                        ref={ref}
                        onChange={onChange}
                        {...props}
                    />

                    <div className={styles.Mark}>
                        <span className={styles.MarkOff}>No</span>
                        <span className={styles.MarkOn}>Yes</span>
                    </div>


                </label>
            );
        })
);
