import styles from "./infobox.module.scss";
import {Typography} from "../../ui/typography/Typography";
import {Box} from "../box/Box";
import React from "react";

type BrandProps = {
    readonly image: string;
    readonly title: string;
    readonly description: string;
};

export function InfoBox ({
                             image,
                             title,
                             description,
                         }: BrandProps) {
    return (
        <Box>
            <div className={styles.customBox}>
                <img src={image} className={styles.image} alt={"Thank you"} />
                <Typography as={"h1"} align={"center"}>{title}</Typography>
                <Typography as={"p"} margin={0} align={"center"}>{description}</Typography>
            </div>
        </Box>
    )
}
