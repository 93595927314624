import styles from "./vacancyCard.module.scss";
import React from "react";
import {VacancyInterface} from "../../../interfaces/VacancyInterface";
import clsx from "clsx";
import {Typography} from "../../ui/typography/Typography";
import {Button} from "../../ui/button/Button";
import {routes} from "../../../config/routes";
import {Link} from "react-router-dom";
import {VacancyHeader} from "../vacancyHeader/VacancyHeader";
import {limitCharacters} from "../../../utils/limitCharacters";

type VacancyCardProps = {
    readonly vacancy: VacancyInterface;
};

export function VacancyCard ({
                             vacancy,
                         }: VacancyCardProps) {
    return (
        <article key={vacancy.id} className={clsx(styles.card)}>

            <VacancyHeader vacancy={vacancy} logo={true} />

            <main className={clsx(styles.cardMain)}>
                <Typography as={"p"} margin={0}>{
                    limitCharacters(vacancy.shortDescription, 320)
                }</Typography>
            </main>
            <footer className={clsx(styles.cardFooter)}>
                <div className={clsx(styles.columnsInside)}>
                    <div>
                        <Typography as={"h6"} margin={0} className={styles.light}>Segment</Typography>
                        <Typography as={"h5"} margin={0} className={styles.darkSmall}>{ vacancy.company.segments[0] ? vacancy.company.segments[0].name : "-"}</Typography>
                    </div>
                    <div>
                        <Typography as={"h6"} margin={0} className={styles.light}>Team</Typography>
                        <Typography as={"h5"} margin={0} className={styles.darkSmall}>{limitCharacters(vacancy.team.name, 40)}</Typography>
                    </div>
                    <div>
                        <Typography as={"h6"} margin={0} className={styles.light}>Location</Typography>
                        <Typography as={"h5"} margin={0} className={styles.darkSmall}>{vacancy.locations[0] ? vacancy.locations[0].name : "-"}</Typography>
                    </div>
                </div>
                <div className={clsx(styles.action)}>
                    <Link to={routes.dashboard.opportunities.details + "/" + vacancy.id}>
                        <Button as={"button"} buttonStyle={"normal"} size={"auto"} typeStyle={"link"} iconRight={"arrow-right"}>
                            View details
                        </Button>
                    </Link>


                </div>
            </footer>
        </article>
    )
}
