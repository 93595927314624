import {RequestUserRegisterInterface} from "../../interfaces/api";
import {NamedErrorInterface} from "../../interfaces";

export interface PreregistrationState {
    account: RequestUserRegisterInterface,
    invitationCodeIsValid: boolean,
    isLoading: boolean,
    invitationCode: string,
    errors: NamedErrorInterface
}

export const initialState: PreregistrationState = {
    account: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        password2: "",
        // eligibilityRules: false,
        // termRules: false,
        isNewsletter: false,
        lawFirm: "",
        verification: {
            linkedin: {
                status: false,
                code: null,
            },
            file: {
                status: false,
            }
        },
    },
    invitationCodeIsValid: false,
    isLoading: false,
    invitationCode: "",
    errors: {}
};
