import styles from "./cardRow.module.scss";
import React from "react";
import clsx from "clsx";
import {Typography} from "../../ui/typography/Typography";

type CardRowProps = {
    readonly logoUrl: string;
    readonly name: string;
    readonly subname: string;
    readonly className?: string;
};

export function CardRow ({
                                 logoUrl,
                                 name,
                                 subname,
                                 className,
                         }: CardRowProps) {
    return (
        <div className={clsx(styles.card, className)}>
            <div style={{backgroundImage: `url(${logoUrl})`}} className={clsx(styles.cardImage)} />

            <div className={styles.cardContent}>
                <Typography as={"h3"} margin={0} className={styles.firstLine}>{name}</Typography>
                <Typography as={"p"} margin={0} className={styles.secondLine}>{subname}</Typography>
            </div>
        </div>
    )
}
