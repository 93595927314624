import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {initialState} from "./initialState";
import {VacancyInterface} from "../../interfaces/VacancyInterface";

export const vacanciesSlice = createSlice({
    name: 'vacancies',
    initialState,
    reducers: {
        setVacanciesAll: (state, action: PayloadAction<Array<VacancyInterface>>) => {
            state.all = action.payload
        },
        setVacanciesIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setVacanciesIsLoadingSingle: (state, action: PayloadAction<boolean>) => {
            state.isLoadingSingle = action.payload
        },
        setVacanciesMatched: (state, action: PayloadAction<Array<number>>) => {
            state.matched = action.payload
        },
    },
});
export const {
    setVacanciesAll,
    setVacanciesMatched,
    setVacanciesIsLoading,
    setVacanciesIsLoadingSingle,
} = vacanciesSlice.actions;

export const selectVacanciesAll = (state: RootState) => state.vacancies.all || [];
export const selectVacanciesMatched = (state: RootState) => state.vacancies.all ? state.vacancies.all.filter( vacancy => state.vacancies.matched.includes(vacancy.id) ) : [];
export const selectVacanciesIsLoading = (state: RootState) => state.vacancies.isLoading;
export const selectVacanciesIsLoadingSingle = (state: RootState) => state.vacancies.isLoadingSingle;

export default vacanciesSlice.reducer;
