import styles from "./vacancyApplicationStatus.module.scss";
import React from "react";
import clsx from "clsx";
import {VacancyExtendedInterface} from "../../../interfaces/VacancyExtendedInterface";
import {setModal} from "../../../store/base/baseSlice";
import {Button} from "../../ui/button/Button";
import {useAppDispatch} from "../../../hooks";
import {getVacancyApplicationCV, withdrawVacancyApplication} from "../../../store/vacancies/vacanciesApi";
import {confirm} from "../confirm/Confirm";
import {Icon} from "../../ui/icon/Icon";
import {Tooltip} from "../../ui/tooltip/Tooltip";

type VacancyApplicationProps = {
    vacancyExtended: VacancyExtendedInterface,
    updateVacancy: () => void,
};

export function VacancyApplicationStatus ({
    vacancyExtended,
                                              updateVacancy
                         }: VacancyApplicationProps) {

    const dispatch = useAppDispatch();

    function handleWithdrawApplication () {
        if (vacancyExtended.application) {
            dispatch(
                withdrawVacancyApplication(
                    {
                        id: vacancyExtended.application.id,
                    },
                    updateVacancy
                )
            );
        }
    }

    function confirmWithdraw () {
        confirm({
            title: 'Confirm withdrawal',
            message: 'Are you sure you want to withdraw your application?',
            buttons: {
                yes: {
                    label: "Yes",
                    onClick: () => handleWithdrawApplication()
                },
                no: {
                    label: "No",
                    onClick: () => {}
                }
            }
        })
    }

    function handleGetCv () {
        if (vacancyExtended.application) {
            if (vacancyExtended.application.id) {
                dispatch(getVacancyApplicationCV(vacancyExtended.application.id, () => {} ))

            }
        }
    }

    return (
        <div className={clsx(styles.status)}>

            {
                vacancyExtended.application &&
                    <div>
                        <p className={styles.statusHeading}>Application status</p>

                        <div className={styles.statusBox}>
                            { vacancyExtended.application.status }
                            {
                                vacancyExtended.application.cv &&
                                <div>
                                    <button onClick={handleGetCv} type={"button"}>View submitted CV</button>
                                </div>
                            }

                        </div>

                        {
                            !vacancyExtended.application.withdrawn &&
                            vacancyExtended.application.withdrawable &&
                            <Button
                                buttonStyle={"ghost"}
                                size={"sm"}
                                full={true}
                                className={styles.buttonSecond}
                                onClick={confirmWithdraw}
                            >
                                Withdraw
                            </Button>
                        }


                    </div>
            }

            {
                !vacancyExtended.application &&
                    <>
                        <div className={styles.helpTooltip}>
                            <Tooltip content={"Counselpath actively oversees the application process for this role. We will liaise with the employer to ensure you get a timely response."} align={"center"}>
                                <Icon icon={"info"} />
                            </Tooltip>
                        </div>
                        <Button
                            iconLeft={"plane"}
                            full={true}
                            size={"sm"}
                            onClick={() => dispatch(
                                setModal({
                                    modal: "application",
                                    state: true
                                })
                            )}
                        >
                            Apply on Counselpath
                        </Button>
                    </>

            }

        </div>
    )
}
