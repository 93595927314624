import {PreferencesOptionsInterface} from "../../interfaces/PreferencesOptionsInterface";

export interface PreferencesState {
    isLoading: boolean,
    options: PreferencesOptionsInterface | null
}

export const initialState: PreferencesState = {
    isLoading: false,
    options: null
};
