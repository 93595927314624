import React, {useEffect, useState} from "react";
import styles from "./tutorial.module.scss";
import {Slide} from "./slide/Slide";
import clsx from "clsx";
import {Icon} from "../../ui/icon/Icon";
import {Button} from "../../ui/button/Button";
// @ts-ignore
import {AnimateOnChange} from "react-animation";
import {useAppDispatch} from "../../../hooks";
import {putUserTutorialDone} from "../../../store/user/userApi";
import {setUserTutorial} from "../../../store/user/userSlice";

type TutorialProps = {

};

export const Tutorial = ({...props}: TutorialProps) => {

    const dispatch = useAppDispatch();

    const [currentSlide, setCurrentSlide] = useState(0);

    const memoizedSlides = React.useMemo(
        () =>
                 [
                    {
                        id: 0,
                        image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2021/08/slide1.svg`,
                        title: "Thank you and welcome to Counselpath community!",
                        description: "Here are a few tips to get you started.",
                        cta: "Continue"
                    },
                    {
                        id: 1,
                        image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2021/08/slide2.png`,
                        title: "Browse opportunities",
                        description: "Use ‘My opportunities’ panel to view opportunities matched to your preferences.",
                        cta: "Continue"
                    },
                    {
                        id: 2,
                        image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2021/08/slide4.png`,
                        title: "Update your preferences",
                        description: "Use 'My preferences' panel to update your account details and preferences.",
                        cta: "See available opportunities"
                    }
                ],
        []
    );

    function handleGoPrev () {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }

    }

    function handleGoNext () {
        if (currentSlide < memoizedSlides.length-1) {
            setCurrentSlide(currentSlide + 1);
        }
    }

    function handleSkipTutorial () {
        dispatch(putUserTutorialDone());
        dispatch(setUserTutorial(true));
    }

    useEffect(() => {
        memoizedSlides.forEach((slide) => {
            const img = new Image();
            img.src = slide.image;
        });
    }, [memoizedSlides])

    return (
        <div className={styles.tutorial}>

            <button className={clsx(styles.buttonArrow, styles.buttonArrowPrev, {
                [styles.buttonArrowDisabled]: currentSlide === 0
            })} onClick={handleGoPrev}>
                <Icon icon={"arrow-left"} />
            </button>

            <div className={styles.area}>

                <AnimateOnChange>

                    <Slide key={memoizedSlides[currentSlide].id} slide={memoizedSlides[currentSlide]} number={currentSlide} />

                </AnimateOnChange>

                <ul className={styles.pagination}>
                    {
                        memoizedSlides.map((slide, idx) =>
                            <li
                                key={slide.id}
                                className={clsx(styles.paginationItem, {
                                    [styles.paginationItemActive]: idx === currentSlide,
                                })}
                            />
                        )
                    }
                </ul>

                <Button onClick={(currentSlide === memoizedSlides.length-1 ? handleSkipTutorial : handleGoNext)} className={clsx(styles.button)}>{memoizedSlides[currentSlide].cta}</Button>

                {
                    currentSlide < memoizedSlides.length-1 &&
                    <Button onClick={handleSkipTutorial} size={"auto"} typeStyle={"link"} buttonStyle={"ghost"} className={clsx(styles.buttonSkip)}>Skip tutorial</Button>
                }


            </div>
            <button className={clsx(styles.buttonArrow, styles.buttonArrowNext, {
                [styles.buttonArrowDisabled]:  currentSlide === memoizedSlides.length-1
            })} onClick={handleGoNext}>
                <Icon icon={"arrow-right"} />
            </button>
        </div>
    );
}
