import React, {useEffect, useState} from "react";
import {QuestionInterface} from "ts/interfaces";
import {useAppDispatch, useAppSelector} from "ts/hooks";
import {selectUserFieldWorkPreferences, setUserFieldWorkPreferences} from "ts/store/user/userSlice";
import {CheckboxBars} from "../../../../ui/checkboxBars/CheckboxBars";

type QuestionFiveProps = {
    question: QuestionInterface,
    onValid: (isValid: boolean) => void,
};

export const QuestionFive = ({
                                onValid,
                                question,
                         }: QuestionFiveProps) => {

    const [choices, setChoices] = useState<Array<{
        label: string,
        value: string,
    }>>([]);

    const values = useAppSelector(selectUserFieldWorkPreferences) || [];

    const dispatch = useAppDispatch();

    useEffect(() => {
        onValid(values.length > 0);
    }, [values]);

    function handleChange (e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(
            setUserFieldWorkPreferences({
                value: e.currentTarget.value,
                toAdd: e.target.checked
            })
        );
    }

    // function handleSelectAll () {
    //     const allAnswers = [];
    //     choices.map(choice => {
    //         dispatch(
    //             setUserFieldWorkPreferences({
    //                 value: choice.value,
    //                 toAdd: true
    //             })
    //         );
    //     })
    // }

    useEffect(() => {
        setChoices(question.type[0].values || []);
    }, [question]);

    return (
        <div>
            <CheckboxBars
                defaultValues={values}
                selected={values}
                id={question.fieldSlug}
                name={question.fieldSlug}
                choices={choices}
                onChange={handleChange}
            />

            {/*<div className={styles.footer}>*/}
            {/*    <Button*/}
            {/*        buttonStyle={"ghost"}*/}
            {/*        typeStyle={"link"}*/}
            {/*        onClick={handleSelectAll}*/}
            {/*    >*/}
            {/*        Select all that apply*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    );
}
