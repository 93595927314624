import React, {SyntheticEvent, forwardRef, memo} from 'react';
import styles from "./pill.module.scss";
import clsx from 'clsx';
import {Icon} from "../icon/Icon";
import {decode} from "html-entities";

type InputProps = {
    readonly id: string;
    readonly name: string;
    readonly value: string | number;
    readonly isRequired?: boolean;
    readonly label: string;
    readonly defaultChecked?: boolean;
    readonly onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly checked?: boolean;
};

export const Pill = memo<InputProps>(
    forwardRef(
        (
            {
                id,
                name,
                onChange,
                className,
                value,
                label,
                isRequired,
                disabled = false,
                ...props
            },
            ref,
        ) => {

            return (
                <div
                    className={clsx(styles.pill, {
                        [styles.pillDisabled]: disabled
                    })}
                >
                    <input
                        className={clsx(styles.checkbox, className)}
                        name={name}
                        id={id}
                        type={"checkbox"}
                        value={value}
                        //@ts-ignore
                        ref={ref}
                        onChange={onChange}
                        disabled={disabled}
                        {...props}
                    />

                    <label
                        className={clsx(styles.label, className, {})}
                        htmlFor={id}>

                        <div className={styles.check}>
                            <Icon icon={"check"} />
                        </div>

                        <div
                            className={clsx(styles.text, {
                            })}
                        >
                            {decode(label)}
                        </div>
                    </label>
                </div>

            );
        })
);
