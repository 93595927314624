import styles from './verifyCard.module.scss';
import React from "react";
import {Typography} from "../typography/Typography";
import clsx from "clsx";
import {Status} from "../status/Status";


type VerifyCardProps = {
    readonly logoUrl: string;
    readonly title: string;
    readonly successMessage?: string | null;
    readonly disabled?: boolean;
    readonly description: string;
};

export function VerifyCard({
                               logoUrl,
                               title,
    successMessage = null,
    disabled = false,
                               description,
                         ...props
                     }: VerifyCardProps) {
    return (
        <div
            className={clsx(styles.card, {
                [styles.cardDisabled]: disabled
            })}
        >
            <img src={logoUrl} className={styles.icon} alt={"Verification method"} />
            <Typography as={"h5"} margin={1}>{title}</Typography>
            <Typography as={"p"} type={"small"} margin={4}>{description}</Typography>

            {
                successMessage &&
                    <Status type={"success"} message={successMessage} />
            }
        </div>
    )
}


