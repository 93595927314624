import {AxiosService} from "./AxiosService";
import {Container, Service} from "typedi";

@Service()
export class ApiService {

    constructor(
        private readonly client: AxiosService
    ) {
        this.client = Container.get(AxiosService);
    }

    async post (url: string, data?: any, config?: any) {
        return this.client.instance().post(url, data, config);
    }

    async put (url: string, data?: any) {
        return this.client.instance().put(url, data);
    }

    async get (url: string, data?: any) {
        return this.client.instance().get(url, data);
    }

    async delete (url: string, data?: any) {
        return this.client.instance().delete(url, data);
    }
}
