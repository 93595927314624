import React, {useEffect} from "react";
import {useAppSelector} from "../../../hooks";
import {selectUser} from "../../../store/user/userSlice";
import {Navigate} from "react-router-dom";
import {routes} from "../../../config/routes";
import {USER_STATUS} from "ts/typings/enums";


const Dashboard: React.FC = () => {

    const user = useAppSelector(selectUser);

    useEffect(() => {
        // closeTabAlert();
    }, []);

    if (user) {
        if (user.emailVerified && user.userStatus === USER_STATUS.activated && user.tutorialDone && user.questionsDone) {
            return <Navigate to={routes.dashboard.opportunities.base} replace={true}/>
        } else {
            return <Navigate to={routes.introduction.base} replace={true}/>
        }

    }
    return <Navigate to={routes.login.base} replace={true}/>

}

export default Dashboard;
