import React, {useEffect, useState} from "react";
import {QuestionInterface} from "ts/interfaces";
import {useAppDispatch, useAppSelector} from "ts/hooks";
import {selectUserFieldSegments, setUserFieldSegments} from "ts/store/user/userSlice";
import {TermInterface} from "../../../../../interfaces/TermInterface";
import styles from "./questionTwo.module.scss";
import {Pill} from "../../../../ui/pill/Pill";

type QuestionTwoProps = {
    question: QuestionInterface,
    onValid: (isValid: boolean) => void,
    fieldSlug?: string,
};

export const QuestionTwo = ({
                                onValid,
                                question,
                                fieldSlug = question.fieldSlug
                         }: QuestionTwoProps) => {

    const [groups, setGroups] = useState<Array<{
        label: string | null,
        children: Array<TermInterface>
    }>>([]);

    const values = useAppSelector(selectUserFieldSegments) || [];

    const dispatch = useAppDispatch();

    useEffect(() => {
        onValid(values.length >= 1);
    }, [values]);

    function handleChange (term: TermInterface) {
        dispatch(
            setUserFieldSegments(
                {
                    term_id: term.term_id,
                    name: term.name
                }
            )
        );
    }

    useEffect(() => {
        const block = question.type[0];
        const blockTag = block.tag;
        const ch = blockTag ? block[blockTag] || [] : [];
        const t: Array<{
            label: string | null,
            children: Array<TermInterface>
        }> = [];

        ch.forEach((term) => {
            if (term.parent === 0) {
                const c:Array<TermInterface> = [];

                ch.forEach(termChild => {
                    if (termChild.parent === term.term_id) {
                        c.push(termChild);
                    }
                });

                t.push({
                    label: term.name,
                    children: [...c]
                })
            }
        })


        setGroups(t);
    }, [question]);

    return (
        <div>
            {
                groups.map(({ label, children }, index) => {
                    return (
                        <div key={index} className={styles.group}>
                            {
                                label &&
                                <p className={styles.groupLabel}>{label}</p>
                            }
                            <div className={styles.groupPills}>
                                {
                                    children.map((term: TermInterface) => {
                                        return (
                                            <Pill
                                                key={term.term_id}
                                                id={fieldSlug + "_" + term.term_id}
                                                name={fieldSlug}
                                                defaultChecked={values.findIndex(_item => _item.term_id === term.term_id) > -1}
                                                label={term.name}
                                                value={term.term_id}
                                                onChange={() => handleChange(term)}
                                            />
                                        )
                                    })
                                }
                            </div>

                        </div>
                    )
                })
            }
        </div>
    );
}
