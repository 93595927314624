import {app} from "../config/base";
import {amountFormatter} from "./amountFormatter";

export function amountWithCurrency (amount: string | number) {
    if (amount) {
        return app.currency + amountFormatter(amount);

    } else {
        return "Confidential";
    }
}
