import React, {ReactNode, forwardRef, memo, useState} from 'react';
import styles from "./dropzone.module.scss";
import clsx from 'clsx';
import {useDropzone} from "react-dropzone";
import {Button} from "../button/Button";


type DropzoneProps = {
    readonly children?: ReactNode;
    readonly fileExtensionsAccept?: string;
    readonly isLoading?: boolean;
    readonly dropArea?: boolean;
    readonly dropAreaTitle?: string;
    readonly dropAreaSubTitle?: string;
    readonly name: string;
    readonly fileExtensions?: string;
    readonly maxSize?: number;
    readonly maxFiles?: number;
    readonly onChange: (files: Array<File>) => void,
    readonly handleError?: (message: string) => void,
    // readonly onError: () => void,
};
export const Dropzone = memo<DropzoneProps>(
    forwardRef(
        (
            {
                children,
                // onError,
                onChange,
                isLoading,
                dropArea,
                dropAreaTitle,
                name,
                maxFiles,
                dropAreaSubTitle,
                maxSize,
                fileExtensions = "",
                fileExtensionsAccept = "",
                handleError = () => {},
                ...props
            },
            ref,
        ) => {

            const {getRootProps, getInputProps, acceptedFiles, open, inputRef } = useDropzone({
                accept: fileExtensionsAccept,
                maxFiles: maxFiles,
                maxSize: maxSize,
                onDrop: (acceptedFiles, fileRejections) => {

                    setFiles(
                        [...acceptedFiles]
                    );
                    handleChange([...acceptedFiles]);

                    // fileRejections.forEach((file) => {
                    //     file.errors.forEach((err) => {
                    //         if (err.code === "file-too-large") {
                    //             handleError("File is too large. Max file size is 2MB.");
                    //         }
                    //     });
                    // });

                }
            });
            const [files, setFiles] = useState<Array<File>>([]);

            function handleRemoveFile (e: MouseEvent, index: number) {
                const newFiles = [...acceptedFiles];
                newFiles.splice(index, 1);
                setFiles([...newFiles]);
                handleChange([...newFiles]);
            }

            function handlePreviewClick (e: React.MouseEvent<HTMLElement>, file: File) {
                e.preventDefault();
                window.open(prepareFilePreviewUrl(file), '_blank');
            }

            function handleChange (files: Array<File>) {
                onChange(files);
            }

            function prepareFilePreviewUrl (file: File) {
                return URL.createObjectURL(file);
            }

            const filesList = files.map((file: File, i) => (
                <div className={styles.uploaderFileBar} key={"file_"+file.size}>
                    <div className={styles.uploaderFileBarLabel}>
                        {file.name}&nbsp;
                        <button className={styles.uploaderFileBarButton} onClick={(e: React.MouseEvent<HTMLElement>) => handlePreviewClick(e, file)}>preview</button>
                    </div>
                    <Button iconRight={"delete"} onClick={(e: MouseEvent) => handleRemoveFile(e, i)} className={styles.uploaderFileBarButton}
                            typeStyle={"link"} size={"auto"} buttonStyle={"danger"}/>
                </div>
            ));

            return (
                <>
                <div
                    className={clsx(styles.wrapper, {


                    })}
                    {...getRootProps()}
                >
                    {
                        dropArea &&
                        <div className={styles.dropArea}>
                            <div className={styles.dropAreaTitle}>{dropAreaTitle}</div>
                            <div className={styles.dropAreaSubtitle}>{dropAreaSubTitle}</div>
                        </div>
                    }
                    {
                        children &&
                        <button className={styles.uploader} onClick={open} disabled={isLoading}>
                            {children}
                        </button>
                    }
                    {/*@ts-ignore*/}
                    <input {...getInputProps()}
                           {...props}
                           name={name}
                           ref={inputRef}
                   />


                </div>
                    {filesList}
                </>
            );
        })
);
