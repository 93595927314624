import React, {useEffect, useState} from "react";
import {QuestionInterface} from "ts/interfaces";
import {useAppDispatch, useAppSelector} from "ts/hooks";
import {selectUserFieldCommunicationPreferences, setUserFieldCommunicationPreferences,} from "ts/store/user/userSlice";
import {CheckboxBars} from "../../../../ui/checkboxBars/CheckboxBars";

type QuestionElevenProps = {
    question: QuestionInterface,
    onValid: (isValid: boolean) => void,
};

export const QuestionEleven = ({
                                   onValid,
                                   question,
                               }: QuestionElevenProps) => {

    const [choices, setChoices] = useState<Array<{
        label: string,
        value: string,
    }>>([]);

    const values = useAppSelector(selectUserFieldCommunicationPreferences) || [];

    const dispatch = useAppDispatch();

    useEffect(() => {
        onValid(true);
    }, [values]);

    function handleChange (e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(
            setUserFieldCommunicationPreferences({
                value: e.currentTarget.value,
                toAdd: e.target.checked
            })
        );
    }

    useEffect(() => {
        setChoices(question.type[0].values || []);
    }, [question]);

    return (
        <div>
            <CheckboxBars
                defaultValues={values}
                selected={values}
                id={question.fieldSlug}
                name={question.fieldSlug}
                choices={choices}
                onChange={handleChange}
            />
        </div>
    );
}
