import {AppThunk} from "../store";
import {endpoints} from "../../config/endpoints";
import {Container} from "typedi";
import {ApiService} from "../../services/ApiService";
import {
    setPreferencesOptions,
    setIsLoading,
} from "./preferencesSlice";
import {PreferencesOptionsInterface} from "../../interfaces/PreferencesOptionsInterface";


export const getUserPreferences = (): AppThunk => (dispatch) => {

    const api = Container.get(ApiService);

    api
        .get(endpoints.user.preferences)
        .then((res) => {
            // console.log(res);
            if (res.data.state) {
                dispatch(setPreferencesOptions(res.data));
            }

        }).catch((err) => {
        console.log(err);
    }).finally(() => {
        dispatch(setIsLoading(false));
    });
};


export const updateUserPreferences = (preferences: PreferencesOptionsInterface, onSuccess: () => void, onError: (message:string) => void): AppThunk => (dispatch) => {

    const api = Container.get(ApiService);

    api
        .put(
            endpoints.user.preferences,
            {
                ...preferences
            }
        )
        .then((res) => {
            // console.log(res);
            if (res.data.state) {
                // dispatch(setPreferencesOptions(res.data));
                onSuccess();
            } else {
                onError(res.data.message);
            }

        }).catch((err) => {
        console.log(err);
    }).finally(() => {
        dispatch(setIsLoading(false));
    });
};
