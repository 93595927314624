const BASE = process.env.REACT_APP_API_URL + "/wp-json";
const PUBLIC_PATH = "/hrl/v2/public"
const AUTHORIZED_PATH = "/jwt-auth/v1"

export const endpoints = {
    public: {
        global: {
            file: {
                upload: PUBLIC_PATH + "/global/file/upload",
            },
            check_email: PUBLIC_PATH + "/global/check-email",
        }
    },
    auth: {
        global: {
            process: {
                get: AUTHORIZED_PATH + "/global/process",
            },
            visitor: AUTHORIZED_PATH + "/global/visitor",
        },
    },
    code: {
        verify: BASE + PUBLIC_PATH + "/invitation-code/verify",
    },
    user: {
        public: {
            activate: PUBLIC_PATH + "/user/activate",
            create: PUBLIC_PATH + "/user/create",
            login: PUBLIC_PATH + "/user/login",
            reset_password: PUBLIC_PATH + "/user/reset_password",
        },
        get: AUTHORIZED_PATH + "/user/get",
        tutorial: AUTHORIZED_PATH + "/user/tutorial",
        answers: AUTHORIZED_PATH + "/user/answers",
        invite: AUTHORIZED_PATH + "/user/invite",
        preferences: AUTHORIZED_PATH + "/user/preferences",
    },
    linkedin: {
        token: PUBLIC_PATH + "/linkedin/profile"
    },
    vacancies: {
        base: AUTHORIZED_PATH + "/vacancies/",
        all: AUTHORIZED_PATH + "/vacancies/all",
        matched: AUTHORIZED_PATH + "/vacancies/matched",
        question: AUTHORIZED_PATH + "/vacancies/question",
        externalVisit: AUTHORIZED_PATH + "/vacancies/external_visit",
    },
    application: {
        base: AUTHORIZED_PATH + "/applications",
        add: AUTHORIZED_PATH + "/applications/add",
        withdraw: AUTHORIZED_PATH + "/applications/withdraw",
        cv: AUTHORIZED_PATH + "/applications/cv",
    }
}
