import ReactGA from "react-ga4";

export function statsUserRegister(): void {
    ReactGA.event({
        category: "User",
        action: "user_register",
        label: "User register",
    });
}

export function statsVacancyView(title: string, id: number): void {
    ReactGA.event({
        category: "Vacancy " + title + " (" + id + ")",
        action: "vacancy_view",
        label: "View vacancy " + title + " (" + id + ")",
    });
}

export function statsVacancyApplication(title: string, id: number): void {
    ReactGA.event({
        category: "Vacancy " + title + " (" + id + ")",
        action: "vacancy_application",
        label: "Application on vacancy " + title + " (" + id + ")",
    });
}
