import React, {forwardRef, memo} from 'react';
import styles from "./checkbox.module.scss";
import clsx from 'clsx';

type InputProps = {
    readonly id: string;
    readonly name: string;
    readonly label?: string;
    readonly ariaLabel?: string;
    readonly type?: "email" | "number" | "password" | "text";
    readonly disabled?: boolean;
    readonly isRequired?: boolean;
    readonly checked?: boolean;
    readonly defaultChecked?: boolean;
    readonly hasError?: boolean;
    readonly errorMessage?: string;
    readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readonly className?: string;
    readonly children?: React.ReactNode;
};

export const Checkbox = memo<InputProps>(
    forwardRef(
        (
            {
                id,
                name,
                disabled = false,
                checked,
                defaultChecked,
                hasError,
                errorMessage,
                onChange,
                className,
                isRequired,
                children,
                ...props
            },
            ref,
        ) => {
            return (
                <div
                    className={styles.wrapper}
                >

                    <label className={clsx(styles.label, className, {
                        [styles.labelDisabled]: disabled,
                        [styles.labelError]: hasError,
                    })} htmlFor={id}>

                        <input
                            className={clsx(styles.input, className)}
                            name={name}
                            id={id}
                            type={"checkbox"}
                            disabled={disabled}
                            defaultChecked={defaultChecked}
                            //@ts-ignore
                            ref={ref}
                            onChange={onChange}
                            {...props}
                        />

                        <div className={styles.mark} />
                        <div className={styles.text}>
                            {children}
                            {
                                isRequired && <span className={styles.inputRequired}>*</span>
                            }
                        </div>

                        <div
                            className={clsx(styles.field, {
                                [styles.fieldError]: hasError,
                            })}
                        >

                        </div>
                    </label>


                    {
                        hasError && errorMessage &&
                        <div className={styles.errorMessage}>{errorMessage}</div>
                    }
                </div>

            );
        })
);
