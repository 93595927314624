import {TokenService} from "./TokenService";
import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {Container, Service} from "typedi";

@Service()
export class AxiosService {

    private readonly defaultOptions: AxiosRequestConfig;
    private readonly instanceLocal: AxiosInstance;

    constructor(private readonly tokenService: TokenService) {

        this.tokenService = Container.get(TokenService);

        this.defaultOptions = {
            baseURL: process.env.REACT_APP_API_URL + "/wp-json",
            responseType: 'json',
        };

        this.instanceLocal = axios.create(this.defaultOptions);
    }

    instance () {

        // Set the AUTH token for any request
        this.instanceLocal.interceptors.request.use((config) => {
            const token = this.tokenService.getToken();
            config.headers.Authorization =  token ? `Bearer ${token}` : '';
            return config;
        });

        return this.instanceLocal;
    }

}
