import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Navbar from "../partials/navbar/Navbar";
import {routes} from "../../config/routes";
import Login from "../pages/login/Login";
import JoinCode from "../pages/join/joinCode/JoinCode";
import JoinAccount from "../pages/join/joinAccount/JoinAccount";
import JoinVerification from "../pages/join/joinVerification/JoinVerification";
// @ts-ignore
import {LinkedInPopUp} from 'react-linkedin-login-oauth2';
import Routing from "../utils/Routing";
import JoinThankyou from "../pages/join/joinThankYou/JoinThankyou";
import {PrivateRoute} from "../utils/PrivateRoute";
import Dashboard from "../pages/dashboard/Dashboard";
import ModalViewer from "../utils/ModalViewer";
import Introduction from "../pages/introduction/Introduction";
import {TemplateIntroduction} from "../templates/templateIntro/TemplateIntroduction";
import {TemplateDashboard} from "../templates/templateDashboard/TemplateDashboard";
import Applications from "../pages/applications/Applications";
import Profile from "../pages/profile/Profile";
import VacancyPage from "../pages/vacancyPage/VacancyPage";
import VacanciesPage from "../pages/vacanciesPage/VacanciesPage";
import {setConfiguration} from 'react-grid-system';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReactGA from "react-ga4";
import {useAppDispatch} from "../../hooks";
import {setRedirectTo, setRedirectDone} from "../../store/base/baseSlice";

const Boot: React.FC = () => {

    setConfiguration({ gridColumns: 12, gutterWidth: 24 });

    ReactGA.initialize(process.env.REACT_APP_GA_ID ?? "");
    ReactGA.send("pageview");

    // Get current url for redirect
    const dispatch = useAppDispatch();
    if (window.location.pathname.length > 4) {
        dispatch(setRedirectTo(window.location.pathname));
        dispatch(setRedirectDone(false));
    }


    return (
        <div>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_API_GOOGLE_RECAPTCHA_V3_SITE_KEY || ""}
                scriptProps={{
                    async: false,
                    defer: false,
                    appendTo: 'head',
                    nonce: undefined
                }}
            >
                <BrowserRouter>
                    <Routing>
                        <Routes>

                            {/* Login */}

                            <Route path="*">
                                <Login />
                            </Route>
                            <Route path={routes.login.base}>
                                <Login />
                            </Route>

                            <Route path={routes.login.base + "/:activationCode"}>
                                <Login />
                            </Route>

                            {/* Join */}
                            <Route path={routes.join.base + "/*"}>
                                <Route
                                    path="/"
                                    element={<JoinAccount/>}
                                />

                                <Route
                                    path="account"
                                    element={<JoinAccount/>}
                                />
                                <Route
                                    path="verification"
                                    element={<JoinVerification />}
                                />
                                <Route
                                    path="verification/linkedin"
                                    element={<LinkedInPopUp />}
                                />
                                <Route
                                    path="thank-you"
                                    element={<JoinThankyou />}
                                />
                            </Route>

                            {/* Introduction */}
                            <PrivateRoute
                                path={routes.introduction.base + "/*"}
                                element={<TemplateIntroduction />}
                            >
                                <Route
                                    path={"/"}
                                    element={<Introduction />}
                                />
                            </PrivateRoute>

                            {/* Dashboard */}
                            <PrivateRoute
                                path={routes.dashboard.base + "/*"}
                                element={<TemplateDashboard />}
                            >
                                <Route
                                    path={"/"}
                                    element={<Dashboard />}
                                />
                                <Route
                                    path={"opportunities"}
                                    element={<VacanciesPage matched={true} />}
                                />
                                <Route
                                    path={"opportunities/all"}
                                    element={<VacanciesPage matched={false} />}
                                />

                                {/*Dynamic title*/}
                                <Route
                                    path={"opportunities/details/:id"}
                                    element={<VacancyPage />}
                                />
                                <Route
                                    path={"applications"}
                                    element={<Applications />}
                                />
                                <Route
                                    path={"profile"}
                                    element={<Profile />}
                                />
                            </PrivateRoute>

                        </Routes>
                    </Routing>

                    <ModalViewer />

                </BrowserRouter>
            </GoogleReCaptchaProvider>
        </div>


    );
}

export default Boot;
