import type {ReactNode} from 'react';
import React, {useEffect} from "react";
import styles from "./templateDashboard.module.scss";
import {Brand} from "../../partials/brand/Brand";
import {Button} from "../../ui/button/Button";
import Footer from "../../partials/footer/Footer";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {requestUserLogout} from "../../../store/user/userApi";
import {Navigate, Outlet, useNavigate} from 'react-router-dom';
import Navigation from "../../partials/navigation/Navigation";
import {
    selectModals,
    selectRedirectDone,
    selectRedirectTo, setBaseRoutePathname,
    setModal, setRedirectDone,
    setRedirectTo
} from "../../../store/base/baseSlice";
import ModalInviteFriend from "../../modals/ModalInviteFriend/ModalInviteFriend";
import {isMobile} from "react-device-detect";
import {routes} from "../../../config/routes";

type TemplateAsideProps = {
    readonly children?: ReactNode;
};

export const TemplateDashboard = ({children}: TemplateAsideProps) => {

    const dispatch = useAppDispatch();
    const modals = useAppSelector(selectModals);
    const redirectDone = useAppSelector(selectRedirectDone);
    const redirectTo = useAppSelector(selectRedirectTo);
    let navigate = useNavigate();

    useEffect(() => {
        if (!redirectDone && redirectTo) {
            dispatch(setRedirectDone(true));
            navigate(redirectTo);
            dispatch(setBaseRoutePathname(redirectTo));
        }
    }, [redirectDone, redirectTo]);

    if (isMobile) {
        return <Navigate to={routes.introduction.base} replace={true}/>
    }

    function handleLogoutClick () {
        dispatch(requestUserLogout());
    }

    function handleClickInvitationModal () {
        dispatch(setModal({
            modal: "invite",
            state: true
        }));
    }



    return (
        <div className={styles.wrapper}>

            <div className={styles.navbar}>
                <div className={"container"}>
                    <div className={styles.navbarInner}>
                        <Brand theme={"dark"} size={"medium"}/>

                        <div className={styles.navbarSide}>

                            <div className={styles.navbarNavigation}>
                                <Navigation />
                            </div>

                            <Button onClick={handleClickInvitationModal} className={styles.navbarAction} buttonStyle={"normal"} typeStyle={"normal"} size={"xs"} >Invite friends</Button>
                            <Button onClick={handleLogoutClick} iconLeft={"exit"} buttonStyle={"ghost"} size={"auto"} typeStyle={"link"}>Logout</Button>
                        </div>


                    </div>
                </div>
            </div>

            <div className={"container"}>
                <Outlet />
            </div>

            <div className={styles.footer}>
                <Footer />
            </div>

            {
                modals.invite &&
                    <ModalInviteFriend />

            }

        </div>
    );
}
