import styles from "./companyCard.module.scss";
import React, {ReactNode} from "react";
import clsx from "clsx";
import {Typography} from "../../ui/typography/Typography";

type CompanyCardProps = {
    readonly logoUrl: string;
    readonly name: string;
    readonly website: string;
    readonly items: Array<{
        label: string,
        value: string | Array<string> | ReactNode,
    }>;
    readonly onClickCompanyUrl: (website: string) => void
};

export function CompanyCard ({
                                 logoUrl,
                                 name,
                                 items,
                                 website,
    onClickCompanyUrl
                         }: CompanyCardProps) {
    return (
        <aside className={styles.aside}>
            <div className={styles.asideHeader}>
                <div style={{backgroundImage: `url(${logoUrl})`}} className={clsx(styles.asideLogo)} />
                <Typography as={"h5"} align={"center"} margin={0}>{name}</Typography>
            </div>
            <div className={styles.asideMain}>
                {
                    items.map((item, i) => {
                        if (item.value) {
                            return (
                                <div key={"company_item_"+i} className={styles.asideList}>
                                    <span className={styles.asideLight}>{item.label}</span>
                                    <span className={styles.asideDark}>{item.value}</span>
                                </div>
                            )
                        }
                    })
                }
            </div>
            {
                website &&
                <div className={styles.asideFooter}>
                    <button
                        className={clsx(styles.asideLink, styles.asideLight)}
                        onClick={(e) => onClickCompanyUrl(website)}
                    >
                        {
                            website
                                .replace("https://www.", "")
                                .replace("http://www.", "")
                        }
                    </button>
                </div>
            }
        </aside>
    )
}
