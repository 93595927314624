import React, {useEffect, useRef, useState} from "react";
import {TemplateAside} from "../../templates/templateAside/TemplateAside";
import styles from "./login.module.scss";
import {Input} from "../../ui/input/Input";
import {emailValidation, passwordValidation} from "../../../utils/validations";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectIsLoading, setModal} from "../../../store/base/baseSlice";
import {Typography} from "../../ui/typography/Typography";
import {useForm} from "react-hook-form";
import {Button} from "../../ui/button/Button";
import {getInitUser, requestUserAccountActivation, requestUserLogin} from "../../../store/user/userApi";
import {Status} from "../../ui/status/Status";
import {RequestUserRegisterInterface} from "../../../interfaces/api";
import {useNavigate, useParams} from 'react-router-dom';
import {
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {Loader} from "../../ui/loader/Loader";
import {useDocumentTitle} from "ts/hooks";
import {routes} from "../../../config/routes";
import Recaptcha from 'react-grecaptcha';

const Login: React.FC = () => {

    useDocumentTitle("Login - Counselpath");

    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const [globalErrors, setGlobalErrors] = useState("");
    const [isDisabledLocal, setIsDisabledLocal] = useState(true);
    const [isActivate, setIsActivate] = useState(false);
    const [activationSuccessMessage, setActivationSuccessMessage] = useState<string | null>(null);
    const { activationCode } = useParams();
    const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);

    let navigate = useNavigate();

    const { register, setValue, getValues, handleSubmit, formState: { errors, isValid } } = useForm<{
        email: string,
        password: string,
        captchaToken: string | false,
        captchaTokenV2: string | false,
    }>({
        mode: "onSubmit"
    });

    function handleChangeForm () {
        const values = getValues();
        let isValid = true;

        if (values.email.length < 1) {
            isValid = false;
        }
        if (values.password.length < 10) {
            isValid = false;
        }

        setIsDisabledLocal(!isValid);
    }

    useEffect(() => {
        handleChangeForm();
    }, [getValues]);

    useEffect(() => {
        if (activationCode) {
            if (activationCode.length > 0) {
                setIsActivate(true);

                dispatch(
                    requestUserAccountActivation(activationCode, handleSuccessActivation, handleFinallyActivation)
                );
            }
        }

    }, [activationCode]);

    function handleFinallyActivation () {
        setIsActivate(false);
    }

    function handleSuccessActivation (message: string) {
        setActivationSuccessMessage(message);
        setIsActivate(false);
    }

    function handleChangeCaptcha (value: string) {
        if (!showRecaptchaV2) {
            setValue('captchaToken', value);
        }
    }

    function onSuccessLogin () {
        navigate(routes.introduction.base);
    }

    const onSubmit = (data: RequestUserRegisterInterface) => {
        dispatch(
            requestUserLogin(
                {
                username: getValues().email,
                password: getValues().password,
                captchaToken: getValues().captchaToken,
                captchaTokenV2: getValues().captchaTokenV2,
            },
                setGlobalErrors,
                onSuccessLogin,
                runCaptchaV2,
            )
        );
    };

    function runCaptchaV2 () {
        setValue('captchaToken', false);
        setShowRecaptchaV2(false);
        setShowRecaptchaV2(true);
    }

    function verifyCallback (token: string) {
        setValue('captchaTokenV2', token);
        setValue('captchaToken', false);
    }

    function expiredCallback () {
        setValue('captchaTokenV2', false);
    }


    useEffect(() => {
        // register('captchaToken', { required: true });
        // register('captchaTokenV2', { required: true });
    }, []);

    useEffect(() => {
        dispatch(getInitUser());
    }, [dispatch]);

    return (

            <TemplateAside>

                <div>
                    <Typography as={"h5"} className={"lead"}>Login</Typography>
                    <Typography as={"h1"} margin={4}>Curated careers platform for experienced legal professionals</Typography>
                </div>

                {
                    isActivate &&
                        <div className={styles.activateBox}>
                            <h6>Account activation...</h6>
                            <Loader />
                        </div>
                }
                {
                    activationSuccessMessage &&
                        <div className={styles.activateStatus}>
                            <Status type={"success"} message={activationSuccessMessage} />
                        </div>
                }
                {
                    !isActivate &&
                        <form onChange={handleChangeForm} onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <Input
                            {...register(
                                "email",
                                {
                                    required: "Field is required",
                                    validate: {
                                        positive: v => emailValidation(v),
                                    },
                                }
                            )}
                            isRequired={true}
                            label={"E-mail"}
                            id={"email"}
                            name={"email"}
                            type={"email"}
                            autoComplete={"on"}
                            hasError={!!errors.email}
                            errorMessage={errors.email?.message}
                        />
                        <Input
                            {...register(
                                "password",
                                {
                                    required: "Field is required",
                                    // validate: {
                                    //     positive: v =>
                                    //         passwordValidation(
                                    //             v,
                                    //             ""
                                    //         ),
                                    // },
                                }
                            )}
                            isRequired={true}
                            type={"password"}
                            label={"Password"}
                            id={"password"}
                            name={"password"}
                            hasError={!!errors.password}
                            errorMessage={errors.password?.message}
                        />


                        {
                            globalErrors &&
                            <Status type={"error"} message={globalErrors} />
                        }

                        <div className={styles.footer}>

                            <GoogleReCaptcha onVerify={handleChangeCaptcha} />

                            {
                                showRecaptchaV2 &&
                                <div style={{marginTop: 16, marginBottom: 16}}>
                                    <Recaptcha
                                        sitekey={"6LflIyYcAAAAAKz7IY8qZ0CO9lJ2NS81dTCvEBrX"}
                                        callback={verifyCallback}
                                        expiredCallback={expiredCallback}
                                        locale="en-EN"
                                    />
                                </div>

                            }

                            <Button
                                type={"submit"}
                                notValid={isDisabledLocal}
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                Log In
                            </Button>

                            <div className={styles.footerBottom}>
                                <Button
                                    className={styles.footerLink}
                                    buttonStyle={"light"}
                                    typeStyle={"link"}
                                    size={"auto"}
                                    onClick={() => dispatch(setModal({
                                        modal: "passwordReset",
                                        state: true
                                    }))}
                                >
                                    Forgot your password?
                                </Button>
                            </div>

                        </div>



                    </form>
                }

            </TemplateAside>
    );
}

export default Login;
