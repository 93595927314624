export const passwordValidation = (v: string, message?: string) => {
    const PASSWORD_LENGTH = process.env.REACT_APP_PASSWORD_LENGTH || 6;
    const length = v.length >= PASSWORD_LENGTH;
    const containsDigits = /[0-9]/.test(v);
    const containsUpper = /[A-Z]/.test(v);
    const containsSpecial = /[!@#$%^&*()+=.,-]/.test(v);

    return (length &&
        containsDigits &&
        containsSpecial &&
        containsUpper) ||
        (
            message ? message :
                `Password must have at least ${PASSWORD_LENGTH} characters, one big letter, one special character and one number.`
        )
}
