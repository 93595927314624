import type {ReactNode} from 'react';
import React from "react";
import styles from "./templatePre.module.scss";
import {Brand} from "../../partials/brand/Brand";
import Footer from "../../partials/footer/Footer";

type TemplateAsideProps = {
    readonly children: ReactNode;
};

export const TemplatePre = ({children}: TemplateAsideProps) => {
    return (
        <div className={styles.wrapper}>

            <div className={styles.navbar}>
                <div className={"container"}>
                    <div className={styles.navbarInner}>
                        <Brand theme={"light"} size={"medium"}/>
                    </div>
                </div>
            </div>

            <div className={"container"}>
                <div className={styles.main}>
                    {
                        children
                    }
                </div>

            </div>

            <div className={styles.footer}>
                <Footer/>
            </div>

        </div>
    );
}
