export const routes = {
    login: {
        base: "/login",
    },
    join: {
        base: "/join", // code verification
        account: {
            base: "/join/account", // account register form
            verification: "join/verification", // cv + linkedin verification
        },
        thankYou: "/join/thank-you"
    },
    introduction: {
        base: "/introduction",
        tutorial: "/introduction/tutorial",
        questions: "/introduction/questions",
        mobile: "/introduction/mobile",
    },
    dashboard: {
        base: "/dashboard",
        opportunities: {
            base: "/dashboard/opportunities",
            matched: "/dashboard/opportunities/matched",
            all: "/dashboard/opportunities/all",
            details: "/dashboard/opportunities/details",
        },
        applications: {
            base: "/dashboard/applications",
        },
        preferences: {
            base: "/dashboard/profile",
        }
    },
};
