import {Action, configureStore, ThunkAction,} from '@reduxjs/toolkit';
import preregistrationReducer from './preregistration/preregistrationSlice';
import baseReducer from './base/baseSlice';
import userReducer from './user/userSlice';
import vacanciesReducer from './vacancies/vacanciesSlice';
import preferencesReducer from './preferences/preferencesSlice';


export const store = configureStore({
    reducer: {
        base: baseReducer,
        user: userReducer,
        preregistration: preregistrationReducer,
        vacancies: vacanciesReducer,
        preferences: preferencesReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
