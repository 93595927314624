import styles from "./vacancyHeader.module.scss";
import React from "react";
import {VacancyInterface} from "../../../interfaces/VacancyInterface";
import clsx from "clsx";
import {Typography} from "../../ui/typography/Typography";
import {Salary} from "../salary/Salary";
import {VacancyExtendedInterface} from "../../../interfaces/VacancyExtendedInterface";
import {decode} from "html-entities";

type VacancyCardProps = {
    readonly vacancy: VacancyInterface | VacancyExtendedInterface;
    readonly logo: boolean;
};

export function VacancyHeader ({
                             vacancy,
    logo = false,
                         }: VacancyCardProps) {
    return (
            <header className={clsx(styles.vacancyHeader)}>
                <div className={clsx(styles.vacancyHeaderLeft)}>
                    {
                        logo &&
                        <div className={styles.vacancyHeaderLogo} style={{backgroundImage: `url(${ vacancy.company.logoUrl })`}} />
                    }
                    <div>
                        <Typography as={"h4"} margin={0} className={styles.vacancyHeaderDark}>{vacancy.company.name}</Typography>
                        <Typography as={"p"} margin={0} className={styles.vacancyHeaderLight}>{vacancy.title}</Typography>
                    </div>
                </div>
                <div className={clsx(styles.vacancyHeaderRight, styles.vacancyHeaderColumnsInside)}>
                    <div className={styles.vacancyHeaderColumnPqe}>
                        <Typography as={"h4"} margin={0} className={styles.vacancyHeaderDark}>{decode(vacancy.pqe)}</Typography>
                        <Typography as={"p"} margin={0} className={styles.vacancyHeaderLight}>Tenure</Typography>
                    </div>

                    <div className={styles.vacancyHeaderColumnSalary}>
                        <Typography as={"h4"} margin={0} className={styles.vacancyHeaderDark}>
                            <Salary salary={vacancy.salary} />
                        </Typography>
                        <Typography as={"p"} margin={0} className={styles.vacancyHeaderLight}>Base salary</Typography>
                    </div>
                </div>
            </header>

    )
}
