import clsx from 'clsx';
import {forwardRef, memo} from 'react';

import styles from './button.module.scss';
import {Icon} from "../icon/Icon";
import {Loader} from "../loader/Loader";

type ButtonProps = {
    readonly as?: 'a' | 'button';
    readonly iconLeft?: string;
    readonly iconRight?: string;
    readonly children?: React.ReactNode;
    readonly disabled?: boolean;
    readonly notValid?: boolean;
    readonly className?: string;
    readonly onClick?: (e: MouseEvent | any) => void;
    readonly href?: string;
    readonly loading?: boolean;
    readonly target?: string;
    readonly type?: "button" | "submit" | "reset" | undefined;
    readonly size?: "xs" | "sm" | "md" | "auto";
    readonly full?: boolean;
    readonly rel?: string;
    readonly typeStyle?: "normal" | "link";
    readonly buttonStyle?: 'normal' | 'danger' | 'ghost' | 'light' | 'dark';
};

export const Button = memo<ButtonProps>(
    forwardRef(
        (
            {
                as: As = 'button',
                iconLeft,
                iconRight,
                children,
                full,
                loading = false,
                type = "button",
                notValid = false,
                className = '',
                size="md",
                typeStyle = "normal",
                disabled = false,
                buttonStyle = 'normal',
                ...props
            },
            ref,
        ) => {
            return (
                <As
                    className={clsx(styles.button, className, {
                        [styles.buttonDisabled]: disabled,
                        [styles.buttonNotValid]: notValid,
                        [styles.buttonIconLeft]: iconLeft,
                        [styles.buttonIconRight]: iconRight,
                        [styles.buttonTypeNormal]: typeStyle === "normal",
                        [styles.buttonTypeLink]: typeStyle === "link",
                        [styles.buttonStyleDanger]: buttonStyle === 'danger',
                        [styles.buttonStyleNormal]: buttonStyle === 'normal',
                        [styles.buttonStyleGhost]: buttonStyle === 'ghost',
                        [styles.buttonStyleLight]: buttonStyle === 'light',
                        [styles.buttonStyleDark]: buttonStyle === 'dark',
                        [styles.buttonSizeAuto]: size === 'auto',
                        [styles.buttonSizeMd]: size === 'md',
                        [styles.buttonSizeSm]: size === 'sm',
                        [styles.buttonSizeXs]: size === 'xs',
                        [styles.buttonFull]: full,
                    })}
                    type={type}
                    // @ts-ignore
                    ref={ref}
                    {...props}
                >
                    {
                        iconLeft &&
                            <Icon className={clsx(styles.icon)} icon={iconLeft} />
                    }
                    {
                        loading &&
                            <Loader className={styles.loading} />
                    }
                    {children}
                    {
                        iconRight &&
                            <Icon className={clsx(styles.icon)} icon={iconRight} />
                    }
                </As>
            );
        },
    ),
);
Button.displayName = 'Button';
