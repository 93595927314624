import React, {useEffect, useState} from "react";
import styles from "./questions.module.scss";
import welcomeScreen from "img/illustrations/welcome_screen.svg";
import {Typography} from "../../ui/typography/Typography";
import {Box} from "../box/Box";
import {Button} from "../../ui/button/Button";
import {useAppDispatch} from "../../../hooks";
import {QuestionInterface} from "../../../interfaces";
import {getQuestions} from "../../../store/base/baseApi";
import {Question} from "../question/Question";
// @ts-ignore
import {AnimateOnChange} from "react-animation";
import {QuestionSubmit} from "../questionSubmit/QuestionSubmit";
import {Loading} from "../../ui/loading/Loading";

type TutorialProps = {};

export const Questions = ({...props}: TutorialProps) => {

    const dispatch = useAppDispatch();
    const [questions, setQuestions] = useState<Array<QuestionInterface> | null>(null);
    const [currentStep, setCurrentStep] = useState<number>(0);

    useEffect(() => {
        dispatch(getQuestions(setQuestions));
    }, [dispatch]);

    return (
        <>
            {
                currentStep === 0 &&
                <Box>
                    <img src={welcomeScreen} className={styles.image} alt={"Welcome"}/>
                    <Typography as={"h1"} align={"center"}>Welcome to Counselpath!</Typography>
                    <Typography as={"p"} margin={7} align={"center"}>Let’s begin by answering a few questions that will help us guide you towards the most relevant opportunities. This will take about 5 minutes. No worries if you later change your mind, you can update your answers at any time in ‘My preferences’ panel.</Typography>
                    <Button className={styles.button} onClick={() => setCurrentStep(1)}>Get started</Button>
                </Box>
            }

            {
                !questions && currentStep !== 0 &&
                    <div className={styles.loadingWrapper}>
                        <Loading />
                    </div>
            }

            <AnimateOnChange>
                {
                    questions &&
                        <>
                            {
                                currentStep > 0 && currentStep <= questions.length &&
                                <Question
                                    key={"question_" + currentStep}
                                    allSteps={questions.length + 1 } // + Submit step
                                    question={questions[currentStep - 1]}
                                    step={currentStep}
                                    goPrev={() => setCurrentStep(currentStep - 1)}
                                    goNext={() => setCurrentStep(currentStep + 1)}
                                />
                            }

                            {
                                currentStep === questions.length + 1 &&
                                    <QuestionSubmit
                                        questions={questions}
                                        goPrev={() => setCurrentStep(currentStep - 1)}
                                    />
                            }
                        </>
                }
            </AnimateOnChange>

        </>
    );
}
