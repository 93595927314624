import React, {useEffect, useState} from "react";
import {QuestionInterface} from "ts/interfaces";
import {useAppDispatch, useAppSelector} from "ts/hooks";
import {
    addUserFieldAdditionalJurisdictionItem,
    addUserFieldJurisdictionItem,
    removeUserFieldAdditionalJurisdictionItem,
    removeUserFieldAdditionalJurisdictionItems,
    removeUserFieldJurisdictionItem,
    selectUserFieldAdditionalJurisdiction,
    selectUserFieldJurisdiction,
} from "ts/store/user/userSlice";
import {TermInterface} from "../../../../../interfaces/TermInterface";
import styles from "./questionSeven.module.scss";
import {Searchable} from "../../../../ui/searchable/Searchable";
import {TermStateInterface} from "../../../../../interfaces/TermStateInterface";
import {Icon} from "../../../../ui/icon/Icon";
import {Checkbox} from "../../../../ui/checkbox/Checkbox";
import clsx from "clsx";

type QuestionSevenProps = {
    question: QuestionInterface,
    onValid: (isValid: boolean) => void,
    fieldSlug?: string,
};

export const QuestionSeven = ({
                                onValid,
                                question,
                                fieldSlug = question.fieldSlug
                         }: QuestionSevenProps) => {

    const [groups, setGroups] = useState<Array<TermInterface>>([]);
    const [addAdditionalJurisdictions, setAddAdditionalJurisdictions] = useState<boolean>(false);
    const values = useAppSelector(selectUserFieldJurisdiction) || [];
    const valuesAdditional = useAppSelector(selectUserFieldAdditionalJurisdiction) || [];
    const dispatch = useAppDispatch();

    useEffect(() => {
        onValid(values.length > 0);

        if (values.length === 0) {
            setAddAdditionalJurisdictions(false);
            dispatch(
                removeUserFieldAdditionalJurisdictionItems()
            );
        }
    }, [values]);

    useEffect(() => {
        const block = question.type[0];
        const blockTag = block.tag;
        const ch = blockTag ? block[blockTag] || [] : [];

        setGroups(ch);

    }, [question]);

    useEffect(() => {
        if (valuesAdditional.length > 0) {
            setAddAdditionalJurisdictions(true);
        }

    }, [valuesAdditional])

    function handleSelect (term: TermInterface) {
        const i = values.findIndex(_item => _item.term_id === term.term_id);
        if (i < 0) {
            dispatch(
                addUserFieldJurisdictionItem({
                    term_id: term.term_id,
                    name: term.name
                })
            );
        }
    }

    function handleSelectAdditional (term: TermInterface) {
        const i = valuesAdditional.findIndex(_item => _item.term_id === term.term_id);
        if (i < 0) {
            dispatch(
                addUserFieldAdditionalJurisdictionItem({
                    term_id: term.term_id,
                    name: term.name
                })
            );
        }
    }

    function handleRemoveTerm (term: TermStateInterface) {
        dispatch(
            removeUserFieldJurisdictionItem(term.term_id)
        );
    }

    function handleRemoveAdditionalTerm (term: TermStateInterface) {
        dispatch(
            removeUserFieldAdditionalJurisdictionItem(term.term_id)
        );
    }

    function handleChangeAddAdditionalJurisdictions (e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.checked) {
            setAddAdditionalJurisdictions(true);
        } else {
            setAddAdditionalJurisdictions(false);
            dispatch(
                removeUserFieldAdditionalJurisdictionItems()
            );
        }
    }

    return (
        <div>
            <div className={styles.results}>
                {
                    values.map((term: TermStateInterface) => (
                        <div className={styles.result} key={term.term_id}>
                            <button className={styles.resultButton} onClick={(e) => handleRemoveTerm(term)}>
                                <Icon icon={"close"} />
                            </button>
                            <span  className={styles.resultLabel}>{term.name}</span>
                        </div>
                    ))
                }
            </div>
            {
                values.length < 1 &&
                <Searchable
                    closeToggle={() => {}}
                    disabled={values.length > 0}
                    onSelect={handleSelect}
                    name={question.fieldSlug}
                    suggestions={groups}
                    label={"Type here..."}
                />
            }
            {
                values.length > 0 &&
                <>
                    <div className={styles.additionalCheckbox}>
                        <Checkbox
                            id={"add_additional_jurisdictions"}
                            name={"add_additional_jurisdictions"}
                            onChange={handleChangeAddAdditionalJurisdictions}
                            defaultChecked={valuesAdditional.length > 0 || addAdditionalJurisdictions}
                        >
                            I am qualified in more than one jurisdiction
                        </Checkbox>
                    </div>
                    {
                        addAdditionalJurisdictions &&
                        <div className={styles.additionalBox}>
                            <p>Which other jurisdictions are you qualified in? Select all that apply</p>
                            <div className={clsx(styles.results, styles.resultsAdditions)}>
                                {
                                    valuesAdditional.map((term: TermStateInterface) => (
                                        <div className={styles.result} key={term.term_id}>
                                            <button className={styles.resultButton}
                                                    onClick={(e) => handleRemoveAdditionalTerm(term)}>
                                                <Icon icon={"close"}/>
                                            </button>
                                            <span className={styles.resultLabel}>{term.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                            <Searchable
                                closeToggle={() => {
                                }}
                                onSelect={handleSelectAdditional}
                                name={question.fieldSlug + "_additional"}
                                suggestions={groups}
                                label={"Type here..."}
                                excerpt={values}
                            />
                        </div>
                    }
                </>
            }
        </div>
    );
}
