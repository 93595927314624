import clsx from 'clsx';
import styles from './brand.module.scss';
import React from "react";

type BrandProps = {
    readonly theme?: 'light' | 'dark';
    readonly size?: 'medium';
};

export function Brand({
                          theme = "dark",
                          size = "medium"
                      }: BrandProps) {
    return (
        <div
            className={clsx(styles.wrapper, {
                [styles.wrapperMedium]: size === 'medium',
                [styles.wrapperLight]: theme === 'light',
                [styles.wrapperDark]: theme === 'dark',
            })}
        >
            <a rel={"noreferrer"} className={styles.link} target={"_blank"} href={process.env.REACT_APP_LANDING_URL}>
                <svg className={styles.svg} viewBox="0 0 795 310" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M146.564 145.198C176.764 145.198 197.564 124.398 197.564 94.5983C197.564 64.7983 176.764 43.9983 146.564 43.9983C116.364 43.9983 95.5645 64.7983 95.5645 94.5983C95.5645 124.398 116.364 145.198 146.564 145.198ZM146.564 133.998C132.764 133.998 127.164 120.998 127.164 98.5983V90.7983C127.164 68.1983 132.764 55.1983 146.564 55.1983C160.564 55.1983 165.964 68.1983 165.964 90.7983V98.3983C165.964 120.998 160.564 133.998 146.564 133.998Z"
                        fill="black"/>
                    <path
                        d="M302.357 118.798V47.9983C302.357 44.1983 299.557 42.7983 295.757 44.7983L267.157 57.3983C264.557 58.5983 263.557 59.9983 263.557 61.7983C263.557 67.9983 273.957 64.9983 273.957 73.5983V121.598C267.757 125.398 261.757 126.398 256.757 126.398C245.757 126.398 241.157 121.598 241.157 107.198V48.7983C241.157 44.9983 238.357 43.5983 234.357 45.3983L205.357 58.5983C203.357 59.5983 202.157 60.7983 202.157 62.3983C202.157 68.3983 212.557 65.5983 212.557 74.1983V115.798C212.557 136.598 223.757 145.198 239.157 145.198C251.557 145.198 263.157 141.398 273.957 132.798V141.398C273.957 145.198 276.157 146.598 279.957 145.198L309.157 134.598C311.157 133.798 312.157 132.398 312.157 130.998C312.157 125.198 302.357 126.598 302.357 118.798Z"
                        fill="black"/>
                    <path
                        d="M417.864 128.198V71.9983C417.864 52.5983 407.864 43.9983 392.464 43.9983C380.264 43.9983 366.464 49.5983 355.064 57.3983V48.3983C355.064 44.1983 351.864 43.1983 348.264 44.7983L320.064 55.7983C318.064 56.7983 316.864 58.1983 316.864 59.7983C316.864 64.7983 326.464 63.9983 326.464 70.3983V128.198C326.464 132.998 317.064 134.398 317.064 138.998C317.064 140.998 318.864 142.798 321.464 142.798H360.264C362.664 142.798 364.464 140.998 364.464 138.998C364.464 134.198 355.064 132.998 355.064 128.198V67.1983C362.064 64.5983 368.664 63.3983 374.064 63.3983C384.864 63.3983 389.264 67.9983 389.264 82.3983V128.198C389.264 133.398 380.464 133.998 380.464 139.198C380.464 140.998 382.264 142.798 384.464 142.798H423.064C425.464 142.798 427.264 140.998 427.264 138.998C427.264 134.398 417.864 132.998 417.864 128.198Z"
                        fill="black"/>
                    <path
                        d="M472.497 145.198C494.697 145.198 510.097 134.598 510.097 116.398C510.097 102.598 500.897 92.1983 476.897 81.5983C460.297 74.3983 456.297 69.9983 456.297 64.5983C456.297 58.5983 461.097 54.9983 467.697 54.9983C484.297 54.9983 485.497 77.1983 499.097 77.1983C505.097 77.1983 508.697 72.7983 508.697 65.9983C508.697 50.3983 488.897 43.9983 471.297 43.9983C449.497 43.9983 434.697 53.7983 434.697 72.1983C434.697 86.7983 443.697 95.5983 466.697 106.198C482.097 113.598 487.097 117.798 487.097 124.398C487.097 130.198 482.697 134.198 474.897 134.198C456.497 134.198 458.297 112.398 445.297 112.398C439.097 112.398 434.297 117.198 434.297 123.998C434.297 138.798 456.697 145.198 472.497 145.198Z"
                        fill="black"/>
                    <path
                        d="M568.052 145.198C587.652 145.198 601.252 136.198 607.052 123.398C610.052 117.398 608.452 114.198 605.452 114.198C600.452 114.198 595.252 123.798 578.252 123.798C559.452 123.798 545.452 112.398 544.652 90.7983H598.452C605.052 90.7983 607.452 88.7983 607.452 81.1983C607.452 63.3983 594.452 43.9983 567.052 43.9983C538.452 43.9983 518.252 64.5983 518.252 94.3983C518.252 123.798 538.252 145.198 568.052 145.198ZM544.852 79.7983C545.452 63.5983 554.052 54.9983 565.652 54.9983C577.252 54.9983 582.452 63.5983 582.452 74.3983C582.452 77.9983 581.052 79.7983 575.852 79.7983H544.852Z"
                        fill="black"/>
                    <path
                        d="M47.8 145.307C71.2 145.307 87.8 131.307 87.8 117.707C87.8 115.307 86.6 113.907 84.2 113.907C78.8 113.907 75 122.107 58.4 122.107C36.4 122.107 24.8 108.107 24.8 85.9074C24.8 65.3074 34.8 55.1074 49 55.1074C55 55.1074 60.2 56.9074 60.2 61.7074C60.2 63.7074 59.4 67.7074 59.4 71.5074C59.4 83.3074 66 89.7074 74.8 89.7074C83.4 89.7074 89.2 83.3074 89.2 73.1074C89.2 54.3074 70.4 44.1074 50.2 44.1074C19.4 44.1074 0 67.9074 0 95.9074C0 123.307 18.2 145.307 47.8 145.307Z"
                        fill="black"/>
                    <path
                        d="M652.331 4.19838V122.393C652.331 124.026 651.534 125.555 650.196 126.491L630.023 140.598C627.372 142.452 623.731 140.555 623.731 137.32V35.498C623.731 29.098 614.131 29.898 614.131 24.898C614.131 23.298 614.803 22.498 617.331 20.898C619.859 19.298 645.531 0.598376 645.531 0.598376C649.131 -0.80163 652.331 0.19837 652.331 4.19838Z"
                        fill="black"/>
                    <path
                        d="M473.303 171.086C463.503 171.086 453.503 174.486 443.703 181.086V175.486C443.703 171.686 441.103 170.086 436.903 171.886L408.703 182.886C406.503 183.886 405.303 185.286 405.303 186.886C405.303 191.886 415.103 190.886 415.103 197.886V293.286C415.103 300.686 405.703 300.686 405.703 305.686C405.703 307.886 407.503 309.486 409.903 309.486H448.503C450.703 309.486 452.503 307.886 452.503 305.886C452.503 299.686 443.703 300.886 443.703 293.286V269.486C449.103 271.486 453.903 272.286 459.503 272.286C488.703 272.286 508.703 251.686 508.703 215.686C508.703 187.886 494.503 171.086 473.303 171.086ZM459.103 261.286C449.703 261.286 443.703 256.286 443.703 242.686V191.486C447.503 190.086 451.103 189.286 455.503 189.286C471.303 189.286 480.703 199.286 480.703 223.686C480.703 251.086 472.103 261.286 459.103 261.286Z"
                        fill="black"/>
                    <path
                        d="M542.495 272.286C555.295 272.286 566.295 266.486 572.295 258.286C575.295 266.486 580.895 272.286 591.295 272.286C602.295 272.286 608.695 265.686 608.695 259.086C608.695 256.086 607.695 255.086 606.295 255.086C604.895 255.086 603.895 255.686 602.495 255.686C600.495 255.686 599.095 254.286 599.095 251.286V204.886C599.095 181.686 588.495 171.086 562.295 171.086C533.295 171.086 517.695 184.086 517.695 196.686C517.695 203.886 522.495 208.886 530.295 208.886C548.095 208.886 544.695 181.886 561.495 181.886C567.695 181.886 570.695 185.486 570.695 194.286V213.486C533.295 217.686 516.295 231.086 516.295 248.886C516.295 262.086 525.695 272.286 542.495 272.286ZM557.895 254.286C549.895 254.286 545.095 249.686 545.095 242.286C545.095 234.286 550.695 225.886 570.695 223.286V249.086C566.495 252.686 562.695 254.286 557.895 254.286Z"
                        fill="black"/>
                    <path
                        d="M651.382 272.286C668.382 272.286 682.782 264.086 682.782 256.686C682.782 254.486 681.382 253.286 679.582 253.286C676.182 253.286 672.782 256.486 664.982 256.486C656.782 256.486 652.582 252.886 652.582 244.286V186.086H677.782C681.382 186.086 683.382 184.286 683.382 181.086V178.286C683.382 175.286 681.382 173.486 677.782 173.486H652.582V152.286C652.582 148.086 648.982 147.086 645.782 149.686L610.582 175.086C605.582 178.686 607.582 184.886 612.982 184.886H618.582C622.182 184.886 624.182 186.886 624.182 190.486V245.286C624.182 264.686 635.182 272.286 651.382 272.286Z"
                        fill="black"/>
                    <path
                        d="M786.028 253.686V197.886C786.028 180.286 777.428 171.086 762.028 171.086C748.428 171.086 734.628 177.886 723.228 184.686V131.286C723.228 127.286 720.028 126.086 716.628 127.686L688.228 147.886C686.303 149.5 684.828 150.286 684.828 151.886C684.828 156.886 694.628 156.086 694.628 162.486V253.686C694.628 261.086 685.228 260.286 685.228 266.086C685.228 268.286 687.028 269.886 689.428 269.886H728.028C730.228 269.886 732.028 268.086 732.028 266.286C732.028 260.086 723.228 261.286 723.228 253.686V194.486C730.028 192.086 736.628 190.486 742.228 190.486C753.028 190.486 757.428 196.486 757.428 210.886V253.686C757.428 261.086 748.028 260.086 748.028 266.086C748.028 268.086 749.828 269.886 752.228 269.886H790.828C793.228 269.886 794.828 268.286 794.828 266.286C794.828 261.086 786.028 261.486 786.028 253.686Z"
                        fill="black"/>
                </svg>
            </a>
        </div>
    )
}
