import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector, useDocumentTitle} from "../../../hooks";
import {selectUser} from "../../../store/user/userSlice";
import {Navigate} from "react-router-dom";
import {routes} from "../../../config/routes";
import {Box} from "../../partials/box/Box";
import {Col, Row} from "react-grid-system";
import styles from "./profile.module.scss";
import {
    setIsLoading,
    selectIsLoading,
    selectPreferences,
} from "../../../store/preferences/preferencesSlice"
import {getUserPreferences, updateUserPreferences} from "../../../store/preferences/preferencesApi";
import {DarkBox} from "../../partials/darkBox/DarkBox";
import {RadioBars} from "../../ui/radioBars/RadioBars";
import {Input} from "../../ui/input/Input";
import {TermInterface} from "../../../interfaces/TermInterface";
import {Pill} from "../../ui/pill/Pill";
import {CheckboxBars} from "../../ui/checkboxBars/CheckboxBars";
import {useForm} from "react-hook-form";
import {RequestUserSavePreferencesInterface} from "../../../interfaces/api";
import {Button} from "../../ui/button/Button";
import {passwordValidation, spaceLessValidation} from "../../../utils/validations";
import {PreferencesOptionsInterface} from "../../../interfaces/PreferencesOptionsInterface";
import {Status} from "../../ui/status/Status";
import {TermStateInterface} from "../../../interfaces/TermStateInterface";

const Profile: React.FC = () => {

    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const preferences = useAppSelector(selectPreferences);
    const [localPreferences, setLocalPreferences] = useState<PreferencesOptionsInterface | null>(null);
    const [successLocal, setLocalSuccess] = useState(false);
    const [errorLocal, setLocalError] = useState<string>("");
    const [practiceParent, setPracticeParent] = useState<number>(-1);

    const {
        register,
        watch,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm<RequestUserSavePreferencesInterface>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });

    const new_password = useRef({});
    new_password.current = watch("new_password", "");

    useDocumentTitle(`My preferences - Counselpath`);


    useEffect(() => {
        dispatch(setIsLoading(true));
        dispatch(getUserPreferences());
    }, []);

    useEffect(() => {
        if (preferences) {
            const n = {...preferences};
            setLocalPreferences(n);

            // Select parent of practices
            if (preferences.u_practices.selected.length > 0) {
                const selectedPracticeId = parseInt(preferences.u_practices.selected[0]);
                (preferences.u_practices.type[0].practice_tag || []).map((term) => {
                    if (selectedPracticeId === term.term_id) {
                        setPracticeParent(term.parent);
                    }
                })
            }
        }
    }, [preferences]);

    useEffect(() => {
        if (localPreferences) {
            // console.log(localPreferences);
        }
    }, [localPreferences]);

    const onSubmit = handleSubmit(data => {
        if (localPreferences) {
            dispatch(updateUserPreferences(localPreferences, handleSuccessSaved, handleErrorSaved));
        }
    });

    function handleChangeForm () {

    }

    function handleSuccessSaved () {
        setLocalError("");
        setLocalSuccess(true);

        reset({
            current_password: "",
            new_password: "",
            new_password2: "",
        });

        if (localPreferences) {
            setLocalPreferences({
                ...localPreferences,
                current_password: "",
                new_password: "",
                new_password2: "",
            })
        }
    }

    function handleErrorSaved (errorMessage: string) {
        setLocalSuccess(false);
        setLocalError(errorMessage);
    }

    function handleChangeCurrentStatus (e: SyntheticEvent<HTMLInputElement>) {
        if (localPreferences) {
            setLocalPreferences({
                ...localPreferences,
                "u_status": {
                    ...localPreferences.u_status,
                    selected: [e.currentTarget.value]
                }
            })
        }
    }

    function handleChangeSegments (e: SyntheticEvent<HTMLInputElement>, term_id: number) {
        if (localPreferences) {
            setLocalPreferences({
                ...localPreferences,
                "u_segments": {
                    ...localPreferences.u_segments,
                    selected: addOrRemoveFromArray(localPreferences.u_segments.selected, term_id.toString())
                }
            })
        }
    }

    function handleChangeFactors (e: SyntheticEvent<HTMLInputElement>, term_id: number) {
        if (localPreferences) {
            if (
                localPreferences.u_factors.selected.length < 3
            ) {
                setLocalPreferences({
                    ...localPreferences,
                    "u_factors": {
                        ...localPreferences.u_factors,
                        selected: addOrRemoveFromArray(localPreferences.u_factors.selected, term_id.toString())
                    }
                })
            } else {
                if (localPreferences.u_factors.selected.indexOf(term_id.toString()) > 0) {
                    setLocalPreferences({
                        ...localPreferences,
                        "u_factors": {
                            ...localPreferences.u_factors,
                            selected: addOrRemoveFromArray(localPreferences.u_factors.selected, term_id.toString())
                        }
                    })
                }
            }
        }
    }

    function handleChangePreferences (e: SyntheticEvent<HTMLInputElement>) {
        if (localPreferences) {
            setLocalPreferences({
                ...localPreferences,
                "u_work_preferences": {
                    ...localPreferences.u_work_preferences,
                    selected: addOrRemoveFromArray(localPreferences.u_work_preferences.selected, e.currentTarget.value)
                }
            })
        }
    }

    function handleChangeCommunicationPreferences (e: SyntheticEvent<HTMLInputElement>) {
        if (localPreferences) {
            setLocalPreferences({
                ...localPreferences,
                "u_communication_preferences": {
                    ...localPreferences.u_communication_preferences,
                    selected: addOrRemoveFromArray(localPreferences.u_communication_preferences.selected, e.currentTarget.value, true)
                }
            })
        }
    }

    function handleChangeSectors (e: SyntheticEvent<HTMLInputElement>, term_id: number) {
        if (localPreferences) {
            setLocalPreferences({
                ...localPreferences,
                "u_sectors": {
                    ...localPreferences.u_sectors,
                    selected: addOrRemoveFromArray(localPreferences.u_sectors.selected, term_id.toString())
                }
            })
        }
    }

    function handleChangePractices (e: SyntheticEvent<HTMLInputElement>, term_id: number, term: TermInterface) {
        let toRemove = false;
        let newPractices:Array<string> = [];
        if (localPreferences) {

            toRemove = (
                term.parent !== practiceParent &&
                localPreferences.u_practices.selected.length > 0
            );

            newPractices = toRemove ? [term_id.toString()] : addOrRemoveFromArray(localPreferences.u_practices.selected, term_id.toString());

            setPracticeParent(term.parent);

            setLocalPreferences({
                ...localPreferences,
                "u_practices": {
                    ...localPreferences.u_practices,
                    selected: newPractices
                }
            })
        }
    }

    function handleChangeRequireVisa (e: SyntheticEvent<HTMLInputElement>) {
        if (localPreferences) {
            setLocalPreferences({
                ...localPreferences,
                "u_require_visa": {
                    ...localPreferences.u_require_visa,
                    selected: [e.currentTarget.value]
                }
            })
        }
    }

    function changeTextValue (slug: "first_name" | "last_name" | "current_password" | "new_password" | "new_password2", value: string) {
        if (localPreferences) {
            const n = {
                ...localPreferences
            }
            n[slug] = value;
            setLocalPreferences({
                ...n,
            })
        }
    }

    function addOrRemoveFromArray (array: Array<string>, item: string, allowRemoveAll: boolean = false) {
        const newArray = [...array];
        const index = newArray.indexOf(item);
        if ( index > -1 ) {
            if (array.length > 1 || allowRemoveAll) {
                newArray.splice(index, 1);
            }

        }
        else newArray.push(item);
        return newArray;
    }


    if (!user) {
        return <Navigate to={routes.login.base} replace={true}/>
    }

    return (
        <div>
            <Row>
                <Col sm={3}>
                </Col>
                <Col sm={9}>
                    <Box size={"full"}>
                        <h2 className={"h5"}>My preferences</h2>
                        {
                            isLoading &&
                                <p>Loading preferences...</p>
                        }
                        {
                            // !isLoading &&
                            localPreferences &&
                            <form onSubmit={onSubmit} className={styles.form} onChange={handleChangeForm}>

                            <div>
                                    <DarkBox
                                        title={"Name & lastname"}
                                    >
                                        <Row>
                                            <Col sm={6}>
                                                <Input
                                                    isRequired={true}
                                                    type={"text"}
                                                    label={"First name"}
                                                    id={"first_name"}
                                                    value={localPreferences.first_name}
                                                    {...register(
                                                        "first_name",
                                                        {
                                                            required: "Field is required",
                                                            validate: {
                                                                spaceLess: v => spaceLessValidation(v)
                                                            }
                                                        }
                                                    )}
                                                    onChange={(e) => changeTextValue("first_name", e.currentTarget.value)}
                                                    hasError={!!errors.first_name}
                                                    errorMessage={errors.first_name?.message}
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Input
                                                    isRequired={true}
                                                    type={"text"}
                                                    label={"Last name"}
                                                    id={"last_name"}
                                                    value={localPreferences.last_name}
                                                    {...register(
                                                        "last_name",
                                                        {
                                                            required: "Field is required",
                                                            validate: {
                                                                spaceLess: v => spaceLessValidation(v)
                                                            }
                                                        }
                                                    )}
                                                    onChange={(e) => changeTextValue("last_name", e.currentTarget.value)}
                                                    hasError={!!errors.last_name}
                                                    errorMessage={errors.last_name?.message}
                                                />
                                            </Col>
                                        </Row>
                                    </DarkBox>

                                    <DarkBox
                                        title={"E-mail"}
                                    >
                                        <Input
                                            isRequired={true}
                                            type={"email"}
                                            label={"E-mail"}
                                            id={"user_email"}
                                            name={"user_email"}
                                            disabled={true}
                                            value={localPreferences.email}
                                        />

                                    </DarkBox>

                                    <DarkBox
                                        title={"Change password"}
                                    >
                                        <Row>
                                            <Col sm={4}>
                                                <Input
                                                    type={"password"}
                                                    label={"Current password"}
                                                    id={"current_password"}
                                                    value={localPreferences.current_password}
                                                    {...register(
                                                        "current_password",
                                                        {

                                                        }
                                                    )}
                                                    onChange={(e) => changeTextValue("current_password", e.currentTarget.value)}
                                                    hasError={!!errors.current_password}
                                                    errorMessage={errors.current_password?.message}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Input
                                                    type={"password"}
                                                    label={"New password"}
                                                    id={"new_password"}
                                                    value={localPreferences.new_password}
                                                    {...register(
                                                        "new_password",
                                                        {
                                                            validate: {
                                                                positive: v => v.length > 0 ? passwordValidation(v) : true,
                                                            },
                                                        }
                                                    )}
                                                    onChange={(e) => changeTextValue("new_password", e.currentTarget.value)}

                                                    hasError={!!errors.new_password}
                                                    errorMessage={errors.new_password?.message}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Input
                                                    type={"password"}
                                                    label={"Repeat password"}
                                                    id={"new_password2"}
                                                    value={localPreferences.new_password2}
                                                    {...register(
                                                        "new_password2",
                                                        {
                                                            validate: v => v.length > 0 ? v === new_password.current || "The passwords do not match" : true
                                                        }
                                                    )}
                                                    onChange={(e) => changeTextValue("new_password2", e.currentTarget.value)}
                                                    hasError={!!errors.new_password2}
                                                    errorMessage={errors.new_password2?.message}
                                                />
                                            </Col>
                                        </Row>

                                    </DarkBox>

                                    {
                                        localPreferences.u_status &&
                                        <DarkBox
                                            title={localPreferences.u_status.title}
                                            description={localPreferences.u_status.description}
                                        >
                                            <RadioBars
                                                selected={localPreferences.u_status.selected[0]}
                                                id={localPreferences.u_status.fieldSlug}
                                                name={localPreferences.u_status.fieldSlug}
                                                choices={localPreferences.u_status.type[0].values || []}
                                                defaultValue={localPreferences.u_status.selected[0]}
                                                onChange={handleChangeCurrentStatus}
                                            />
                                        </DarkBox>
                                    }

                                    {
                                        localPreferences.u_segments &&
                                        <DarkBox
                                            title={localPreferences.u_segments.title}
                                            description={localPreferences.u_segments.description}
                                        >
                                            {
                                                (localPreferences.u_segments.type[0].segment_tag || []).map((term: TermInterface) => {
                                                    if (term.parent !== 0) {
                                                        return (
                                                            <Pill
                                                                key={term.term_id}
                                                                id={localPreferences.u_segments.fieldSlug + "_" + term.term_id}
                                                                name={localPreferences.u_segments.fieldSlug}
                                                                checked={localPreferences.u_segments.selected.findIndex(_item => parseInt(_item) === term.term_id) > -1}
                                                                label={term.name}
                                                                value={term.term_id}
                                                                onChange={(e) => handleChangeSegments(e, term.term_id)}
                                                            />
                                                        )
                                                    }
                                                })
                                            }
                                        </DarkBox>
                                    }

                                    {
                                        localPreferences.u_factors &&
                                        <DarkBox
                                            title={localPreferences.u_factors.title}
                                            description={localPreferences.u_factors.description}
                                        >
                                            {
                                                (localPreferences.u_factors.type[0].factor_tag || []).map((term: TermInterface) => {
                                                    return (
                                                        <Pill
                                                            key={term.term_id}
                                                            id={localPreferences.u_factors.fieldSlug + "_" + term.term_id}
                                                            name={localPreferences.u_factors.fieldSlug}
                                                            checked={localPreferences.u_factors.selected.findIndex(_item => parseInt(_item) === term.term_id) > -1}
                                                            label={term.name}
                                                            value={term.term_id}
                                                            onChange={(e) => handleChangeFactors(e, term.term_id)}
                                                        />
                                                    )
                                                })
                                            }
                                        </DarkBox>
                                    }

                                    {
                                        localPreferences.u_work_preferences &&
                                        <DarkBox
                                            title={localPreferences.u_work_preferences.title}
                                            description={localPreferences.u_work_preferences.description}
                                        >
                                            <CheckboxBars
                                                defaultValues={localPreferences.u_work_preferences.selected}
                                                selected={localPreferences.u_work_preferences.selected}
                                                id={localPreferences.u_work_preferences.fieldSlug}
                                                name={localPreferences.u_work_preferences.fieldSlug}
                                                choices={localPreferences.u_work_preferences.type[0].values || []}
                                                onChange={(e) => handleChangePreferences(e)}
                                            />
                                        </DarkBox>
                                    }

                                    {
                                        localPreferences.u_sectors &&
                                        <DarkBox
                                            title={localPreferences.u_sectors.title}
                                            description={localPreferences.u_sectors.description}
                                        >
                                            {
                                                (localPreferences.u_sectors.type[0].sector_tag || []).map((term: TermInterface) => {
                                                    return (
                                                        <Pill
                                                            key={term.term_id}
                                                            id={localPreferences.u_sectors.fieldSlug + "_" + term.term_id}
                                                            name={localPreferences.u_sectors.fieldSlug}
                                                            checked={localPreferences.u_sectors.selected.findIndex(_item => parseInt(_item) === term.term_id) > -1}
                                                            label={term.name}
                                                            value={term.term_id}
                                                            onChange={(e) => handleChangeSectors(e, term.term_id)}
                                                        />
                                                    )
                                                })
                                            }
                                        </DarkBox>
                                    }


                                    {
                                        localPreferences.u_practices &&
                                        <DarkBox
                                            title={localPreferences.u_practices.title}
                                            description={localPreferences.u_practices.description}
                                        >

                                            {
                                                (localPreferences.u_practices.terms || []).map((t: any) => {
                                                    return (
                                                        <div className={styles.areaInner}>
                                                            <h6>{t.term.name}</h6>
                                                            {
                                                                t.children.map((term: any) => {
                                                                    return (
                                                                        <Pill
                                                                            key={term.term_id}
                                                                            id={localPreferences.u_practices.fieldSlug + "_" + term.term_id}
                                                                            name={localPreferences.u_practices.fieldSlug}
                                                                            checked={localPreferences.u_practices.selected.findIndex(_item => parseInt(_item) === term.term_id) > -1}
                                                                            label={term.name}
                                                                            value={term.term_id}
                                                                            onChange={(e) => handleChangePractices(e, term.term_id, term)}

                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </DarkBox>
                                    }

                                    {
                                        localPreferences.u_require_visa &&
                                        <DarkBox
                                            title={localPreferences.u_require_visa.title}
                                            description={localPreferences.u_require_visa.description}
                                        >
                                            <RadioBars

                                                defaultValue={localPreferences.u_require_visa.selected[0]}
                                                selected={localPreferences.u_require_visa.selected[0]}
                                                id={localPreferences.u_require_visa.fieldSlug}
                                                name={localPreferences.u_require_visa.fieldSlug}
                                                choices={localPreferences.u_require_visa.type[0].values || []}
                                                onChange={handleChangeRequireVisa}
                                            />

                                        </DarkBox>
                                    }

                                {
                                    localPreferences.u_communication_preferences &&
                                    <DarkBox
                                        title={localPreferences.u_communication_preferences.title}
                                        description={localPreferences.u_communication_preferences.description}
                                    >
                                        <CheckboxBars
                                            defaultValues={localPreferences.u_communication_preferences.selected}
                                            selected={localPreferences.u_communication_preferences.selected}
                                            id={localPreferences.u_communication_preferences.fieldSlug}
                                            name={localPreferences.u_communication_preferences.fieldSlug}
                                            choices={localPreferences.u_communication_preferences.type[0].values || []}
                                            onChange={(e) => handleChangeCommunicationPreferences(e)}
                                        />
                                    </DarkBox>
                                }


                                    <DarkBox
                                        title={"Your preferences on minimum salary"}
                                    >
                                        <p>
                                            Counselpath closely collaborates with legal employers to bring you a curated portfolio of top career opportunities.
                                            We adhere to the highest standards of service for candidates and employers alike, and we strictly protect confidentiality for both sides.
                                            We have disabled adjusting your preferences on minimum salary to ensure fair use of this feature on candidate side.
                                            Adjusting this setting is possible, but limited in terms of frequency. Please get in touch at contact@counselpath.com to adjust this setting.

                                        </p>
                                    </DarkBox>

                                    <DarkBox
                                        title={"Delete account"}
                                    >
                                        <p>Should you wish to delete your account please let us know at <a href={"mailto:contact@counselpath.com"}>contact@counselpath.com</a>.<br /> Upon your request we will remove your account alongside all current and past application data.</p>

                                    </DarkBox>


                                </div>

                                {
                                    successLocal &&
                                    <div className={styles.status}>
                                        <Status type={"success"} message={"Your preferences have been updated!"} />
                                    </div>
                                }
                                {
                                    errorLocal &&
                                    <div className={styles.status}>
                                        <Status type={"error"} message={errorLocal} />
                                    </div>
                                }

                                <Button
                                    type={"submit"}
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    Save preferences
                                </Button>

                            </form>

                        }


                    </Box>
                </Col>
            </Row>

        </div>
    );


}

export default Profile;
