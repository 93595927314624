import React, {SyntheticEvent, forwardRef, memo} from 'react';
import styles from "./modal.module.scss";
import clsx from 'clsx';
import {setModal} from "../../../store/base/baseSlice";
import {ModalsTypes} from "ts/typings/types";
import {useAppDispatch} from "../../../hooks";
import {Icon} from "../icon/Icon";
import {Button} from "../button/Button";


type ModalProps = {
    readonly isOpen: boolean;
    readonly children: React.ReactNode;
    readonly cta?: React.ReactNode;
    readonly width?: string | number;
    readonly onClose?: (e: SyntheticEvent<HTMLInputElement>) => void;
    readonly className?: string;
    readonly modalName: ModalsTypes;
    readonly title: string;
    readonly showCancel?: boolean;
    readonly showClose?: boolean;
};

export const Modal = memo<ModalProps>(
    forwardRef(
        (
            {
                isOpen = false,
                className,
                children,
                modalName,
                width,
                title,
                cta,
                showCancel = false,
                showClose = true,
                ...props
            },
            ref,
        ) => {

            const dispatch = useAppDispatch();

            function handleCloseClick () {
                dispatch(setModal({
                    modal: modalName,
                    state: false,
                }))
            }

            return (
                <div className={styles.wrapper}>
                    <div
                        className={clsx(styles.modal, {

                        })}
                    >
                        <div className={styles.header}>
                            <h5 className={styles.title}>{title}</h5>
                            {
                                showClose &&
                                    <button
                                        type={"button"}
                                        className={styles.buttonClose}
                                        onClick={handleCloseClick}
                                        title={"Close"}
                                    >
                                        <Icon icon={"close"}/>
                                    </button>
                            }
                        </div>
                        <div className={styles.main}>
                            {children}
                        </div>
                        {
                            (showCancel || cta) &&
                                <div className={styles.footer}>
                                    {
                                        showCancel &&
                                            <Button
                                                onClick={handleCloseClick}
                                                buttonStyle={"ghost"}
                                                typeStyle={"link"}
                                                size={"sm"}
                                            >
                                                Cancel
                                            </Button>
                                    }
                                    {
                                        cta &&
                                            <div className={styles.footerCta}>
                                                { cta }
                                            </div>
                                    }
                                </div>
                        }

                    </div>

                    <span className={styles.bg} onClick={handleCloseClick} />

                </div>

            );
        })
);
