import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {useAppSelector} from "../../hooks";
import {selectUserIsAuth} from "../../store/user/userSlice";

import {routes} from "../../config/routes";


type PrivateRouteProps = {
    readonly children?: React.ReactNode;
    readonly element?: React.ReactElement | null;
    readonly path: string;
}

export function PrivateRoute({
                                 path,
                                 children,
    element,
                                 ...rest
}: PrivateRouteProps) {

    const isAuth = useAppSelector(selectUserIsAuth);

    return isAuth
        ?
        (
            <Route path={path} {...rest} element={element}>{children}</Route>
        ): (
            <Navigate to={routes.login.base} replace={true}/>
            )

}
