import React, {useState} from 'react';
import {Modal} from "../../ui/modal/Modal";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectModals} from "../../../store/base/baseSlice";
import {Typography} from "../../ui/typography/Typography";
import {Input} from "../../ui/input/Input";
import {Button} from "../../ui/button/Button";
import {useForm} from "react-hook-form";
import {Status} from "../../ui/status/Status";
import {RequestVacancyQuestion} from "../../../interfaces/api";
import {VacancyExtendedInterface} from "../../../interfaces/VacancyExtendedInterface";
import {sendVacancyQuestion} from "../../../store/vacancies/vacanciesApi";
import {Textarea} from "../../ui/textarea/Textarea";
import styles from "./modalQuestion.module.scss";

interface ModalQuestionProps {
    vacancy: VacancyExtendedInterface
}

export function ModalQuestion({
                                  vacancy
                                    }: ModalQuestionProps) {

    const modals = useAppSelector(selectModals);
    const [success ,setSuccess] = useState(false);
    const [isLoading ,setIsLoading] = useState(false);

    const { register, reset, handleSubmit, formState: { errors }, getValues } = useForm<{message: string}>({
        mode: "onSubmit"
    });
    const dispatch = useAppDispatch();

    function onSuccess () {
        setSuccess(true);
        setIsLoading(false);
        reset();
    }

    const onSubmit = (data: RequestVacancyQuestion) => {
        setIsLoading(true);

        dispatch(
            sendVacancyQuestion(
                {
                    vacancyId: vacancy.id,
                    message: data.message
                },

                onSuccess
            )
        );
    };

    return (
        <Modal
            isOpen={modals.question ?? false}
            modalName={"question"}
            title={"Contact Counselpath"}
            showCancel={true}
            showClose={true}
            cta={
                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    iconLeft={"envelope"}
                    size={"sm"}
                >
                    Submit
                </Button>
            }
        >
            <Typography as={"h3"} margin={1}>Do you have a question about this role?</Typography>
            <Typography as={"p"} margin={4}>Fill out the form and a member of Counselpath team will get back to you as soon as possible.</Typography>

            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <Input
                    label={"Role"}
                    id={"vacancyId"}
                    name={"vacancyId"}
                    disabled={true}
                    type={"text"}
                    autoComplete={"off"}
                    defaultValue={
                        `${vacancy.company.name}, ${vacancy.title} (ID: ${vacancy.id})`
                    }

                />
                <label htmlFor={"message"} className={styles.label}>Message</label>
                <Textarea
                    {...register(
                        "message",
                        {
                            required: "Field is required",
                        }
                    )}
                    maxLength={1000}
                    id={"message"}
                    name={"message"}
                    placeholder={"Type your message here..."}
                    hasError={!!errors.message}
                    errorMessage={errors.message?.message}
                />
                {
                    success &&
                        <Status type={"success"} message={"Your message has been submitted. Thank you."} />
                }
            </form>

        </Modal>
    );

}

export default ModalQuestion;
