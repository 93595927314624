import React from "react";
import styles from "./questionSubmit.module.scss";
import stylesQuestion from "../question/question.module.scss";
import {Box} from "../box/Box";
import {QuestionInterface} from "ts/interfaces/QuestionInterface";
import {Button} from "../../ui/button/Button";
import {Accordion} from "../../ui/accordion/Accordion";
import {selectUserFields} from "ts/store/user/userSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {UserFieldsType} from "ts/typings/types";
import {decode} from "html-entities";
import {putUserFields} from "../../../store/user/userApi";
import {selectIsLoading} from "../../../store/base/baseSlice";
import {TermInterface} from "../../../interfaces/TermInterface";
import {TermStateInterface} from "../../../interfaces/TermStateInterface";

type QuestionProps = {
    questions: Array<QuestionInterface>,
    goPrev: () => void,
};

export const QuestionSubmit = ({
                             questions,
                             goPrev,
                         }: QuestionProps) => {

    const fields = useAppSelector(selectUserFields);
    const isLoading = useAppSelector(selectIsLoading);
    const dispatch = useAppDispatch();

    function handleClickSubmit() {
        dispatch(putUserFields(fields));
    }

    function Result({
                        value = ""
                    }: {
        value: any
    }) {
        return (
            <div className={styles.result}>{decode(value)}</div>
        )
    }

    function displayValues(v: any, slug: UserFieldsType, choices: any = []) {

        if (v) {
            if (choices.length > 0) {

                if (Array.isArray(v) && v.length == 0) {
                    return <p className={styles.questionDescriptionNoSpace}>No entry</p>
                }

                return choices.map((c: any, idx: number) => {
                    if (Array.isArray(v)) {
                        return v.map(t => {
                            if (c.value === t) {
                                return <Result key={"t_" + t.term_id} value={c.label}/>
                            }
                        })
                    } else {
                        if (c.value === v) {
                            return <Result key={"v_" + v.term_id} value={c.label}/>
                        }
                    }

                })
            } else {
                if (Array.isArray(v)) {
                    return v.map((t: any) => {
                        return <Result key={"tv_" + t.term_id} value={t.name}/>
                    })
                }
            }
            if (v.hasOwnProperty("month")) {
                return <Result value={v.month + "/" + v.year}/>
            }
        }

        if (v !== undefined) {
            if (slug === "u_salary") {
                if (isFinite(v) && v) {
                    return <Result value={`${v}K`}/>
                } else {
                    return <Result value={"I don’t have a minimum base salary in mind"}/>
                }
            }
        }

        return (
            <div>No entry</div>
        )
    }

    function displayPracticeParent (choices: Array<TermInterface>, selected: Array<TermStateInterface>) {
        if (selected.length > 0) {
            const singleSelectedTerm = selected[0];
            let parentId: number | null = null;
            let parent: any | TermInterface = null;

            choices.map((term) => {
                if (singleSelectedTerm.term_id === term.term_id) {
                    parentId = term.parent;
                }
            });

            if (parentId) {
                choices.map((term) => {
                    if (term.term_id === parentId) {
                        parent = term;
                    }
                });
            }

            if (parent) {
                return <p className={styles.questionDescription}>{parent.name}</p>
            }

        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <Box size={"full"}>
                    <header className={stylesQuestion.questionHeader}>
                        <h1 className={"title"}>Summary</h1>
                        <p className={stylesQuestion.questionStepDescription}>Review your answers before submitting. No
                            worries if you change your mind, you can later update them in ‘My Preferences’.</p>
                    </header>

                    <div className={stylesQuestion.questionMain}>

                        {
                            questions.map((q) => {
                                return (
                                    <Accordion defaultOpen={true} key={"q_" + q.fieldSlug} label={
                                        <span className={styles.accordionLabel}>{q.label}</span>
                                    }>
                                        <div className={styles.accordionBody}>
                                            {
                                                q.fieldSlug === "u_practices" &&
                                                    displayPracticeParent(q.type[0]["practice_tag"] || [], fields.u_practices || [])
                                            }
                                            {
                                                displayValues(fields[q.fieldSlug], q.fieldSlug, q.type[0].values)
                                            }
                                            {
                                                q.fieldSlug === "u_jurisdictions" && fields.u_additional_jurisdictions && fields.u_additional_jurisdictions.length > 0 &&
                                                <div>
                                                    <p className={styles.questionDescription}>Additional jurisdictions</p>
                                                    {
                                                        fields.u_additional_jurisdictions.map(t => {
                                                            return <Result key={"t_" + t.term_id} value={t.name}/>
                                                        })
                                                    }
                                                </div>

                                            }
                                            {
                                                q.fieldSlug === "u_practices" && fields.u_practices_description &&
                                                <p className={styles.questionDescriptionNoMarginBottom}>{fields.u_practices_description}</p>
                                            }
                                        </div>
                                    </Accordion>
                                )
                            })
                        }

                    </div>

                    <footer className={stylesQuestion.questionFooter}>
                        <div className={stylesQuestion.questionFooterStart}>
                            <Button onClick={goPrev}
                                    typeStyle={"link"}
                                    buttonStyle={"dark"}
                                    size={"auto"}
                                    iconLeft={"arrow-left"}
                                    disabled={isLoading}
                            >
                                Go back
                            </Button>
                        </div>

                        <div className={stylesQuestion.questionFooterEnd}>
                            <Button
                                type={"button"}
                                onClick={handleClickSubmit}
                                disabled={isLoading}
                                loading={isLoading}
                            >
                                Submit
                            </Button>
                        </div>

                    </footer>
                </Box>
            </div>
        </div>
    );
}

