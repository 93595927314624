import React from "react";
import styles from "./slide.module.scss";
import clsx from "clsx";

type TutorialProps = {
    slide: any,
    number: number,
};

export const Slide = ({slide, number}: TutorialProps) => {
    return (
        <div className={clsx(styles.slide, styles[`slide-${number}`])}>
            <img className={styles.image} src={slide.image} alt={slide.title} />
            <h3 className={styles.title}>{slide.title}</h3>
            <p>{slide.description}</p>
            <ul className={styles.pagination}>
                <li className={styles.paginationItem}>{}</li>
            </ul>
        </div>
    );
}
