import {
    addError,
    invitationCodeIsValid,
    removeError,
    setIsLoading,
    setVerificationByLinkedinProfile,
    setVerificationByLinkedinStatus,
    updateAccountValues,
} from "./preregistrationSlice";
import {setBaseRoutePathname} from "../base/baseSlice";

import {AppThunk} from "../store";
import {endpoints} from "../../config/endpoints";
import {routes} from "../../config/routes";
import {RequestUserRegisterInterface} from "../../interfaces/api";
import {Container} from "typedi";
import {ApiService} from "../../services/ApiService";
import {statsUserRegister} from "../../utils/stats";

export const fetchCheckInvitationCode = (code: string, onSuccess: () => void): AppThunk => async (dispatch) => {

    const api = Container.get(ApiService);

    dispatch(setIsLoading(true));

    api.get(endpoints.code.verify, {
        params: {
            invitation_code: code,
        },

    })
        .then((response) => {
            dispatch(invitationCodeIsValid(true));
            dispatch(removeError({
                name: "invitationCode",
                message: ""
            }));
            onSuccess();

        })
        .catch((error) => {
            if (error?.response?.data?.message) {
                dispatch(addError({
                    name: "invitationCode",
                    message: error.response.data.message
                }));
            }

        }).finally(() => {
        dispatch(setIsLoading(false));
    });
};
export const fetchCheckData = (data: RequestUserRegisterInterface): AppThunk => async (dispatch) => {

    const api = Container.get(ApiService);

    dispatch(setIsLoading(true));

    api.get(endpoints.public.global.check_email, {
        params: {
            email: data.email,
        }
    })
        .then((response) => {
            if (response.data.state) {
                dispatch(removeError({
                    name: "email",
                    message: ""
                }));
                dispatch(updateAccountValues(data));
                dispatch(setBaseRoutePathname(routes.join.account.verification));
            } else {
                dispatch(addError({
                    name: "email",
                    message: "You can't use this e-mail."
                }));
            }
        })
        .catch((error) => {
            console.log(error);
        }).finally(() => {
        dispatch(setIsLoading(false));
    });
};

export const fetchCreateUser = (user: RequestUserRegisterInterface, file: File | null, invitationCode: string): AppThunk => async (dispatch) => {

    const api = Container.get(ApiService);

    dispatch(setIsLoading(true));

    const formData = new FormData();
    formData.append("firstname", user.firstname);
    formData.append("lastname", user.lastname);
    formData.append("email", user.email);
    formData.append("password", user.password);
    formData.append("password2", user.password2);
    formData.append("lawFirm", user.lawFirm);
    // formData.append("termRules", JSON.stringify(user.termRules));
    // formData.append("eligibilityRules", JSON.stringify(user.eligibilityRules));
    formData.append("isNewsletter", JSON.stringify(user.isNewsletter));
    formData.append("invitationCode", JSON.stringify(invitationCode));
    formData.append("verificationLinkedinStatus", JSON.stringify(user.verification.linkedin.status));
    formData.append("verificationLinkedinDataLocalizedFirstName", JSON.stringify(user.verification.linkedin.data?.localizedFirstName));
    formData.append("verificationLinkedinDataLocalizedLastName", JSON.stringify(user.verification.linkedin.data?.localizedLastName));
    formData.append("verificationLinkedinDataProfilePicture", JSON.stringify(user.verification.linkedin.data?.profilePicture));
    formData.append("verificationFileStatus", JSON.stringify(user.verification.file.status));

    if (file) {
        formData.append("cv", file);
    }

    api.post(endpoints.user.public.create, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((response) => {
            if (response.data.state) {

                statsUserRegister();

                dispatch(setBaseRoutePathname(routes.join.thankYou));
            } else {

            }
        })
        .catch((error) => {
            console.log(error);
        }).finally(() => {
        dispatch(setIsLoading(false));
    });
};



export const fetchLinkedinProfile = (linkedinCode: string): AppThunk => async (dispatch) => {

    const api = Container.get(ApiService);

    dispatch(setIsLoading(true));

    api.post(
        endpoints.linkedin.token,
        {
            code: linkedinCode,
            redirect_uri: process.env.REACT_APP_LINKEDIN_BACK_URL,
        },
        {
            headers: {
                'Content-Type': 'application/json'
            },
        }
    )
        .then((res) => {
            if (res) {
                if (res.data.error) {
                    dispatch(setVerificationByLinkedinStatus({
                        type: "linkedin",
                        status: false
                    }))
                } else {
                    dispatch(setVerificationByLinkedinProfile({
                        localizedFirstName: res.data.localizedFirstName,
                        localizedLastName: res.data.localizedLastName,
                        profilePicture: res.data.profilePicture,
                    }));
                    dispatch(setVerificationByLinkedinStatus({
                        type: "linkedin",
                        status: true
                    }))
                }

            }
        }).catch(() => {
        dispatch(setVerificationByLinkedinStatus({
            type: "linkedin",
            status: false
        }))
    }).finally(() => {
        dispatch(setIsLoading(false));
    })
};
