import clsx from 'clsx';
import styles from './status.module.scss';
import React from "react";
import {Icon} from "../icon/Icon";


type StatusProps = {
    readonly type: "success" | "error";
    readonly message: string;
    readonly className?: string;
};

export function Status({
                           type = "success",
                           message = "",
                           className,
                           ...props
                       }: StatusProps)
{
    return (
        <div
            className={clsx(styles.status, className, {
                [styles.statusSuccess]: type === 'success',
                [styles.statusError]: type === 'error',
            })}
        >
            {
                type === "success" &&
                    <Icon className={styles.icon} icon={"success"} />
            }
            {message}
        </div>
    )
}
