import React from 'react';
import ModalResetPassword from "../modals/ModalResetPassword/ModalResetPassword";
import {useAppSelector} from "../../hooks";
import {selectModals} from "../../store/base/baseSlice";
import ModalApplication from "../modals/ModalApplication/ModalApplication";

export function ModalViewer() {

    const modals = useAppSelector(selectModals);

    return (
        <>
            {
                modals.passwordReset &&
                    <ModalResetPassword />
            }

        </>
    );
}

export default ModalViewer;
