import React, {useEffect, useState} from "react";
import {Box} from "ts/components/partials/box/Box";
import styles from "./joinVerification.module.scss";
import {Typography} from "ts/components/ui/typography/Typography";
// @ts-ignore
import {LinkedIn} from 'react-linkedin-login-oauth2';
import {VerifyCard} from "ts/components/ui/verifyCard/VerifyCard";
import linkedinIcon from "img/linkedin.svg";
import fileIcon from "img/file.svg";
import {useAppDispatch, useAppSelector, useDocumentTitle} from "../../../../hooks";
import {
    selectAccount,
    selectFileVerificationStatus, selectInvitationCode,
    selectIsLoading,
    selectLinkedinVerificationStatus,
    setVerificationByFile,
    setVerificationByLinkedinStatus,
    setVerificationLinkedinCode
} from "../../../../store/preregistration/preregistrationSlice";
import {Button} from "../../../ui/button/Button";
import {fetchCreateUser, fetchLinkedinProfile} from "../../../../store/preregistration/preregistrationAPI";
import {closeTabAlert} from "../../../../utils/closeTabAlert";
import FileSelector from "../../../partials/fileSelector/FileSelector";
import {TemplatePre} from "../../../templates/templatePre/TemplatePre";


const JoinVerification: React.FC = () => {

    useDocumentTitle("Join - Counselpath");

    const dispatch = useAppDispatch();
    const verifiedByLinkedin = useAppSelector(selectLinkedinVerificationStatus);
    const verifiedByFile = useAppSelector(selectFileVerificationStatus);
    const isLoading = useAppSelector(selectIsLoading);
    const account = useAppSelector(selectAccount);
    const invitationCode = useAppSelector(selectInvitationCode);
    const [file, setFile] = useState<File | null>(null);
    const DEFAULT_MAX_FILE_SIZE_IN_BYTES = parseInt(process.env.REACT_APP_DEFAULT_MAX_FILE_SIZE_IN_BYTES || "2097152");


    const [isVerified, setIsVerified] = useState(false);

    function handleSuccessLinkedin(data: {
        code: string
    }): any {
        if (data.code) {
            dispatch(setVerificationLinkedinCode(
                {
                    code: data.code,
                    type: "linkedin"
                }
            ));

            if (data.code) {
                dispatch(fetchLinkedinProfile(data.code))
            }
        }
    }

    function handleFailureLinkedin(error: any): any {
        dispatch(setVerificationByLinkedinStatus({
            type: "linkedin",
            status: false,
        }));
    }

    function handleErrorFile() {
        dispatch(setVerificationByFile({
            type: "file",
            status: false,
        }));
    }

    function handleAddFile(file: File | null) {
        setFile(file);
        dispatch(setVerificationByFile({
            type: "file",
            status: !!file,
        }));
    }

    function handleSubmit() {
        if (isVerified && !isLoading) {
            dispatch(fetchCreateUser(account, file, invitationCode));
        }
    }

    useEffect(() => {
        setIsVerified(
            verifiedByLinkedin || verifiedByFile
        );
    }, [verifiedByLinkedin, verifiedByFile]);

    useEffect(() => {
        closeTabAlert();
        // console.log(process.env.REACT_APP_LINKEDIN_CLIENT_ID);
    }, []);

    return (
        <TemplatePre>
            <Box>
                <Typography as={"h1"}>Verify your profile</Typography>
                <Typography as={"p"} margin={3}>Counselpath is a careers platform for solicitors. We review all new applications to ensure each profile fits our community. Please choose a verification method. We will confirm your profile, typically within 1-2 working days.</Typography>

                <LinkedIn
                    clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                    onFailure={handleFailureLinkedin}
                    scope="r_liteprofile"
                    onSuccess={handleSuccessLinkedin}
                    redirectUri={process.env.REACT_APP_LINKEDIN_BACK_URL}
                >
                    <VerifyCard
                        disabled={isLoading}
                        logoUrl={linkedinIcon}
                        title={"Verify your profile via LinkedIn"}
                        description={'Used for verification only. Will not result in any updates to your LinkedIn profile.'}
                        successMessage={verifiedByLinkedin ? "LinkedIn profile verified" : null}
                    />
                </LinkedIn>

                <FileSelector
                    fileExtensionsAccept={"application/pdf"}
                    fileExtensions={["pdf"]}
                    maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
                    onError={handleErrorFile}
                    onAddFile={handleAddFile}
                    isLoading={isLoading}
                >

                    <VerifyCard
                        disabled={isLoading}
                        logoUrl={fileIcon}
                        title={"Verify your profile via CV upload"}
                        description={`Used for verification only. Please upload your CV in PDF format. Will not be visible to employers or other members.`}
                        successMessage={verifiedByFile ? "CV attached" : null}
                    />
                </FileSelector>

                <div className={styles.footerInside}>
                    <Button disabled={isLoading || !isVerified} loading={isLoading} onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>

            </Box>
        </TemplatePre>
    );
};
export default JoinVerification;
