export function closeTabAlert () {
    const isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? "pagehide" : "beforeunload";

    window.addEventListener(eventName, function (e) {

        // For IE and Firefox prior to version 4
        if (e) {
            e.returnValue = 'Leaving this page will reset the form';
        }

        e.preventDefault();
        e.returnValue = '';

        // For Safari
        return 'Leaving this page will reset the form';
    });
}
