import React from "react";
import {useAppSelector} from "../../../hooks";
import {selectUser} from "../../../store/user/userSlice";
import {Navigate} from "react-router-dom";
import {routes} from "../../../config/routes";

const Applications: React.FC = () => {

    const user = useAppSelector(selectUser);

    if (user) {
        return (
            <div>
                Dashboard applications
            </div>
        );
    }
    return <Navigate to={routes.login.base} replace={true}/>

}

export default Applications;
