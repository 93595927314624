import clsx from 'clsx';
import styles from './icon.module.scss';
import React, {forwardRef, memo} from "react";
import {ReactComponent as Linkedin} from 'img/icons/linkedin.svg';
import {ReactComponent as Info} from 'img/icons/info.svg';
import {ReactComponent as Plus} from 'img/icons/plus.svg';
import {ReactComponent as Glass} from 'img/icons/glass.svg';
import {ReactComponent as Eye} from 'img/icons/eye.svg';
import {ReactComponent as Success} from 'img/icons/success.svg';
import {ReactComponent as Delete} from 'img/icons/delete.svg';
import {ReactComponent as Exit} from 'img/icons/exit.svg';
import {ReactComponent as ArrowLeft} from 'img/icons/arrow-left.svg';
import {ReactComponent as ArrowRight} from 'img/icons/arrow-right.svg';
import {ReactComponent as Close} from 'img/icons/close.svg';
import {ReactComponent as Check} from 'img/icons/check.svg';
import {ReactComponent as Calendar} from 'img/icons/calendar.svg';
import {ReactComponent as List} from 'img/icons/list.svg';
import {ReactComponent as Plane} from 'img/icons/plane.svg';
import {ReactComponent as Envelope} from 'img/icons/envelope.svg';

type IconProps = {
    readonly icon: string;
    readonly size?: 'small';
    readonly className?: string;
};


export const Icon = memo<IconProps>(
    forwardRef(
        (
            {
                icon,
                size = "small",
                className,
                ...props
            },
            ref,
        ) => {
            return (
                <div
                    className={clsx(styles.icon, className, {
                        [styles.iconSmall]: size === 'small',
                    })}
                    // @ts-ignore
                    ref={ref}
                    {...props}
                >
                    {icon === "linkedin" &&
                    <Linkedin/>
                    }
                    {icon === "info" &&
                    <Info/>
                    }
                    {icon === "plus" &&
                    <Plus/>
                    }
                    {icon === "glass" &&
                    <Glass/>
                    }
                    {icon === "eye" &&
                    <Eye/>
                    }
                    {icon === "success" &&
                    <Success/>
                    }
                    {icon === "delete" &&
                    <Delete/>
                    }
                    {icon === "exit" &&
                    <Exit/>
                    }
                    {icon === "arrow-left" &&
                    <ArrowLeft/>
                    }
                    {icon === "arrow-right" &&
                    <ArrowRight/>
                    }
                    {icon === "close" &&
                    <Close/>
                    }
                    {icon === "check" &&
                    <Check/>
                    }
                    {icon === "calendar" &&
                    <Calendar/>
                    }
                    {icon === "list" &&
                    <List/>
                    }
                    {icon === "plane" &&
                    <Plane/>
                    }
                    {icon === "envelope" &&
                    <Envelope/>
                    }
                </div>
            );
        },
    ),
);
