import React, {useEffect, useState} from "react";
import {RadioBars} from "../../../../ui/radioBars/RadioBars";
import {QuestionInterface} from "ts/interfaces";
import {useAppDispatch, useAppSelector} from "ts/hooks";
import {selectUserFieldStatus, setUserFieldStatus} from "ts/store/user/userSlice";

type QuestionOneProps = {
    question: QuestionInterface,
    onValid: (isValid: boolean) => void,
};

export const QuestionOne = ({
                                onValid,
                                question,
                         }: QuestionOneProps) => {

    const [choices, setChoices] = useState<Array<{
        label: string,
        value: string,
    }>>([]);

    const value = useAppSelector(selectUserFieldStatus);

    const dispatch = useAppDispatch();

    useEffect(() => {
        onValid(!!value);
    }, [value]);

    function handleChange (e: React.FormEvent<HTMLInputElement>) {
        dispatch(
            setUserFieldStatus(
                e.currentTarget.value
            )
        );
    }

    useEffect(() => {
        setChoices(question.type[0].values || []);
    }, [question]);

    return (
        <div>
            <RadioBars
                defaultValue={value}
                selected={value}
                id={question.fieldSlug}
                name={question.fieldSlug}
                choices={choices}
                onChange={handleChange}
            />
        </div>
    );
}
