import clsx from 'clsx';
import styles from './process.module.scss';
import React from "react";
import {limitCharacters} from "../../../utils/limitCharacters";


type LoaderProps = {
    readonly steps: Array<{
        step: string,
        description: string,
    }>;
};

export function Process({
    steps,

                         ...props
                     }: LoaderProps) {
    return (
        <ul
            className={clsx(styles.steps, {

            })}
        >
            {
                steps.map((step, index) => {
                    if (step.step.length > 0) {
                        return (
                            <li className={styles.stepsItem} key={"process_" + index}>
                                <div className={styles.stepsItemDot}/>
                                <div className={styles.stepsItemContent}>
                                    <div className={styles.stepsItemLabel}>{step.step}</div>
                                    <div className={styles.stepsItemMore}>{
                                        limitCharacters(step.description, 90)
                                    }</div>
                                </div>
                            </li>
                        )
                    }
                })
            }
        </ul>
    )
}
