export interface BaseState {
    route: {
        pathname: string | null,
        redirectTo: string | null,
        redirectDone: boolean,
    },
    isLoading: boolean,
    modals: {
        [index: string]: boolean
    }
}

export const initialState: BaseState = {
    route: {
        pathname: "/",
        redirectTo: null,
        redirectDone: true,
    },
    isLoading: false,
    modals: {}
};
