import React from 'react';
import 'reflect-metadata';
import ReactDOM from 'react-dom';
import Boot from "./ts/components/boot/Boot";
import style from "./ts/components/boot/boot.module.scss";
import {Provider} from "react-redux";
import {store} from "./ts/store/store";


ReactDOM.render(
    <React.StrictMode>
        <div className={style.boot}>
            <Provider store={store}>
                <Boot/>
            </Provider>
        </div>
    </React.StrictMode>,
    document.getElementById('root')
);
