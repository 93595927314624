import styles from './tooltip.module.scss';
import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";

type IconProps = {
    readonly children?: React.ReactNode;
    readonly content?: React.ReactNode;
    readonly tooltipClassName?: string;
    readonly align?: "center" | "left" | "right";
    [x:string]: any;
};

export function Tooltip({
                            children,
                            content,
                            tooltipClassName,
                            align = "right",
                         ...props
                     }: IconProps) {
    const [top, setTop] = useState(0)
    const elementRef = useRef<HTMLDivElement>(null)

    function handleOver () {
        if (elementRef.current) {
            const { top } =elementRef.current.getBoundingClientRect();;
            setTop(top + 32);
        }

    }

    useEffect(() => {
        window.addEventListener('scroll', handleOver, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleOver);
        };
    }, []);

    return (
        <div
            className={styles.wrapper}
            onMouseEnter={handleOver}
            onTouchStart={handleOver}
            {...props}
        >
            <div className={clsx(styles.tooltip, tooltipClassName)} style={{top: top}}>
                <div style={{textAlign: align}}>{content}</div>
            </div>

            {/*@ts-ignore*/}
            <div ref={elementRef} className={styles.element}>
                {children}
            </div>
        </div>
    )
}
