import React, {useState} from 'react';
import styles from "./dropdown.module.scss";
import {Input} from "../input/Input";
import {useDetectClickOutside} from 'react-detect-click-outside';
import {Scrollbars} from 'rc-scrollbars';
import slugify from "slugify";
import {Button} from "../button/Button";


type DropdownProps = {
    readonly closeToggle: (e: any) => void;
    readonly onSelect: (value: string) => void;
    readonly addSuggestion: (value: string) => void;
    readonly name: string;
    readonly dropDownLabel?: string;
    readonly suggestions: Array<string>

};


export function Dropdown({
                             closeToggle,
                             suggestions,
                             onSelect,
                             dropDownLabel,
                             addSuggestion,
                             name
                         }: DropdownProps) {
    const ref = useDetectClickOutside({
        onTriggered: closeToggle
    });
    const [searchValue, setSearchValue] = useState<string>("");
    const filteredSuggestions = suggestions.filter(
        suggestion =>
            suggestion.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    );

    function handleChangeSearchValue(e: React.FormEvent<HTMLInputElement>) {
        setSearchValue(e.currentTarget.value);
    }

    function handleAddSuggestion() {
        if (searchValue.replace(/\s/g, '').length > 0) {
            addSuggestion(searchValue);
            onSelect(searchValue);
        }
    }

    return (

        <div
            className={styles.dropdown}
            ref={ref}
        >
            <div className={styles.inside}>
                <Input onChange={handleChangeSearchValue}
                       value={searchValue}
                       id={`${name}_dropdown`}
                       name={`${name}_dropdown`}
                       label={dropDownLabel ?? "Search / Add new Option"}
                       iconPrefix={"glass"}
                />

                <Scrollbars style={{width: "100%", height: 152}}>
                    <ul className={styles.list}>
                        {
                            filteredSuggestions.map(suggestion =>
                                <li key={slugify(suggestion)} className={styles.item}>
                                    <button onClick={(e) => onSelect(suggestion)} className={styles.button}
                                            value={suggestion}>{suggestion}</button>
                                </li>
                            )
                        }
                        {
                            filteredSuggestions.length === 0 &&
                            <span className={styles.dropdownEmptyMessage}>No results...</span>
                        }
                    </ul>
                </Scrollbars>
            </div>

            <div className={styles.footer}>
                <Button iconLeft={"plus"} disabled={searchValue.length < 1} typeStyle={"link"} onClick={handleAddSuggestion} size={"auto"}>Add</Button>
            </div>
        </div>
    )
}
