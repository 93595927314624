import React, {useEffect, useState} from 'react';
import {Modal} from "../../ui/modal/Modal";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectModals} from "../../../store/base/baseSlice";
import {Typography} from "../../ui/typography/Typography";
import {Button} from "../../ui/button/Button";
import {Controller, useForm} from "react-hook-form";
import {Status} from "../../ui/status/Status";
import {RequestVacancyApplication} from "../../../interfaces/api";
import styles from "./modalAplication.module.scss";
import {VacancyExtendedInterface} from "../../../interfaces/VacancyExtendedInterface";
import {CardRow} from "../../partials/cardRow/CardRow";
import {Switch} from "../../ui/switch/Switch";
import ReactSlider from "react-slider";
import clsx from "clsx";
import {decode} from "html-entities";
import {Dropzone} from "../../ui/dropzone/Dropzone";
import {sendVacancyApplication} from "../../../store/vacancies/vacanciesApi";
import {statsVacancyApplication, statsVacancyView} from "../../../utils/stats";

interface ModalApplicationProps {
    vacancy: VacancyExtendedInterface,
    updateVacancy: () => void,
}

export function ModalApplication({
                                     vacancy,
                                     updateVacancy
                                 }: ModalApplicationProps) {

    const modals = useAppSelector(selectModals);
    const [success, setSuccess] = useState(false);
    const [errorLocally, setErrorLocally] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [noticePeriod, setNoticePeriod] = useState<number | undefined>(undefined);

    const { register, handleSubmit, control, trigger, setError, setValue, formState: { errors, isValid }, getValues } = useForm({
        mode: "onSubmit"
    });

    const dispatch = useAppDispatch();

    function onSuccess () {
        updateVacancy();
        setErrorLocally(false);
        setSuccess(true);
        setIsLoading(false);
        setDisabled(true);

        statsVacancyApplication(vacancy.title, vacancy.id);
    }

    function onError () {
        setSuccess(false);
        setErrorLocally(true);
        setIsLoading(false);
        setDisabled(false);
    }

    const onSubmit = (data: RequestVacancyApplication) => {
        setIsLoading(true);
        dispatch(
            sendVacancyApplication(
                {
                    vacancyId: vacancy.id,
                    noticePeriod: data.noticePeriod,
                    cv: data.cv,
                    score: vacancy.score.value,
                    matched: vacancy.score.matched,
                },
                onSuccess,
                onError,
            )
        );
    };

    function handleChangeSlider (value: number) {
        setValue("noticePeriod", value, {
            shouldValidate: true
        });
        setNoticePeriod(value);
    }


    return (
        <Modal
            isOpen={modals.application ?? false}
            modalName={"application"}
            title={"You are applying for"}
            showCancel={true}
            showClose={true}
            cta={
                <Button
                    notValid={!isValid}
                    disabled={isLoading || disabled}
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    iconLeft={"envelope"}
                    size={"sm"}
                >
                    Send Application
                </Button>
            }
        >

            <CardRow
                logoUrl={vacancy.company.logoUrl}
                name={`${vacancy.title} (${decode(vacancy.pqe)})`}
                subname={`${vacancy.company.name}, ${vacancy.team.name}`}
                className={styles.cardRow}
            />

            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                {
                    vacancy.questions.length > 0 &&
                    <Typography as={"p"} margin={3}>Please confirm meeting specific employer requirements for this role:</Typography>
                }
                {
                    vacancy.questions &&
                    vacancy.questions.map((question, index) => {
                        if (question) {
                            const name = `vacancy_${vacancy.id}.q${index}` as any;
                            if (question.question.length > 0) {
                                return (
                                    <div key={name} className={styles.questionRow}>
                                        <div className={styles.question}>
                                            <p  className={styles.questionLabel}>{question.question}</p>
                                            <div  className={styles.questionSwitch}>
                                                <Controller
                                                    control={control}
                                                    name={name}
                                                    rules={{
                                                        required: "Required",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Switch id={name} name={name} onChange={(e) => {
                                                            onChange(e);
                                                            trigger(name);
                                                        }} checked={value} />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        {
                                            errors[`vacancy_${vacancy.id}`] &&
                                            errors[`vacancy_${vacancy.id}`][`q${index}`] &&
                                            <Status type={"error"} message={errors[`vacancy_${vacancy.id}`][`q${index}`]?.message} />
                                        }
                                    </div>
                                )
                            }
                        }

                    })
                }

                <div className={styles.sliderWrapper}>
                    <Typography as={"p"} margin={2} className={styles.questionLabel}>How many months is your notice period?</Typography>

                    <ReactSlider
                        min={0}
                        max={7}
                        step={1}
                        onChange={handleChangeSlider}
                        marks={1}
                        defaultValue={0}
                        className={clsx(styles.slider, {
                            [styles.sliderEmpty]: noticePeriod === undefined
                        })}
                        thumbClassName={clsx(styles.sliderThumb, {
                        })}
                        // pearling={true}
                        markClassName={styles.sliderMark}
                        trackClassName={styles.sliderTrack}
                    />
                    <div className={styles.sliderLegend}>
                        <span>{"0"}</span>
                        <span>{"1"}</span>
                        <span>{"2"}</span>
                        <span>{"3"}</span>
                        <span>{"4"}</span>
                        <span>{"5"}</span>
                        <span>{"6"}</span>
                        <span>{"6+"}</span>
                    </div>
                    <input defaultValue={undefined} {...register("noticePeriod", { required: "Required" })} type={"hidden"} />
                    {
                        errors?.noticePeriod &&
                        <Status type={"error"} message={errors.noticePeriod.message} />

                    }
                    <input defaultValue={vacancy.id} {...register("vacancyId", { required: true })} type={"hidden"} />
                </div>

                <Controller
                    control={control}
                    name={"cv"}
                    rules={{
                        required: "Required",
                        validate: {
                            lessThan2MB: files => files[0]?.size < parseInt(process.env.REACT_APP_DEFAULT_MAX_FILE_SIZE_IN_BYTES || "2097152") || 'File is too large. Max file size is 2MB.',
                        }
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Dropzone
                            dropArea={true}
                            dropAreaTitle={"Upload your CV "}
                            dropAreaSubTitle={"PDF only. Maximum size 2 MB."}
                            name={"cv"}
                            onChange={(file) => setValue("cv", file,{ shouldValidate: true })}
                            fileExtensionsAccept={"application/pdf"}
                            maxFiles={1}
                        />
                    )}
                />

                {
                    errors?.cv &&
                    <Status type={"error"} message={errors.cv.message} />

                }

                {
                    success &&
                    <Status type={"success"} message={"Congratulations! Your application has been submitted."} />
                }
                {
                    errorLocally &&
                    <Status type={"error"} message={"Application not submitted! Please try again."} />
                }
            </form>

        </Modal>
    );

}

export default ModalApplication;
