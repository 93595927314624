import React, {forwardRef, memo} from 'react';
import styles from "./salarySlider.module.scss";
import clsx from 'clsx';
import ReactSlider from 'react-slider';

type InputProps = {
    readonly id: string;
    readonly name: string;
    readonly label?: string;
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly isRequired?: boolean;
    readonly onChange?: (value: number) => void;
    readonly className?: string;
    readonly defaultValue?: number;
    readonly iconPrefix?: string | null;
};

export const SalarySlider = memo<InputProps>(
    forwardRef(
        (
            {
                disabled = false,
                isRequired = false,
                iconPrefix = null,
                label,
                defaultValue,
                onChange,
                className,
                id,
                ...props
            },
            ref,
        ) => {

            function handleChange (value: number) {
                if (onChange) {
                    onChange(value);
                }

            }

            function handleClickCloud (value: number) {
                // console.log(value);
                if (onChange) {
                    onChange(value);
                }
            }

            return (
                <div>
                    <div
                        className={clsx(styles.wrapper)}
                    >
                        <ReactSlider
                            min={60}
                            max={250}
                            step={10}
                            onChange={handleChange}
                            marks={10}
                            disabled={disabled}
                            defaultValue={defaultValue}
                            className={clsx(styles.slider, {
                                [styles.sliderDisabled]: disabled
                            })}
                            thumbClassName={clsx(styles.sliderThumb, {
                                [styles.sliderThumbDisabled]: disabled
                            })}
                            markClassName={styles.sliderMark}
                            trackClassName={styles.sliderTrack}
                            renderThumb={(props, state) =>
                                <div {...props}
                                     className={clsx(styles.sliderCloud, {
                                         [styles.sliderCloudDisabled]: disabled
                                     })}
                                     onClick={() => handleClickCloud(state.valueNow)}
                                >
                                    <div className={styles.sliderCloudInner}>£{state.valueNow}K{(state.valueNow === 250) ? "+" : ""}</div>
                                </div>
                            }
                        />

                    </div>
                    <div className={styles.footer}>
                        <div>£60K</div>
                        <div>£250K+</div>
                    </div>
                </div>
            );
        })
);
