import React, {SyntheticEvent, forwardRef, memo} from 'react';
import styles from "./textarea.module.scss";
import clsx from 'clsx';
import {Status} from "../status/Status";

type AutocompleteProps = {
    readonly id: string;
    readonly name: string;
    readonly ariaLabel?: string;
    readonly ariaDescribedby?: string;
    readonly placeholder?: string;
    readonly value?: string;
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly hasError?: boolean;
    readonly errorMessage?: string;
    readonly isRequired?: boolean;
    readonly maxLength?: number;
    readonly onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    readonly onBlur?: (e: SyntheticEvent<HTMLTextAreaElement>) => void;
    readonly className?: string;
    readonly defaultValue?: string;
};

export const Textarea = memo<AutocompleteProps>(
    forwardRef(
        (
            {
                disabled = false,
                isRequired = false,
                hasError,
                onBlur,
                defaultValue,
                onChange,
                className,
                placeholder = "",
                id,
                errorMessage,
                ...props
            },
            ref,
        ) => {


            function handleInputBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
                if (onBlur) {
                    onBlur(e);
                }
            }

            function handleOnChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
                if (onChange) {
                    onChange(e);
                }
            }



            return (
                <div
                    className={clsx(styles.wrapper, {

                    })}
                >
                    <div
                        className={clsx(styles.field, {
                            // [styles.fieldError]: hasError,
                        })}
                    >
                        <textarea
                            id={id}
                            className={clsx(styles.textarea, className, {
                                // [styles.textareaDisabled]: disabled,
                                // [styles.textareaError]: hasError,
                            })}
                            onBlur={(e) => handleInputBlur(e)}
                            onChange={(e) => handleOnChange(e)}
                            disabled={disabled}
                            placeholder={placeholder}
                            //@ts-ignore
                            ref={ref}
                            defaultValue={defaultValue}
                            {...props}
                        />
                    </div>
                    {
                        hasError && errorMessage &&
                            <Status type={"error"} message={errorMessage} />
                    }
                </div>

            );
        })
);
