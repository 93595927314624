import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector, useDocumentTitle} from "../../../hooks";
import {useNavigate} from "react-router-dom";
import {getVacancy} from "../../../store/vacancies/vacanciesApi";
import {Col, Row} from 'react-grid-system';
import {useParams} from "react-router";
import {Button} from "../../ui/button/Button";
import styles from "./vacancyPage.module.scss";
import {CompanyCard} from "../../partials/companyCard/CompanyCard";
import {VacancyExtendedInterface} from "../../../interfaces/VacancyExtendedInterface";
import {VacancyHeader} from "../../partials/vacancyHeader/VacancyHeader";
import clsx from "clsx";
import {Typography} from "../../ui/typography/Typography";
import {selectVacanciesIsLoadingSingle} from "../../../store/vacancies/vacanciesSlice";
import Skeleton from "react-loading-skeleton";
import {Icon} from "../../ui/icon/Icon";
import {Process} from "../../ui/process/Process";
import {decode} from "html-entities";
import {postUserVisitorCompany} from "../../../store/user/userApi";
import {selectModals, setModal} from "../../../store/base/baseSlice";
import ModalApplication from "../../modals/ModalApplication/ModalApplication";
import ModalQuestion from "../../modals/ModalQuestion/ModalQuestion";
import {VacancyApplicationStatus} from "../../partials/vacancyApplicationStatus/VacancyApplicationStatus";
import {TermStateInterface} from "../../../interfaces/TermStateInterface";
import {statsVacancyView} from "../../../utils/stats";
import {VACANCY_TYPE} from "../../../typings/enums";
import {VacancyApplicationExternal} from "../../partials/vacancyApplicationExternal/VacancyApplicationExternal";

type VacancyPageProps = {};

export function VacancyPage({}: VacancyPageProps) {

    const isLoadingSingle = useAppSelector(selectVacanciesIsLoadingSingle);
    const dispatch = useAppDispatch();
    const [vacancyExtended, setVacancyExtended] = useState<VacancyExtendedInterface | null>(null);
    const {id} = useParams();
    let navigate = useNavigate();
    const modals = useAppSelector(selectModals);

    useDocumentTitle(vacancyExtended ? `Offer - ${vacancyExtended.title} (${vacancyExtended.id}) - Counselpath` : "Offer - Counselpath");

    useEffect(() => {
        // closeTabAlert();
    }, []);

    useEffect(() => {
        getLocalVacancy();
    }, []);

    useEffect(() => {
        if (vacancyExtended) {
            statsVacancyView(vacancyExtended.title, vacancyExtended.id);
        }
    }, [vacancyExtended])

    function getLocalVacancy () {
        dispatch(getVacancy(id, setVacancyExtended));
    }

    function handleBackButton() {
        navigate(-1);
    }

    function createMarkup() {
        return {__html: vacancyExtended?.description || ""};
    }

    function handleClickCompanyUrl (link: string) {
        if (vacancyExtended) {
            dispatch(
                postUserVisitorCompany({
                    vacancy_id: vacancyExtended.id,
                    company_id: vacancyExtended?.company.id,
                })
            );
            window.open(link, "_blank");
        }

    }

    return (
        <div>
            <Row>
                <Col sm={12}>
                    <div className={styles.top}>
                        <Button onClick={handleBackButton} as={"button"} buttonStyle={"normal"} typeStyle={"link"} size={"auto"}
                                iconLeft={"arrow-left"}>
                            Back to all opportunities
                        </Button>
                    </div>

                </Col>
            </Row>
            {
                isLoadingSingle &&
                (
                    <Row>
                        <Col sm={3}>
                            <Skeleton height={363}/>
                        </Col>
                        <Col sm={6}>
                            <Skeleton height={500}/>
                        </Col>
                        <Col sm={3}>
                            <Skeleton height={363}/>
                        </Col>
                    </Row>
                )
            }
            {
                vacancyExtended && !isLoadingSingle &&  (
                    <Row>
                        <Col sm={3}>
                            <CompanyCard
                                name={vacancyExtended.company.name}
                                logoUrl={vacancyExtended.company.logoUrl}
                                website={vacancyExtended.company.website}
                                onClickCompanyUrl={handleClickCompanyUrl}
                                items={[
                                    {
                                        label: "Segment",
                                        value: (
                                            vacancyExtended.company.segments.map((
                                                segment, i) =>
                                                <div>{segment.name + (vacancyExtended.company.segments.length > i+1 ? `,` : "")}</div>
                                        ))
                                    },
                                    {
                                        label: "Headquarters",
                                        value: vacancyExtended.company.officeLocation
                                    },
                                    {
                                        label: "Employees",
                                        value: vacancyExtended.company.employees
                                    },
                                    {
                                        label: "No. of offices",
                                        value: vacancyExtended.company.noOfOffices
                                    }
                                ]}
                            />
                        </Col>
                        <Col sm={6}>
                            <div className={styles.box}>
                                <VacancyHeader logo={false} vacancy={vacancyExtended}/>

                                <div className={clsx(styles.boxDesc)}>
                                    <Typography as={"p"} margin={0}>{vacancyExtended.shortDescription}</Typography>
                                </div>

                                <div className={clsx(styles.boxMain)}>
                                    <div className={styles.bricks}>

                                        <div className={clsx(styles.brick, styles.brickWide)}>
                                            <div className={styles.brickLabel}>Team</div>
                                            <div className={styles.brickValue}>{vacancyExtended.team.name}</div>
                                        </div>

                                        {
                                            vacancyExtended.locations.length > 0 &&
                                            <div className={clsx(styles.brick)}>
                                                <div className={styles.brickLabel}>Location</div>
                                                <div
                                                    className={styles.brickValue}>{
                                                    vacancyExtended.locations.map((l,i) => <span key={"model_" + i}>{(i > 0 ? ", " : "") + l.name}</span>)
                                                }</div>
                                            </div>
                                        }

                                        {
                                            vacancyExtended.models[0] &&
                                            <div className={clsx(styles.brick)}>
                                                <div className={styles.brickLabel}>Working model</div>
                                                <div
                                                    className={styles.brickValue}>{
                                                    vacancyExtended.models.map((model, i) => <span key={"model_" + i}>{(i > 0 ? ", " : "") + model.name}</span>)
                                                }</div>
                                            </div>
                                        }

                                        {
                                            vacancyExtended.team.noOfPartners &&
                                            <div className={styles.brick}>
                                                <div className={styles.brickLabel}># of partners</div>
                                                <div
                                                    className={styles.brickValue}>{vacancyExtended.team.noOfPartners}</div>
                                            </div>
                                        }

                                        {
                                            vacancyExtended.team.teamSize &&
                                            <div className={styles.brick}>
                                                <div className={styles.brickLabel}>Team size</div>
                                                <div className={styles.brickValue}>{vacancyExtended.team.teamSize}</div>
                                            </div>
                                        }


                                        {
                                            vacancyExtended.team.legal500Rank &&
                                            <div className={styles.brick}>
                                                <div className={styles.brickLabel}>Legal 500 Rank</div>
                                                <div
                                                    className={styles.brickValue}>{vacancyExtended.team.legal500Rank}</div>
                                            </div>
                                        }


                                        {
                                            vacancyExtended.team.additionalFields &&
                                            Array.isArray(vacancyExtended.team.additionalFields) &&
                                            vacancyExtended.team.additionalFields.map((field, i) => {
                                                if (field.label && field.content) {
                                                    return (
                                                        <div key={"field_"+i} className={styles.brick}>
                                                            <div className={styles.brickLabel}>{field.label}</div>
                                                            <div className={styles.brickValue}>{field.content}</div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                    </div>

                                    <div className={styles.tags}>
                                        <div className={styles.tagsLabel}>Practices covered</div>
                                        {
                                            vacancyExtended.team.practices.map((term: TermStateInterface) => (
                                                <div key={"term_" + term.term_id} className={styles.tagsPill}>{decode(term.name)}</div>
                                            ))
                                        }
                                    </div>

                                    <div className={styles.tags}>
                                        <div className={styles.tagsLabel}>Key sectors covered</div>
                                        {
                                            vacancyExtended.team.sectors.map((term) => (
                                                <div key={"term_" + term.term_id} className={styles.tagsPill}>{decode(term.name)}</div>
                                            ))
                                        }
                                    </div>

                                    <div className={styles.tags}>
                                        <div className={styles.tagsLabel}>Perks & Benefits</div>
                                        {
                                            vacancyExtended.team.perks.map((term) => (
                                                <div key={"term_" + term.term_id} className={styles.tagsPill}>{decode(term.name)}</div>
                                            ))
                                        }
                                    </div>

                                </div>

                                <div className={clsx(styles.boxSubMain)}>
                                    <Typography as={"h6"} margin={2} className={styles.darkSmall}>Role
                                        description</Typography>
                                    <p dangerouslySetInnerHTML={createMarkup()}/>
                                </div>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className={clsx(styles.box, styles.aside)}>
                                <div className={styles.asideHeader}>
                                    <Icon className={styles.asideHeaderIcon} icon={"list"}/>
                                    Interviewing Process
                                </div>
                                <div className={styles.asideMain}>
                                    {
                                        vacancyExtended.process &&
                                        <Process steps={vacancyExtended.process}/>
                                    }

                                </div>
                                <div className={styles.asideFooter}>

                                    {
                                        vacancyExtended.type === VACANCY_TYPE.internal &&
                                        <>
                                            <VacancyApplicationStatus
                                                vacancyExtended={vacancyExtended}
                                                updateVacancy={getLocalVacancy}
                                            />
                                            <Button
                                                buttonStyle={"ghost"}
                                                full={true}
                                                size={"sm"}
                                                className={styles.buttonSecond}
                                                onClick={() => dispatch(setModal({
                                                    modal: "question",
                                                    state: true
                                                }))}
                                            >
                                                Have questions?
                                            </Button>
                                        </>

                                    }
                                    {
                                        vacancyExtended.type === VACANCY_TYPE.external &&
                                            <VacancyApplicationExternal vacancyExtended={vacancyExtended} />
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            }
            {
                vacancyExtended &&
                modals.application &&
                !vacancyExtended.application?.withdrawn &&
                vacancyExtended.type === VACANCY_TYPE.internal &&
                    <ModalApplication vacancy={vacancyExtended} updateVacancy={getLocalVacancy} />
            }

            {
                vacancyExtended &&
                modals.question &&
                vacancyExtended.type === VACANCY_TYPE.internal &&
                    <ModalQuestion vacancy={vacancyExtended} />
            }

        </div>
    );
}

export default VacancyPage;
