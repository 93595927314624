import clsx from 'clsx';
import styles from './loader.module.scss';
import React from "react";


type LoaderProps = {
    className?: string;
    size?: "small" | "medium";
};

export function Loader({
className,
    size = "small",
                         ...props
                     }: LoaderProps) {
    return (
        <div
            className={clsx(styles.loader, className, {
                [styles.loaderSmall]: size === 'small',
                [styles.loaderMedium]: size === 'medium',
            })}
        >
        </div>
    )
}
