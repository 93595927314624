import React from "react";
import {TemplatePre} from "../../../templates/templatePre/TemplatePre";
import thankyouImage from "img/illustrations/thankyou.svg";
import {InfoBox} from "../../../partials/infoBox/InfoBox";
import {useDocumentTitle} from "../../../../hooks/useDocumentTitle";

const JoinThankyou: React.FC = () => {

    useDocumentTitle("Thank You - Counselpath");

    return (
        <TemplatePre>
            <InfoBox
                image={thankyouImage}
                title={"Please verify your email."}
                description={"We have sent you a notification to verify your email address. Please click on the link inside to complete registration. Once this is done, your account will be reviewed by a member of Counselpath team."}
            />
        </TemplatePre>
    );
};

export default JoinThankyou;
