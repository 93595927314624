import type {ReactNode} from 'react';
import React from "react";
import styles from "./darkBox.module.scss";
import clsx from "clsx";

type TemplateDarkBoxProps = {
    readonly children: ReactNode;
    readonly title?: string;
    readonly className?: string;
    readonly description?: string;
};

export const DarkBox = ({children, title, description, className}: TemplateDarkBoxProps) => {
    return (
        <div className={clsx(styles.area, className, {})}>
            {
                title &&
                <h3 className={"h6"}>{title}</h3>
            }

            {
                description &&
                <p>{description}</p>
            }

            {children}
        </div>

    );
}
