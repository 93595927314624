import type {ReactNode} from 'react';
import React from "react";
import illustration from "img/illustrations/streamline-icon-business-deal-3.svg"
import illustrationMobile from "img/illustrations/streamline-icon-business-deal.svg"
import styles from "./templateAside.module.scss";
import {Brand} from "../../partials/brand/Brand";

type TemplateAsideProps = {
    readonly children: ReactNode;
};

export const TemplateAside = ({children}: TemplateAsideProps) => {
    return (
        <div className={styles.wrapper}>
            <div className={"container"}>
                <aside className={styles.aside}>
                    <div className={styles.brand}>
                        <Brand theme={"dark"} size={"medium"}/>
                    </div>

                    <div className={styles.mobileHero}>
                        <img alt={"Shake hand illustration"} src={illustration}
                             className={styles.illustrationMobile}/>
                    </div>

                    <div>
                        {children}
                    </div>
                </aside>
                <div className={styles.background}>
                    <img alt={"Shake hand illustration"} src={illustration} className={styles.illustration}/>
                </div>
            </div>
        </div>
    );
}
