import {AppThunk} from "ts/store/store";
import {endpoints} from "ts/config/endpoints";
import {routes} from "ts/config/routes";
import {removeUser, setUserFromApi, setUserQuestionsDone} from "./userSlice";
import {setBaseRoutePathname, setIsLoading,} from "../base/baseSlice";
import {RequestUserLoginInterface, RequestUserVisitExternalVacancy} from "ts/interfaces/api";
import {Container} from 'typedi';
import {ApiService} from "../../services/ApiService";
import {TokenService} from "../../services/TokenService";
import {UserFields} from "../../interfaces/UserFields";
import {RequestUserVisitorCompanyInterface} from "../../interfaces/api/RequestUserVisitorCompany";


export const getInitUser = (): AppThunk => (dispatch) => {

    const api = Container.get(ApiService);
    const tokenService = Container.get(TokenService);
    const isToken = !!tokenService.getToken();

    if (isToken) {
        dispatch(setIsLoading(true));

        api
            .get(endpoints.user.get)
            .then((res) => {
                dispatch(setIsLoading(false));
                if (res.data.success) {
                    dispatch(setUserFromApi(res.data.data));
                    dispatch(setBaseRoutePathname(routes.introduction.base));
                } else {
                    tokenService.removeToken();
                }
            }).catch((err) => {
                console.log(err);
                tokenService.removeToken();
                dispatch(setIsLoading(false));
        });
    }

};

export const requestUserAccountActivation = (hash: string, onSuccess: (message: string) => void, onFinally: () => void) : AppThunk => async (dispatch) => {

    dispatch(setIsLoading(true));

    const api = Container.get(ApiService);

    api
        .post(endpoints.user.public.activate, {
            hash: hash,
        }).then((res) => {
            if (res.data.state) {
                onSuccess(res.data.message)
            }
    })
        .catch((err) => {
        })
        .finally(() => {
            onFinally();
            dispatch(setIsLoading(false));
        });
}

export const requestUserLogin = (
    data: RequestUserLoginInterface,
    setError: (error: string) => void,
    onSuccessLogin: () => void,
    runCaptchaV2: () => void,
) : AppThunk => async (dispatch) => {

    dispatch(setIsLoading(true));

    const api = Container.get(ApiService);
    const tokenService = Container.get(TokenService);

    api
        .post(endpoints.user.public.login, {
            username: data.username,
            password: data.password,
            captchaToken: data.captchaToken,
            captchaTokenV2: data.captchaTokenV2,
    }).then((res) => {
        if (res.data.success) {
            dispatch(setUserFromApi(res.data.data));
            tokenService.setToken(res.data.data.token);
            onSuccessLogin();
        } else {
            if (res.data.c_v2) {
                runCaptchaV2();
                setError("Login failed.")
            } else {
                setError("Login failed. Invalid e-mail or password.")
            }
        }
    })
        .catch((err) => {
            setError("Login failed. Invalid e-mail or password.")
        })
        .finally(() => {
            dispatch(setIsLoading(false));
        });
}

export const requestUserLogout = () : AppThunk => async (dispatch) => {

    const tokenService = Container.get(TokenService);

    dispatch(setIsLoading(true));
    dispatch(removeUser());
    tokenService.removeToken();
    dispatch(setIsLoading(false));
    dispatch(setBaseRoutePathname(routes.login.base))
}

export const requestUserResetPassword = (email: string, onSuccess: () => void, onError: () => void) : AppThunk => async (dispatch) => {
    const api = Container.get(ApiService);

    api
        .post(endpoints.user.public.reset_password, {
            email: email,
        }).then((res) => {
        if (res.data.state) {
            onSuccess();
        } else {
            onError();
        }
    })
        .catch(() => {
            onError();
        })
        .finally(() => {

        });
}

export const putUserTutorialDone = (): AppThunk => (dispatch) => {
    const api = Container.get(ApiService);

    api
        .put(endpoints.user.tutorial, {
            tutorialIsDone: true
        })
        .then((res) => {
            // console.log(res);

        }).catch((err) => {
        console.log(err);
    });
};

export const putUserFields = (data: UserFields): AppThunk => (dispatch) => {

    const api = Container.get(ApiService);

    dispatch(setIsLoading(true));

    api
        .put(endpoints.user.answers, data)
        .then((res) => {
            if (res.data.state) {
                dispatch(setUserQuestionsDone(true));
            }
        }).catch((err) => {
        console.log(err);
    }).finally(() => {
        dispatch(setIsLoading(false));
    });
};


export const postUserVisitorCompany = (data: RequestUserVisitorCompanyInterface): AppThunk => (dispatch) => {

    const api = Container.get(ApiService);

    api
        .post(endpoints.auth.global.visitor, data)
        .then((res) => {
            // console.log(res);
        }).catch((err) => {
        console.log(err);
    }).finally(() => {
    });
};

export const postUserVisitExternalVacancy = (data: RequestUserVisitExternalVacancy): AppThunk => (dispatch) => {

    const api = Container.get(ApiService);

    api
        .post(endpoints.vacancies.externalVisit, data)
        .then((res) => {
            // console.log(res);
        }).catch((err) => {
        console.log(err);
    }).finally(() => {
    });
};


export const postUserInviteFriend = (
    data: {friendEmail: string},
    onSuccess: () => void
) : AppThunk => async (dispatch) => {
    const api = Container.get(ApiService);

    api
        .post(
            endpoints.user.invite,
            data
        )
        .then((res) => {
            onSuccess();
        }).catch((err) => {
        console.log(err);
    }).finally(() => {

    });
}
