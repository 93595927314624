import React, {useState} from 'react';
import {Modal} from "../../ui/modal/Modal";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectModals} from "../../../store/base/baseSlice";
import {Typography} from "../../ui/typography/Typography";
import {Input} from "../../ui/input/Input";
import {Button} from "../../ui/button/Button";
import {useForm} from "react-hook-form";
import {Status} from "../../ui/status/Status";
import {RequestUserInviteFriend, RequestVacancyQuestion} from "../../../interfaces/api";
import styles from "./modalInviteFriend.module.scss";
import {postUserInviteFriend} from "../../../store/user/userApi";
import {emailValidation} from "../../../utils/validations";

export function ModalInviteFriend() {

    const modals = useAppSelector(selectModals);
    const [success ,setSuccess] = useState(false);
    const [isDisabledLocal, setIsDisabledLocal] = useState(true);
    const [isLoading ,setIsLoading] = useState(false);

    const { register, reset, handleSubmit, formState: { errors, isValid }, getValues } = useForm<RequestUserInviteFriend>({
        mode: "onSubmit"
    });
    const dispatch = useAppDispatch();


    function handleChangeForm () {
        const values = getValues();
        setIsDisabledLocal(values.friendEmail.length < 3);

    }

    function onSuccess () {
        setSuccess(true);
        setIsLoading(false);
        setIsDisabledLocal(true);
    }

    const onSubmit = (data: RequestUserInviteFriend) => {
        setIsLoading(true);

        dispatch(
            postUserInviteFriend(
                {
                    friendEmail: data.friendEmail,
                },

                onSuccess
            )
        );
    };

    return (
        <Modal
            isOpen={modals.question ?? false}
            modalName={"invite"}
            title={"Invite a friend to Counselpath"}
            showCancel={true}
            showClose={true}
            cta={
                <Button
                    disabled={isLoading}
                    notValid={isDisabledLocal}
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    iconLeft={"envelope"}
                    size={"sm"}
                >
                    Send invitation
                </Button>
            }
        >
            <Typography as={"h3"} margin={1}>Do you know someone who could be interested in Counselpath?</Typography>
            <Typography as={"p"} margin={4}>Type their email address below to send them an invitation. As a member you can invite an unlimited number of connections.</Typography>

            <form onSubmit={handleSubmit(onSubmit)}  onChange={handleChangeForm}  className={styles.form}>
                <Input
                    {...register(
                        "friendEmail",
                        {
                            required: "Field is required",
                            validate: {
                                positive: v => emailValidation(v),
                            },
                        }
                    )}

                    isRequired={true}
                    label={"E-mail"}
                    id={"friendEmail"}
                    name={"friendEmail"}
                    type={"text"}
                    autoComplete={"on"}
                    hasError={!!errors.friendEmail}
                    errorMessage={errors.friendEmail?.message}
                />
                {
                    success &&
                        <Status type={"success"} message={"Invitation sent"} />
                }
            </form>

        </Modal>
    );

}

export default ModalInviteFriend;
