import React from 'react';
import clsx from "clsx";

type TypographyProps = {
    readonly as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
    readonly type?: 'small' | '';
    readonly children?: React.ReactNode;
    readonly className?: string;
    readonly margin?: number;
    readonly align?: "left" | "center" | "right";
};

export function Typography({
                               as: As = 'p',
                               children,
                               className = '',
                               margin,
                               align,
                               type = "",
                               ...props
                           }: TypographyProps) {

    const inlineStyles: any = {

    }

    if (margin || margin === 0) {
        inlineStyles.marginBottom = margin * 8 + "px";
    }

    if (align) {
        inlineStyles.textAlign = align;
    }

    return (
        <As
            className={clsx(className, type, {})}
            style={{...inlineStyles}}
            {...props}
        >
            {children}
        </As>
    );
}
