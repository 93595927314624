import React, {forwardRef, memo} from 'react';
import styles from "./checkboxBars.module.scss";
import clsx from 'clsx';
import {Icon} from "../icon/Icon";
import {Status} from "../status/Status";

type CheckboxBarsProps = {
    readonly id: string;
    readonly name: string;
    readonly ariaLabel?: string;
    readonly ariaDescribedby?: string;
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly hasError?: boolean;
    readonly errorMessage?: string;
    readonly isRequired?: boolean;
    readonly inputProps?: any;
    readonly onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readonly appearance?: "primary";
    readonly className?: string;
    readonly defaultValues?: Array<string>;
    readonly selected?: Array<string>;
    readonly choices: Array<{
        value: string,
        label: string,
    }>;
};

export const CheckboxBars = memo<CheckboxBarsProps>(
    forwardRef(
        (
            {
                disabled = false,
                isRequired = false,
                hasError,
                onChange,
                className,
                id,
                selected = [],
                errorMessage,
                name,
                choices,
                defaultValues = [],
                ...props
            },
            ref,
        ) => {

            function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
                if (onChange) {
                    onChange(e);
                }
            }

            return (
                <div
                    className={clsx(styles.wrapper, {
                        // [styles.wrapperSelected]: !!selected
                    })}
                >
                    {
                        choices &&
                        choices.map(choice => (
                            <div key={"key_"+choice.value} className={styles.field}>
                                <input
                                    id={"_"+name+"_"+choice.value}
                                    className={clsx(styles.radio, className, {
                                        [styles.radioDisabled]: disabled,
                                        [styles.radioError]: hasError,
                                    })}
                                    onChange={(e) => handleOnChange(e)}
                                    disabled={disabled}
                                    checked={selected.includes( choice.value )}
                                    //@ts-ignore
                                    ref={ref}
                                    type={"checkbox"}
                                    name={name}
                                    value={choice.value}
                                    {...props}
                                />

                                <label className={styles.label} htmlFor={"_"+name+"_"+choice.value}>
                                    {choice.label}
                                    <div className={styles.check}>
                                        <Icon icon={"check"} />
                                    </div>
                                </label>

                            </div>
                        ))
                    }
                    {
                        hasError && errorMessage &&
                            <Status type={"error"} message={errorMessage} />
                    }
                </div>

            );
        })
);
