import type {ReactNode} from 'react';
import React from "react";
import styles from "./confirm.module.scss";
import clsx from "clsx";
import { confirmAlert } from 'react-confirm-alert';
import {Icon} from "../../ui/icon/Icon";
import {Button} from "../../ui/button/Button";


type ConfirmProps = {
    readonly title: string;
    readonly message: string;
    readonly className?: string;
    readonly buttons: {
        yes: {
            label: string,
            onClick: () => void
        },
        no: {
            label: string,
            onClick: () => void
        }
    };
};

export const confirm = (options:ConfirmProps ) => {
    const {title, message, className, buttons} = options;
    return (
        confirmAlert({
            customUI: ({ onClose }) => {

                function handleClickYes () {
                    buttons.yes.onClick();
                    onClose()
                }

                function handleClickNo () {
                    buttons.no.onClick();
                    onClose()
                }

                return (
                    <div className={clsx(styles.confirm, className)}>
                        <div className={styles.header}>
                            <h5 className={styles.title}>{title}</h5>
                            <button
                                type={"button"}
                                className={styles.buttonClose}
                                onClick={handleClickNo}
                                title={"Close"}
                            >
                                <Icon icon={"close"}/>
                            </button>
                        </div>
                        <div className={styles.main}>
                            <p>{message}</p>
                        </div>
                        <div className={styles.footer}>
                            <Button
                                onClick={handleClickNo}
                                buttonStyle={"ghost"}
                                typeStyle={"link"}
                                size={"sm"}
                            >
                                {buttons.no.label}
                            </Button>
                            <div className={styles.footerCta}>
                                <Button
                                    onClick={handleClickYes}
                                    size={"sm"}
                                >
                                    {buttons.yes.label}
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            }
        })
    );
}
