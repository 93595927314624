import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {initialState} from "./initialState";
import {ModalsTypes} from "ts/typings/types";

export const baseSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        setBaseRoutePathname: (state, action: PayloadAction<string>) => {
            state.route.pathname = action.payload
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setRedirectTo: (state, action: PayloadAction<string | null>) => {
            state.route.redirectTo = action.payload
        },
        setRedirectDone: (state, action: PayloadAction<boolean>) => {
            state.route.redirectDone = action.payload
        },
        setModal: (state, action: PayloadAction<{
            modal: ModalsTypes,
            state: boolean,
        }>) => {
            state.modals[action.payload.modal] = action.payload.state;
        },
    },
});
export const {
    setIsLoading,
    setBaseRoutePathname,
    setModal,
    setRedirectTo,
    setRedirectDone,
} = baseSlice.actions;

export const selectRoute = (state: RootState) => state.base.route.pathname;
export const selectIsLoading = (state: RootState) => state.base.isLoading;
export const selectModals = (state: RootState) => state.base.modals;
export const selectRedirectTo = (state: RootState) => state.base.route.redirectTo;
export const selectRedirectDone = (state: RootState) => state.base.route.redirectDone;

export default baseSlice.reducer;
