import * as React from 'react';
import {ReactNode, useEffect, useRef, useState} from 'react';
import styles from "./fileSelector.module.scss";
import {Status} from "../../ui/status/Status";
import {Button} from "../../ui/button/Button";

type UploaderProps = {
    readonly children?: ReactNode;
    readonly fileExtensionsAccept?: string;
    readonly isLoading?: boolean;
    readonly dropArea?: boolean;
    readonly dropAreaTitle?: string;
    readonly dropAreaSubTitle?: string;
    readonly fileExtensions?: Array<string>;
    readonly maxFileSizeInBytes?: number;
    readonly onAddFile: (file: File | null) => void,
    readonly onError: () => void,
};

const FileSelector = ({
                          children,
                          onError,
                          onAddFile,
                          isLoading,
                          dropArea,
                          dropAreaTitle,
                          dropAreaSubTitle,
                          maxFileSizeInBytes,
                          fileExtensions = [],
                          fileExtensionsAccept = "",
                          ...props
                      }: UploaderProps) => {

    const [file, setFile] = useState<File | null>(null);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const fileInputField = useRef<HTMLInputElement>(null);
    const DEFAULT_MAX_FILE_SIZE_IN_BYTES = parseInt(process.env.REACT_APP_DEFAULT_MAX_FILE_SIZE_IN_BYTES || "2097152")

    function handleOnFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        let fileIsValid = true;

        // Update the state
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const ext = file?.name.split('.').pop() ?? "";

            // Valid size
            if (
                maxFileSizeInBytes && maxFileSizeInBytes < file.size
            ) {
                setHasError(true);
                setErrorMessage("File is too big. Max file size: " + DEFAULT_MAX_FILE_SIZE_IN_BYTES / 1024 / 1024 + "MB");
                onError();
                fileIsValid = false;
            }

            // Valid extension
            if (
                fileExtensions.length > 0 &&
                ext &&
                !fileExtensions.includes(ext)
            ) {
                setHasError(true);
                setErrorMessage("File should be one of extensions: " + fileExtensions);
                onError();
                fileIsValid = false;
            }

            if (fileIsValid) {
                setHasError(false);
                setErrorMessage("");
                setFile(file);
            }


        } else {
            onError();
        }

        // @ts-ignore
        if (props?.onChange) {
            // @ts-ignore
            props.onChange(e);
        }
    }

    function handleUploaderClick(e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>) {
        if (fileInputField?.current) {
            // @ts-ignore
            fileInputField.current.click();
        }
    }

    function handleResetFile() {
        setFile(null);

        if (fileInputField.current !== null) {
            fileInputField.current.value = "";
        }

        onAddFile(null);
    }

    useEffect(() => {
        if (file) {
            onAddFile(file);
        }
    }, [file])

    return (
        <>
            {
                children &&
                <button className={styles.uploader} onClick={handleUploaderClick} disabled={isLoading}>
                    {children}
                </button>
            }

            {
                dropArea &&
                <div className={styles.dropArea} onClick={handleUploaderClick}>
                    <div className={styles.dropAreaTitle}>{dropAreaTitle}</div>
                    <div className={styles.dropAreaSubtitle}>{dropAreaSubTitle}</div>
                </div>
            }

            {
                file &&
                <div className={styles.uploaderFileBar}>
                    <div className={styles.uploaderFileBarLabel}>{file.name}</div>
                    <Button iconRight={"delete"} onClick={handleResetFile} className={styles.uploaderFileBarButton}
                            typeStyle={"link"} size={"auto"} buttonStyle={"danger"}/>
                </div>
            }

            {
                hasError && errorMessage &&
                <Status type={"error"} message={errorMessage}/>
            }

            <input
                {...props}
                className={styles.input}
                type={"file"}
                ref={fileInputField}
                onChange={handleOnFileChange}
                accept={fileExtensionsAccept}

            />
        </>
    )
};

export default FileSelector;
