import React, {useEffect, useRef, useState} from "react";
import {Box} from "../../../partials/box/Box";
import styles from "./joinAccount.module.scss";
import {Input} from "../../../ui/input/Input";
import {Typography} from "../../../ui/typography/Typography";
import {useForm} from "react-hook-form";
import {Button} from "../../../ui/button/Button";
import {Checkbox} from "../../../ui/checkbox/Checkbox";
import {Tooltip} from "../../../ui/tooltip/Tooltip";
import {selectError, selectIsLoading,} from "../../../../store/preregistration/preregistrationSlice";
import {useAppDispatch, useAppSelector, useDocumentTitle} from "../../../../hooks";
import {Select} from "../../../ui/select/Select";
import {emailValidation, passwordValidation, spaceLessValidation} from "../../../../utils/validations";
import {fetchCheckData} from "../../../../store/preregistration/preregistrationAPI";
import {closeTabAlert} from "../../../../utils/closeTabAlert";
import {TemplatePre} from "../../../templates/templatePre/TemplatePre";
import {RequestUserRegisterInterface} from "../../../../interfaces/api";
import {Icon} from "../../../ui/icon/Icon";

const JoinAccount: React.FC = () => {

    useDocumentTitle("Join - Counselpath");

    const {
        register,
        watch,
        handleSubmit,
        setValue,
        setError,
        getValues,
        trigger,
        formState: {errors}
    } = useForm<RequestUserRegisterInterface>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const errorsState = useAppSelector(selectError);
    const [isDisabledLocal, setIsDisabledLocal] = useState(true);

    const onSubmit = handleSubmit(data => {
        dispatch(fetchCheckData(data));
    });
    const password = useRef({});
    password.current = watch("password", "");

    function handleChangeForm () {
        const values = getValues();
       if (
           // values.eligibilityRules &&
            values.email.length > 0 &&
            values.firstname.length > 0 &&
            values.lastname.length > 0 &&
            values.lawFirm.length > 0 &&
            values.password.length > 0 &&
            values.password2.length > 0
            // values.termRules
       ) {
           setIsDisabledLocal(false);
       } else {
           setIsDisabledLocal(true);
       }

    }

    useEffect(() => {

        for (const [key, value] of Object.entries(errorsState)) {
            // @ts-ignore
            setError(key, {
                type: "manual",
                message: value.message,
            });
        }



    }, [errorsState, setError]);

    useEffect(() => {
        closeTabAlert();
    }, []);


    return (
        <TemplatePre>
            <Box>
                <Typography as={"h1"}>Register</Typography>
                <Typography as={"p"} margin={4}>Register to access job opportunities from leading employers. Your profile is confidential and invisible to other members. An employer can only see your profile if you have voluntarily applied for a role with them.</Typography>

                <form onSubmit={onSubmit} className={styles.form} onChange={handleChangeForm}>
                    <Input
                        {...register(
                            "firstname",
                            {
                                required: "Field is required",
                                validate: {
                                    spaceLess: v => spaceLessValidation(v)
                                }
                            }
                        )}
                        isRequired={true}
                        label={"First name"}
                        id={"firstname"}
                        name={"firstname"}
                        hasError={!!errors.firstname}
                        errorMessage={errors.firstname?.message}
                    />
                    <Input
                        {...register(
                            "lastname",
                            {
                                required: "Field is required",
                                validate: {
                                    spaceLess: v => spaceLessValidation(v)
                                }
                            }
                        )}
                        isRequired={true}
                        label={"Last name"}
                        id={"lastname"}
                        name={"lastname"}
                        hasError={!!errors.lastname}
                        errorMessage={errors.lastname?.message}
                    />
                    <Input
                        {...register(
                            "email",
                            {
                                required: "Field is required",
                                validate: {
                                    positive: v => emailValidation(v),
                                },
                            }
                        )}
                        isRequired={true}
                        label={"E-mail"}
                        id={"email"}
                        name={"email"}
                        type={"text"}
                        hasError={!!errors.email}
                        errorMessage={errors.email?.message}
                    />
                    <Input
                        {...register(
                            "password",
                            {
                                required: "Field is required",
                                validate: {
                                    positive: v => passwordValidation(v),
                                },
                            }
                        )}
                        isRequired={true}
                        type={"password"}
                        label={"Password"}
                        id={"password"}
                        name={"password"}
                        hasError={!!errors.password}
                        errorMessage={errors.password?.message}
                    />

                    <Input
                        {...register(
                            "password2",
                            {
                                required: "Field is required",
                                validate: value =>
                                    value === password.current || "The passwords do not match"
                            }
                        )}
                        isRequired={true}
                        type={"password"}
                        label={"Repeat password"}
                        id={"password2"}
                        name={"password2"}
                        hasError={!!errors.password2}
                        errorMessage={errors.password2?.message}
                    />

                    <Select
                        {...register(
                            "lawFirm",
                            {
                                required: "Field is required",
                            }
                        )}
                        isRequired={true}
                        name={"lawFirm"}
                        id={"lawFirm"}
                        onChangeMiddleware={handleChangeForm}
                        label={"Current or most recent law firm"}
                        dropDownLabel={`Search (if law firm not listed, type in a name and click "Add")`}
                        hasError={!!errors.lawFirm}
                        errorMessage={errors.lawFirm?.message}
                        setValue={setValue}
                        suggestions={["Addleshaw Goddard", "Akin Gump", "Allen & Overy", "Arnold & Porter", "Ashurst", "Baker McKenzie", "Bevan Brittan", "Bird & Bird", "Bryan Cave Leighton Paisner", "CMS (Cameron McKenna)", "Carey Olsen", "Charles Russel Speechlys", "Cleary Gottlieb Steen & Hamilton", "Clifford Chance", "Clyde & Co", "Cooley", "Covington & Burling", "Curtins Mallet-Prevost", "DAC Beachcroft", "DLA Piper", "DWF Group", "Davis Polk", "Debevoise & Plimpton", "Dechert", "Dentons", "Dorsey & Whitney", "Eversheds Sutherland", "Farrer & Co", "Fieldfisher", "Fladgate", "Fox Williams", "Freshfields Bruckhaus Deringer", "Fried Frank", "Gibson Dunn", "Goodwin", "Greenberg Traurig", "Gunnercooke", "HFW (Holman Fenwick Willan)", "Harbottle & Lewis", "Hausfeld", "Herbert Smith Freehills", "Hogan Lovells", "Irwin Mitchell", "Jones Day", "K&L Gates", "Kennedys", "Kirkland & Ellis", "Latham & Watkins", "Linklaters", "Locke Lord", "Macfarlanes", "Maples Teesdale", "Mayer Brown", "Millbank", "Millls & Reve", "Mishcon de Reya", "Morgan Lewis", "Morgan, Lewis & Bockius", "Morrison & Foerster", "Northridge Law", "Norton Rose Fulbright", "Orrick", "Paul Hastings", "Pillsbury", "Pinsent Masons", "Quinn Emanuel", "Reed Smith", "Reynolds Porter Chamberlain", "Ropes & Gray", "Shearman & Sterling", "Shoosmiths", "Sidley Austin", "Simmons & Simmons", "Simpson Thacher & Bartlett", "Skadden", "Slaughter and May", "Squire Patton Boggs", "Stephenson Harwood", "Sullivan & Cromwell", "Taylor Wessing", "Travers Smith", "Trowers & Hamlins", "Vinson & Elkins", "Watson, Farley & Williams", "Weil Gotshal & Manges", "White & Case", "Wiggin", "Wilkie Farr & Gallagher", "Winston & Strawn", "Withers"]}
                    />

                    <Checkbox
                        {...register(
                            "isNewsletter",
                        )}
                        isRequired={false}
                        id={"isNewsletter"}
                        name={"isNewsletter"}
                    >
                        Yes, keep me up to date with law careers insights, reports and interviews via a monthly newsletter
                    </Checkbox>

                    {/*<Checkbox*/}
                    {/*    {...register(*/}
                    {/*        "termRules",*/}
                    {/*        {*/}
                    {/*            required: "Field is required",*/}
                    {/*        }*/}
                    {/*    )}*/}
                    {/*    isRequired={true}*/}
                    {/*    id={"termRules"}*/}
                    {/*    name={"termRules"}*/}
                    {/*    hasError={!!errors.termRules}*/}
                    {/*    errorMessage={errors.termRules?.message}*/}
                    {/*>*/}
                    {/*    I agree to <a rel="noreferrer" target={"_blank"} href={process.env.REACT_APP_LANDING_URL + "/terms-conditions/"}>Counselpath’s*/}
                    {/*    Terms</a> and <a rel="noreferrer" target={"_blank"} href={process.env.REACT_APP_LANDING_URL + "/privacy-policy/"}>Privacy*/}
                    {/*    Policy</a>.*/}
                    {/*</Checkbox>*/}

                    {/*<Checkbox*/}
                    {/*    {...register(*/}
                    {/*        "eligibilityRules",*/}
                    {/*        {*/}
                    {/*            required: "Field is required",*/}
                    {/*        }*/}
                    {/*    )}*/}
                    {/*    isRequired={true}*/}
                    {/*    id={"eligibilityRules"}*/}
                    {/*    name={"eligibilityRules"}*/}
                    {/*    hasError={!!errors.eligibilityRules}*/}
                    {/*    errorMessage={errors.eligibilityRules?.message}*/}
                    {/*>*/}
                    {/*    I meet eligibility criteria: I am an NQ, Associate or Counsel qualified within one of the eligible legal practices*/}
                    {/*    <Tooltip style={{marginLeft: 12}} content={<><strong>Eligible practices:</strong> Corporate, Capital Markets, Banking and Finance, Funds, Real Estate, Antitrust and Competition, Restructuring and Insolvency, Employment, Tax, Dispute Resolution, Intellectual Property </>}>*/}
                    {/*        <Icon icon={"info"} />*/}
                    {/*    </Tooltip>*/}
                    {/*</Checkbox>*/}

                    <div className={styles.footer}>
                        <Button
                            type={"submit"}
                            notValid={isDisabledLocal}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Submit
                        </Button>

                        <p style={{textAlign: "center"}}>
                            By clicking submit you accept our <a rel="noreferrer" target={"_blank"} href={process.env.REACT_APP_LANDING_URL + "/terms-conditions/"}>Terms and Conditions</a> and <a rel="noreferrer" target={"_blank"} href={process.env.REACT_APP_LANDING_URL + "/privacy-policy/"}>Privacy Policy</a>, <br /> and confirm you are eligible for membership
                                <Tooltip style={{marginLeft: 8}} content={<>Membership is open to associates specialising in one of the eligible practices: Antitrust & Competition, Banking & Finance, Capital Markets, Corporate, Dispute Resolution, Funds, Intellectual Property, Real Estate, Regulatory, Restructuring & Insolvency, Tax</>}>
                                    <Icon icon={"info"} />
                                </Tooltip>
                        </p>
                    </div>
                </form>
            </Box>
        </TemplatePre>

    );
}

export default JoinAccount;
