import * as React from 'react';
import {NavLink, useLocation} from "react-router-dom";
import {routes} from "../../../config/routes";
import styles from "./menuVacancies.module.scss";
import {Badge} from "../../ui/badge/Badge";
import clsx from "clsx";
import {useAppSelector} from "../../../hooks";
import {selectVacanciesIsLoading} from "../../../store/vacancies/vacanciesSlice";
import {Loader} from "../../ui/loader/Loader";

type MenuVacanciesProps = {
    readonly all: number
    readonly matched: number
};

export function MenuVacancies ({
                                   all,
                                   matched
                             }: MenuVacanciesProps) {
    const location = useLocation();
    const isLoading = useAppSelector(selectVacanciesIsLoading);

    return (
        <nav className={styles.menu}>
            <NavLink end={true} activeClassName={styles.menuLinkActive} className={styles.menuLink} to={`${routes.dashboard.opportunities.base}`}>
                <span>Matched Opportunities</span>
                <Badge className={styles.menuLinkBadge}>
                    {
                        isLoading ?
                            <Loader />
                            : <>{matched}</>
                    }
                </Badge>
            </NavLink>
            <NavLink end={true} activeClassName={styles.menuLinkActive} className={styles.menuLink} to={`${routes.dashboard.opportunities.all}`}>
                <span>All Opportunities</span>
                <Badge className={styles.menuLinkBadge}>
                    {
                        isLoading ?
                            <Loader />
                            : <>{all}</>
                    }

                </Badge>
            </NavLink>

            <span className={clsx(styles.menuBar, {
                [styles.menuBarTop]: location.pathname === routes.dashboard.opportunities.base,
                [styles.menuBarBottom]: location.pathname === routes.dashboard.opportunities.all,
            })} />
        </nav>
    )
};

export default MenuVacancies;
