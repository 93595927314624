import styles from "./VacancyApplicationExternal.module.scss";
import React from "react";
import clsx from "clsx";
import {VacancyExtendedInterface} from "../../../interfaces/VacancyExtendedInterface";
import {Button} from "../../ui/button/Button";
import {useAppDispatch} from "../../../hooks";
import {postUserVisitExternalVacancy} from "../../../store/user/userApi";
import {Tooltip} from "../../ui/tooltip/Tooltip";
import {Icon} from "../../ui/icon/Icon";

type VacancyApplicationProps = {
    vacancyExtended: VacancyExtendedInterface
};

export function VacancyApplicationExternal ({
                                                vacancyExtended,
                                            }: VacancyApplicationProps) {

    const dispatch = useAppDispatch();

    function goToExternalUrl () {
        if (vacancyExtended) {
            const url = vacancyExtended.externalUrl ?? process.env.REACT_APP_API_URL;
            dispatch(
                postUserVisitExternalVacancy({
                    vacancy_id: vacancyExtended.id,
                })
            );
            if (vacancyExtended.externalUrl) {
                window.open(url, "_blank");
            }

        }
    }


    return (
        <div className={clsx(styles.status)}>

            <div className={styles.helpTooltip}>
                <Tooltip content={"Counselpath does not actively oversee the application process for this role. You will be redirected to apply on the employer website."} align={"center"}>
                    <Icon icon={"info"} />
                </Tooltip>
            </div>

            <Button
                buttonStyle={"normal"}
                size={"sm"}
                full={true}
                iconLeft={"plane"}
                className={styles.button}
                onClick={goToExternalUrl}
            >
                Apply on employer site
            </Button>
        </div>
    )
}
