import React, {forwardRef, memo, useState} from 'react';
import styles from "./accordion.module.scss";
import clsx from 'clsx';

type AccordionProps = {
    readonly label: React.ReactNode;
    readonly children: React.ReactNode;
    readonly className?: string;
    readonly defaultOpen?: boolean;
};

export const Accordion = memo<AccordionProps>(
    forwardRef(
        (
            {
                className,
                label,
                children,
                defaultOpen = false
            },
            ref,
        ) => {
            const [open, setOpen] = useState(defaultOpen);


            return (
                <div className={clsx(
                    styles.accordion, {
                        [styles.accordionOpened]: open
                    }
                )} onClick={() => setOpen(!open)}>
                    <div className={clsx(
                        styles.accordionBar, {
                        }
                    )}>
                        {label}
                        <span className={styles.accordionBarArrow} />
                    </div>
                    <div
                        className={clsx(
                            styles.accordionBody
                        )}>
                        {children}
                    </div>
                </div>
            );
        })
);
