import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {initialState} from "./initialState";
import {ResponseUserInterface} from "../../interfaces/api";
import {RootState} from "../store";
import {TermStateInterface} from "../../interfaces/TermStateInterface";
import {MonthDateInterface} from "../../interfaces/MonthDateInterface";

function upsertTerms(array: Array<TermStateInterface> | undefined, item: TermStateInterface) {
    if (array) {
        const i = array.findIndex(_item => _item.term_id === item.term_id);
        if (i > -1) {
            array = array.filter(_item => _item.term_id !== item.term_id)
        }
        else array = [
            ...array,
            item
        ];
    } else {
        array = [
            item
        ];
    }
    return array;
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserFromApi: (state, action: PayloadAction<ResponseUserInterface>) => {

            state.data = {
                id: action.payload.id,
                email: action.payload.email,
                nicename: action.payload.nicename,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                displayName: action.payload.displayName,
                tutorialDone: action.payload.tutorialDone,
                questionsDone: action.payload.questionsDone,
                userStatus: action.payload.userStatus,
                emailVerified: action.payload.emailVerified,

            }

            state.isAuth = true;
        },
        setUserTutorial: (state, action: PayloadAction<boolean>) => {
            if (state.data) {
                state.data.tutorialDone = action.payload;
            }
        },
        setUserIsAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload;
        },
        setUserQuestionsDone: (state, action: PayloadAction<boolean>) => {
            if (state.data) {
                state.data.questionsDone = action.payload;
            }
        },
        removeUser: (state) => {
            state.isAuth = false;
            delete state.data;
        },
        setUserFieldStatus: (state, action: PayloadAction<string>) => {
            state.fields.u_status = action.payload;
        },
        setUserFieldSegments: (state, action: PayloadAction<TermStateInterface>) => {
            state.fields.u_segments = upsertTerms(
                state.fields.u_segments || [],
                action.payload
            );
        },
        setUserFieldFactors: (state, action: PayloadAction<TermStateInterface>) => {
            state.fields.u_factors = upsertTerms(
                state.fields.u_factors || [],
                action.payload
            );
        },
        setUserFieldWorkPreferences: (state, action: PayloadAction<{
            value: string,
            toAdd: boolean
        }>) => {
            if (action.payload.toAdd) {
                if (!(state.fields.u_work_preferences ?? []).includes( action.payload.value )) {
                    state.fields.u_work_preferences = [
                        ...state.fields.u_work_preferences || [],
                        action.payload.value
                    ];
                }

            } else {
                state.fields.u_work_preferences = (state.fields.u_work_preferences || []).filter(_item => _item !== action.payload.value)
            }
            // state.fields.u_work_preferences = action.payload;
        },
        setUserFieldSectors: (state, action: PayloadAction<TermStateInterface>) => {
            state.fields.u_sectors = upsertTerms(
                state.fields.u_sectors || [],
                action.payload
            );
        },
        setUserFieldStartPqe: (state, action: PayloadAction<MonthDateInterface>) => {
            state.fields.u_start_pqe = action.payload;
        },
        addUserFieldJurisdictionItem: (state, action: PayloadAction<TermStateInterface>) => {
            state.fields.u_jurisdictions = [
                ...state.fields.u_jurisdictions || [],
                action.payload
            ]
        },
        removeUserFieldJurisdictionItem: (state, action: PayloadAction<number>) => {
            if (state.fields.u_jurisdictions) {
                state.fields.u_jurisdictions = state.fields.u_jurisdictions.filter(_item => _item.term_id !== action.payload)
            }
        },
        addUserFieldAdditionalJurisdictionItem: (state, action: PayloadAction<TermStateInterface>) => {
            state.fields.u_additional_jurisdictions = [
                ...state.fields.u_additional_jurisdictions || [],
                action.payload
            ]
        },
        removeUserFieldAdditionalJurisdictionItem: (state, action: PayloadAction<number>) => {
            if (state.fields.u_additional_jurisdictions) {
                state.fields.u_additional_jurisdictions = state.fields.u_additional_jurisdictions.filter(_item => _item.term_id !== action.payload)
            }
        },
        removeUserFieldAdditionalJurisdictionItems: (state, action: PayloadAction) => {
            if (state.fields.u_additional_jurisdictions) {
                state.fields.u_additional_jurisdictions = []
            }
        },
        setUserFieldPractices: (state, action: PayloadAction<TermStateInterface>) => {
            state.fields.u_practices = upsertTerms(
                state.fields.u_practices || [],
                action.payload
            );
        },
        setUserFieldPracticesEmpty: (state, action: PayloadAction) => {
            state.fields.u_practices = [];
        },
        setUserFieldPracticesDescription: (state, action: PayloadAction<string>) => {
            state.fields.u_practices_description = action.payload;
        },
        setUserFieldRequireVisa: (state, action: PayloadAction<string>) => {
            state.fields.u_require_visa = action.payload;
        },
        setUserFieldSalary: (state, action: PayloadAction<number | null>) => {
            state.fields.u_salary = action.payload;
        },
        setUserFieldSalarySkip: (state, action: PayloadAction<boolean>) => {
            state.fields.u_salary_skip = action.payload;
        },
        setUserFieldCommunicationPreferences: (state, action: PayloadAction<{
            value: string,
            toAdd: boolean
        }>) => {
            if (action.payload.toAdd) {
                state.fields.u_communication_preferences = [
                    ...state.fields.u_communication_preferences || [],
                    action.payload.value
                ];
            } else {
                state.fields.u_communication_preferences = (state.fields.u_communication_preferences || []).filter(_item => _item !== action.payload.value)
            }
        },
    },
});

export const {
    setUserFromApi,
    setUserIsAuth,
    removeUser,
    setUserQuestionsDone,
    setUserTutorial,
    setUserFieldSegments,
    setUserFieldStatus,
    setUserFieldFactors,
    setUserFieldWorkPreferences,
    setUserFieldSectors,
    setUserFieldStartPqe,
    addUserFieldJurisdictionItem,
    removeUserFieldJurisdictionItem,
    setUserFieldPractices,
    setUserFieldPracticesEmpty,
    setUserFieldPracticesDescription,
    setUserFieldRequireVisa,
    setUserFieldSalary,
    setUserFieldSalarySkip,
    setUserFieldCommunicationPreferences,
    addUserFieldAdditionalJurisdictionItem,
    removeUserFieldAdditionalJurisdictionItem,
    removeUserFieldAdditionalJurisdictionItems,
} = userSlice.actions;

export const selectUserIsAuth = (state: RootState) => ( state.user.isAuth);
export const selectUserQuestionsDone = (state: RootState) => ( state.user.data ? state.user.data.questionsDone : false);
export const selectUser = (state: RootState) => state.user.data;
export const selectUserFields = (state: RootState) => state.user.fields;
export const selectUserFieldStatus = (state: RootState) => state.user.fields.u_status;
export const selectUserFieldSegments = (state: RootState) => state.user.fields.u_segments;
export const selectUserFieldFactors = (state: RootState) => state.user.fields.u_factors;
export const selectUserFieldWorkPreferences = (state: RootState) => state.user.fields.u_work_preferences;
export const selectUserFieldSectors = (state: RootState) => state.user.fields.u_sectors;
export const selectUserFieldStartPqe = (state: RootState) => state.user.fields.u_start_pqe;
export const selectUserFieldJurisdiction = (state: RootState) => state.user.fields.u_jurisdictions;
export const selectUserFieldAdditionalJurisdiction = (state: RootState) => state.user.fields.u_additional_jurisdictions;
export const selectUserFieldPractices = (state: RootState) => state.user.fields.u_practices;
export const selectUserFieldPracticesDescription = (state: RootState) => state.user.fields.u_practices_description;
export const selectUserFieldRequireVisa = (state: RootState) => state.user.fields.u_require_visa;
export const selectUserFieldSalary = (state: RootState) => state.user.fields.u_salary;
export const selectUserFieldSalarySkip = (state: RootState) => state.user.fields.u_salary_skip;
export const selectUserFieldCommunicationPreferences = (state: RootState) => state.user.fields.u_communication_preferences;


export default userSlice.reducer;
