import {Service} from "typedi";

@Service()
export class TokenService {

    private readonly tokenLabel: string = process.env.REACT_APP_LS_TOKEN_KEY || "__hrl-token__";

    hasToken(): boolean {
        return !!this.getToken();
    }

    public setToken(token: string | null = null): void {
        if (!token) {
            this.removeToken();
        } else {
            if (this.getToken() !== token) {
                localStorage.setItem(this.tokenLabel, token);
            }
        }
    }

    public getToken(): string | null {
        return localStorage.getItem(this.tokenLabel) || null;
    }

    public removeToken(): void {
        localStorage.removeItem(this.tokenLabel);
    }
}
