import React, {useEffect, useState} from "react";
import {QuestionInterface} from "ts/interfaces";
import {useAppDispatch, useAppSelector} from "ts/hooks";
import {
    selectUserFieldSalary,
    selectUserFieldSalarySkip,
    setUserFieldSalary,
    setUserFieldSalarySkip,
} from "ts/store/user/userSlice";
import styles from "./questionTen.module.scss";
import {SalarySlider} from "ts/components/partials/salarySlider/SalarySlider";
import {Checkbox} from "../../../../ui/checkbox/Checkbox";

type QuestionNineProps = {
    question: QuestionInterface,
    onValid: (isValid: boolean) => void,
};

export const QuestionTen = ({
                                onValid,
                                question,
                         }: QuestionNineProps) => {

    const value = useAppSelector(selectUserFieldSalary);
    const [localValue, setLocalValue] = useState<number | null>(null);
    const salarySkipped = useAppSelector(selectUserFieldSalarySkip);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (salarySkipped) {
            onValid(!!(value === null || value));
        } else {
            onValid(!!(value));
        }

    }, [value, salarySkipped]);

    useEffect(() => {
        if (salarySkipped) {

        } else {
            if (localValue) {
                dispatch(
                    setUserFieldSalary(localValue)
                );
            }
        }

    }, [salarySkipped]);

    function handleChange (value: number) {

        setLocalValue(value);

        dispatch(
            setUserFieldSalary(value)
        );
    }

    function handleCheckSkip (e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(
            setUserFieldSalarySkip(e.target.checked)
        );

        if (e.target.checked) {
            dispatch(
                setUserFieldSalary(null)
            );
        }
    }

    return (
        <div className={styles.wrapper}>
            <SalarySlider
                id={question.fieldSlug}
                name={question.fieldSlug}
                onChange={handleChange}
                defaultValue={value ? value : 90}
                disabled={salarySkipped}
            />
            <div className={styles.wrapperCheckbox}>
                <Checkbox
                    id={question.fieldSlug + "_skip"}
                    name={question.fieldSlug + "_skip"}
                    onChange={handleCheckSkip}
                    defaultChecked={salarySkipped}
                >
                    I don’t have a minimum base salary in mind
                </Checkbox>
            </div>
        </div>
    );
}
