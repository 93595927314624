import type {ReactNode} from 'react';
import React from "react";
import styles from "./templateIntroduction.module.scss";
import {Brand} from "../../partials/brand/Brand";
import {Button} from "../../ui/button/Button";
import Footer from "../../partials/footer/Footer";
import {useAppDispatch} from "../../../hooks";
import {requestUserLogout} from "../../../store/user/userApi";
import {Outlet} from "react-router-dom";

type TemplateAsideProps = {
    readonly children?: ReactNode;
};

export const TemplateIntroduction = ({children}: TemplateAsideProps) => {

    const dispatch = useAppDispatch();

    function handleLogoutClick () {
        dispatch(requestUserLogout());
    }

    return (
        <div className={styles.wrapper}>

            <div className={styles.navbar}>
                <div className={"container"}>
                    <div className={styles.navbarInner}>
                        <Brand theme={"dark"} size={"medium"}/>

                        <Button onClick={handleLogoutClick} iconLeft={"exit"} buttonStyle={"ghost"} typeStyle={"link"}>Logout</Button>
                    </div>
                </div>
            </div>

            <div className={"container"}>
                <Outlet />
            </div>

            <div className={styles.footer}>
                <Footer />
            </div>

        </div>
    );
}
