import * as React from 'react';
import {NavLink} from "react-router-dom";
import {routes} from "../../../config/routes";
import styles from "./navigation.module.scss";

const Navigation: React.FC = () => (
    <nav className={styles.navbar}>
        <NavLink activeClassName={styles.navbarLinkActive} className={styles.navbarLink} to={routes.dashboard.opportunities.base}>Opportunities</NavLink>
        <NavLink activeClassName={styles.navbarLinkActive} className={styles.navbarLink} to={routes.dashboard.preferences.base}>My preferences</NavLink>
        <a target="_blank" className={styles.navbarLink} href={process.env.REACT_APP_LANDING_URL_INSIGHTS ?? "#"}>Insights</a>

    </nav>
);

export default Navigation;
