import {AppThunk} from "../store";
import {endpoints} from "../../config/endpoints";
import {QuestionInterface} from "../../interfaces/QuestionInterface";
import {Container} from "typedi";
import {ApiService} from "../../services/ApiService";


export const getQuestions = (onSuccess: (questions: Array<QuestionInterface>) => void): AppThunk => (dispatch) => {

    const api = Container.get(ApiService);

    api
        .get(endpoints.auth.global.process.get)
        .then((res) => {
            onSuccess(res.data);
        }).catch((err) => {
        console.log(err);
    });
};
