import React, {useEffect} from "react";
import {useAppSelector} from "../../../hooks";
import {selectUser} from "../../../store/user/userSlice";
import {Navigate} from "react-router-dom";
import {routes} from "../../../config/routes";
import {Tutorial} from "../../partials/tutorial/Tutorial";
import {Questions} from "../../partials/questions/Questions";
import {InfoBox} from "../../partials/infoBox/InfoBox";
import infoboxImage from "img/illustrations/thankyou.svg";
import {USER_STATUS} from "ts/typings/enums";
import {isMobile} from "react-device-detect";
import infoboxMobileImage from "img/illustrations/thankyou.svg";
import {Box} from "../../partials/box/Box";


const Introduction: React.FC = () => {

    const user = useAppSelector(selectUser);

    useEffect(() => {
        // closeTabAlert();
    }, []);

    if (user) {
        return (
            <div>

                {
                    !user.emailVerified &&
                        <InfoBox title={"Please verify your email."} description={"Please click on the link we have sent to your email address. Once this is done, your account will be reviewed by a member of the Counselpath team."} image={infoboxImage} />
                }

                {
                    user.emailVerified && (user.userStatus === USER_STATUS.new || user.userStatus === USER_STATUS.rejected) &&
                        <InfoBox title={"Thank you for registering!"} description={"Counselpath is a careers platform for experienced solicitors. We verify prospective members to ensure each profile fits our community. Your account will become active once a member of the Counselpath team reviews your profile (typically within 1-2 working days). We will send you an email notification once this has been completed."} image={infoboxImage} />
                }

                {
                    user.emailVerified && user.userStatus === USER_STATUS.activated && isMobile &&
                        <InfoBox title={"Counselpath is currently available on desktop only. "} description={"Please visit the site on your computer to login. We are working on launching a mobile version in the future."} image={infoboxMobileImage} />
                }

                {
                    user.emailVerified && user.userStatus === USER_STATUS.activated && !user.questionsDone && !isMobile &&
                        <Questions />
                }

                {
                    user.emailVerified && user.userStatus === USER_STATUS.activated && !user.tutorialDone && user.questionsDone && !isMobile &&
                        <Tutorial />
                }

                {
                    user.emailVerified && user.userStatus === USER_STATUS.activated && user.tutorialDone && user.questionsDone && !isMobile &&
                        <Navigate to={routes.dashboard.base} replace={true}/>
                }

            </div>
        );
    }
    return <Navigate to={routes.login.base} replace={true}/>

}

export default Introduction;
