import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../hooks";
import {selectRoute,} from "../../store/base/baseSlice";

type RoutingProps = {
    readonly children?: React.ReactNode;
}

export function Routing({children}: RoutingProps) {

    const navigate = useNavigate();
    const currentPathname = useAppSelector(selectRoute);

    useEffect(() => {
        if (currentPathname) {
            if (currentPathname.length > 2) {
                navigate(currentPathname);
            }
            // console.log(currentPathname);

        }
    }, [currentPathname, navigate]);

    return (
        <>
            {children}
        </>
    );
}

export default Routing;
