import clsx from 'clsx';
import styles from './salary.module.scss';
import React from "react";
import {SalaryInterface} from "../../../interfaces/SalaryInterface";
import {Icon} from "../../ui/icon/Icon";
import {amountWithCurrency} from "../../../utils/amountWithCurrency";
import {Tooltip} from "../../ui/tooltip/Tooltip";

type SalaryProps = {
    readonly salary: SalaryInterface;
};

export function Salary({
                           salary
                      }: SalaryProps) {
    return (
        <span
            className={clsx(styles.wrapper, {

            })}
        >
            {
                (salary.fit && salary.effect) &&
                <Tooltip
                    content={"Meets or exceeds your minimum salary expectations"}
                    align={"center"}
                    tooltipClassName={styles.tooltip}
                >
                    <div className={styles.icon}>
                        <Icon icon={"check"} />
                    </div>
                </Tooltip>
            }
            <div className={styles.value}>
                 {
                     amountWithCurrency(salary.value)
                 }
            </div>


        </span>
    )
}
