import React, {SyntheticEvent, forwardRef, memo, useEffect, useState,} from 'react';
import styles from "./select.module.scss";
import clsx from 'clsx';
import {Dropdown} from "../dropdown/Dropdown";
import slugify from "slugify";


type SelectProps = {
    readonly id: string;
    readonly name: string;
    readonly label?: string;
    readonly ariaLabel?: string;
    readonly ariaDescribedby?: string;
    readonly value?: string;
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly hasError?: boolean;
    readonly errorMessage?: string;
    readonly dropDownLabel?: string;
    readonly isRequired?: boolean;
    readonly maxLength?: number;
    readonly setValue?: any;
    readonly onChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
    readonly onChangeMiddleware?: () => void;
    readonly onBlur?: (e: SyntheticEvent<HTMLSelectElement>) => void;
    readonly appearance?: "primary" | "secondary";
    readonly className?: string;
    readonly suggestions: Array<string>

};

export const Select = memo<SelectProps>(
    forwardRef(
        (
            {
                disabled = false,
                isRequired = false,
                label,
                hasError,
                suggestions = [],
                onBlur,
                onChange,
                onChangeMiddleware,
                setValue,
                dropDownLabel,
                className,
                id,
                name,
                errorMessage,
                value = "",
                ...props
            },
            ref,
        ) => {


            const [valueState, setValueState] = useState<string>(value);
            const [suggestionsLocal, setSuggestionsLocal] = useState<Array<string>>(suggestions);

            const [toggleDropdown, setToggleDropdown] = useState(false);

            const closeToggle = () => {
                setToggleDropdown(false);
            };

            function handleOnChange(e: React.MouseEvent<HTMLButtonElement>) {
                setValueState(e.currentTarget.value);
                if (onChange) {
                    onChange(e);
                    if (onChangeMiddleware) {
                        onChangeMiddleware();
                    }
                }

            }

            function handleOnSelect (value: string) {
                setValueState(value);
                setValue(name, value, { shouldValidate: true });
                setToggleDropdown(false);

                if (onChangeMiddleware) {
                    onChangeMiddleware();
                }
            }

            function handleButtonSelectClick(e: React.MouseEvent<HTMLElement>) {
                setToggleDropdown(!toggleDropdown);
            }

            function addCustomSuggestion (customSuggestion: string) {
                setSuggestionsLocal([
                    ...suggestionsLocal,
                    customSuggestion
                    ]
                );
            }

            useEffect(() => {
                setValueState(value);
            }, [value]);

            return (
                <div
                    className={clsx(styles.wrapper, {
                        [styles.wrapperFilled]: valueState.length > 0,
                    })}

                >
                    {
                        label &&
                        <label className={styles.label} htmlFor={`${id}_button`}>
                            {label}
                            {
                                isRequired && <span className={styles.inputRequired}>*</span>
                            }
                        </label>
                    }

                    <div
                        className={clsx(styles.field, {
                            [styles.fieldError]: hasError,
                        })}
                    >
                        <button
                            type={"button"}
                            id={`${id}_button`}
                            className={clsx(styles.select, className, {
                            [styles.selectDisabled]: disabled,
                            [styles.selectError]: hasError,
                            })}
                            onClick={handleButtonSelectClick}
                        >
                            {
                                valueState && <div className={styles.selectValue}>{valueState}</div>
                            }
                            <span className={styles.selectArrow} />
                        </button>
                    </div>

                    {
                        toggleDropdown &&
                            <Dropdown
                                addSuggestion={addCustomSuggestion}
                                onSelect={handleOnSelect}
                                closeToggle={closeToggle}
                                name={name}
                                dropDownLabel={dropDownLabel}
                                suggestions={suggestionsLocal }
                            />
                    }

                    <select
                        id={id}
                        className={clsx(styles.input, className)}
                        // @ts-ignore
                        onChange={handleOnChange}
                        //@ts-ignore
                        ref={ref}
                        name={name}
                        autoComplete="off"
                        {...props}
                    >
                        <option />
                        {
                            suggestionsLocal.map(item => <option key={slugify(item)} value={item}>{item}</option>)
                        }
                    </select>
                    {
                        hasError && errorMessage &&
                        <div className={styles.errorMessage}>{errorMessage}</div>
                    }
                </div>

            );
        })
);
