import * as React from 'react';
import styles from "./footer.module.scss";
import {Brand} from "../brand/Brand";
import {Icon} from "../../ui/icon/Icon";

const Footer: React.FC = () => (
    <footer className={styles.footer}>

        <div className="container">
            <footer className={styles.inner}>
                <div className={styles.brand}>
                    <Brand theme={"light"}/>
                </div>

                <div className={styles.nav}>
                    <a rel="noreferrer" className={styles.navLink} target={"_blank"} href={"https://counselpath.com/terms-conditions/"}>Terms & Conditions</a>
                    <a rel="noreferrer" className={styles.navLink} target={"_blank"} href={"https://counselpath.com/privacy-policy/"}>Privacy Policy</a>
                </div>


                <div className={styles.more}>
                    <a rel="noreferrer" href="mailto:contact@counselpath.com" target={"_blank"} >contact@counselpath.com</a>
                </div>


                <div className={styles.socials}>
                    <a rel="noreferrer" href={"https://www.linkedin.com/company/counselpath/"} target="_blank">
                        <Icon icon={"linkedin"}/>
                    </a>
                </div>

            </footer>
        </div>

    </footer>
);

export default Footer;
