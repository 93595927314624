import React, {ReactNode, forwardRef, memo} from 'react';
import styles from "./badge.module.scss";
import clsx from 'clsx';

type BadgeProps = {
    readonly disabled?: boolean;
    readonly className?: string;
    readonly children: ReactNode;
    readonly theme?: "primary" | "light";
};

export const Badge = memo<BadgeProps>(
    forwardRef(
        (
            {
                theme = "primary",
                disabled = false,
                className = "",
                children,
                ...props
            },
            ref,
        ) => {

            return (
                <div
                    className={clsx(styles.badge, className, {
                        [styles.badgeDisabled]: disabled
                    })}
                >
                    {children}
                </div>

            );
        })
);
