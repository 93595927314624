import React, {SyntheticEvent, forwardRef, memo} from 'react';
import styles from "./radioBars.module.scss";
import clsx from 'clsx';
import {Status} from "../status/Status";
import {FieldValueType} from "ts/typings/types";

type RadioBarsProps = {
    readonly id: string;
    readonly name: string;
    readonly ariaLabel?: string;
    readonly ariaDescribedby?: string;
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly hasError?: boolean;
    readonly errorMessage?: string;
    readonly isRequired?: boolean;
    readonly inputProps?: any;
    readonly onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
    readonly appearance?: "primary";
    readonly className?: string;
    readonly checked?: string;
    readonly defaultValue?: FieldValueType;
    readonly selected?: FieldValueType;
    readonly choices: Array<{
        value: string | number | undefined,
        label: string,
    }>;
};

export const RadioBars = memo<RadioBarsProps>(
    forwardRef(
        (
            {
                disabled = false,
                isRequired = false,
                hasError,
                onChange,
                className,
                id,
                selected,
                errorMessage,
                name,
                choices,
                defaultValue,
                ...props
            },
            ref,
        ) => {

            function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
                if (onChange) {
                    onChange(e);
                }

            }



            return (
                <div
                    className={clsx(styles.wrapper, {
                        [styles.wrapperSelected]: !!selected,
                    })}
                >
                    {
                        choices &&
                        choices.map(choice => {
                            return (
                                <div key={"key_"+choice.value} className={styles.field}>
                                    <input
                                        id={"_"+name+"_"+choice.value}
                                        className={clsx(styles.radio, className, {
                                            [styles.radioDisabled]: disabled,
                                            [styles.radioError]: hasError,
                                        })}
                                        onChange={(e) => handleOnChange(e)}
                                        disabled={disabled}
                                        defaultChecked={defaultValue === choice.value}
                                        //@ts-ignore
                                        ref={ref}
                                        type={"radio"}
                                        name={name}
                                        value={choice.value}
                                        {...props}
                                    />

                                    <label className={styles.label} htmlFor={"_"+name+"_"+choice.value}>
                                        {choice.label}
                                        <div className={styles.check} />
                                    </label>

                                </div>
                            )
                        })
                    }
                    {
                        hasError && errorMessage &&
                            <Status type={"error"} message={errorMessage} />
                    }
                </div>

            );
        })
);
