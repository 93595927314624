import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {initialState} from "./initialState";
import {RequestUserRegisterInterface, ResponseLinkedinProfileInterface} from "../../interfaces/api";
import {ErrorInterface} from "../../interfaces";

export const preregistrationSlice = createSlice({
    name: 'preregistration',
    initialState,
    reducers: {
        updateAccountValue: (state, action: PayloadAction<{
            name: string,
            value: string
        }>) => {
            state.account = {
                ...state.account,
                [action.payload.name]: action.payload.value
            }
        },
        updateInvitationCode: (state, action: PayloadAction<string>) => {
            state.invitationCode = action.payload
        },
        updateAccountValues: (state, action: PayloadAction<RequestUserRegisterInterface>) => {
            state.account = {
                ...state.account,
                ...action.payload
            };
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        invitationCodeIsValid: (state, action: PayloadAction<boolean>) => {
            state.invitationCodeIsValid = action.payload;
        },
        addError: (state, action: PayloadAction<ErrorInterface>) => {
            state.errors[action.payload.name] = action.payload;
        },
        removeError: (state, action: PayloadAction<ErrorInterface>) => {
            delete state.errors[action.payload.name];
        },
        setVerificationByLinkedinStatus: (state, action: PayloadAction<{
            status: boolean,
            type: "linkedin"
        }>) => {
            state.account.verification.linkedin.status = action.payload.status;
        },

        setVerificationByLinkedinProfile: (state, action: PayloadAction<ResponseLinkedinProfileInterface>) => {
            state.account.verification.linkedin.data = {
                ...action.payload
            }
        },

        setVerificationLinkedinCode: (state, action: PayloadAction<{
            code: string | null,
            type: "linkedin"
        }>) => {
            state.account.verification.linkedin.code = action.payload.code;
        },

        setVerificationByFile: (state, action: PayloadAction<{
            status: boolean,
            type: "file"
        }>) => {
            state.account.verification.file = {
                status: action.payload.status,
            };
        },
    },
});
export const {
    updateAccountValue,
    updateAccountValues,
    setIsLoading,
    invitationCodeIsValid,
    addError,
    removeError,
    setVerificationLinkedinCode,
    setVerificationByLinkedinProfile,
    setVerificationByLinkedinStatus,
    setVerificationByFile,
    updateInvitationCode,
} = preregistrationSlice.actions;

export const selectState = (state: RootState) => state.preregistration;
export const selectAccount = (state: RootState) => state.preregistration.account;
export const selectIsLoading = (state: RootState) => state.preregistration.isLoading;
export const selectInvitationCodeIsValid = (state: RootState) => state.preregistration.invitationCodeIsValid;
export const selectInvitationCode = (state: RootState) => state.preregistration.invitationCode;
export const selectError = (state: RootState) => state.preregistration.errors;
export const selectLinkedinVerificationStatus = (state: RootState) => state.preregistration.account.verification.linkedin.status;
export const selectLinkedinVerificationCode = (state: RootState) => state.preregistration.account.verification.linkedin.code;
export const selectFileVerificationStatus = (state: RootState) => state.preregistration.account.verification.file.status;

export default preregistrationSlice.reducer;
