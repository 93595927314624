import {VacancyInterface} from "../../interfaces/VacancyInterface";

export interface BaseState {
    all: Array<VacancyInterface>;
    matched: Array<number>;
    isLoading: boolean;
    isLoadingSingle: boolean;
}

export const initialState: BaseState = {
    all: [],
    matched: [],
    isLoading: false,
    isLoadingSingle: false,
};
