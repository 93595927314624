import type {ReactNode} from 'react';
import React from "react";
import styles from "./box.module.scss";
import clsx from "clsx";

type TemplateAsideProps = {
    readonly children: ReactNode;
    readonly size?: "full";
    readonly className?: string;
};

export const Box = ({children, size, className}: TemplateAsideProps) => {
    return (
        <div className={clsx(styles.box, className, {
            [styles.boxSizeFull]: size === "full"
        })}>
            {children}
        </div>
    );
}
