import {UserInterface} from "../../interfaces";
import {UserFields} from "../../interfaces/UserFields";

export interface BaseState {
    data?: UserInterface,
    isAuth: boolean,
    fields: UserFields
}

export const initialState: BaseState = {
    isAuth: false,
    fields: {}
};
