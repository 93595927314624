import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector, useDocumentTitle} from "../../../hooks";
import {selectUser} from "../../../store/user/userSlice";
import {Link, Navigate} from "react-router-dom";
import {routes} from "../../../config/routes";
import {
    selectVacanciesAll,
    selectVacanciesIsLoading,
    selectVacanciesMatched
} from "../../../store/vacancies/vacanciesSlice";
import {getVacanciesAll} from "../../../store/vacancies/vacanciesApi";
import {Col, Row} from 'react-grid-system';
import {VacancyCard} from "../../partials/vacancyCard/VacancyCard";
import StickyBox from "react-sticky-box";
import MenuVacancies from "../../partials/menuVacancies/MenuVacancies";
import Skeleton from 'react-loading-skeleton';
import {Box} from "../../partials/box/Box";
import {Typography} from "../../ui/typography/Typography";
import styles from "./vacanciesPage.module.scss";
import picture from "../../../../img/illustrations/woman_read.svg";

type VacanciesPageProps = {
    readonly matched: boolean
}

export function VacanciesPage ({
                                   matched = false,
                             }: VacanciesPageProps) {

    useDocumentTitle("Vacancies - Counselpath");

    const user = useAppSelector(selectUser);
    const allVacancies = useAppSelector(selectVacanciesAll);
    const allMatchedVacancies = useAppSelector(selectVacanciesMatched);
    const isLoading = useAppSelector(selectVacanciesIsLoading);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // closeTabAlert();
    }, []);

    useEffect(() => {
        dispatch(getVacanciesAll());
    }, []);

    if (user) {
        return (
            <div>
                <Row>
                    <Col sm={3}>
                        <StickyBox offsetTop={20} offsetBottom={20}>
                            <MenuVacancies
                                all={allVacancies.length || 0}
                                matched={allMatchedVacancies.length || 0}
                            />
                        </StickyBox>
                    </Col>
                    <Col sm={9}>
                        {
                            isLoading && (allVacancies.length === 0 || allVacancies.length === 0) &&
                            [...Array(6)].map(((item, i) => <Skeleton key={"_skeleton"+i} height={206} delay={i*0.2} className={"skeleton"} />))
                        }
                        {
                            !matched &&
                            <>
                                {
                                    allVacancies.map((vacancy => <VacancyCard key={vacancy.id} vacancy={vacancy} />))
                                }
                                {
                                    !isLoading && allVacancies.length < 1  &&
                                    <p>Looks like at this moment there aren’t any opportunities.</p>
                                }
                            </>

                        }
                        {
                            matched &&
                            <>
                                {
                                    allMatchedVacancies.map((vacancy => <VacancyCard key={vacancy.id} vacancy={vacancy} />))
                                }
                                {
                                    !isLoading && allMatchedVacancies.length < 1 &&
                                    <Box size={"full"} className={styles.box}>
                                        <img src={picture} className={styles.boxImage} alt={"No opportunities"}/>

                                        <Typography align={"center"} margin={0} as={"p"}>
                                            Looks like at this moment there aren’t any opportunities matching your exact preferences. <br />We recommend to check back in few days as we’re continuously adding new opportunities. <br />You can also check <Link to={routes.dashboard.opportunities.all}>All opportunities</Link> to see if something may be of interest.</Typography>
                                    </Box>
                                }
                            </>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
    return <Navigate to={routes.login.base} replace={true}/>

}

export default VacanciesPage;
