import React, {useEffect, useState} from "react";
import {QuestionInterface} from "ts/interfaces";
import {useAppDispatch, useAppSelector} from "ts/hooks";
import {
    selectUserFieldPractices,
    selectUserFieldPracticesDescription,
    setUserFieldPractices,
    setUserFieldPracticesDescription,
    setUserFieldPracticesEmpty
} from "ts/store/user/userSlice";
import {TermInterface} from "../../../../../interfaces/TermInterface";
import styles from "./questionEight.module.scss";
import {Pill} from "../../../../ui/pill/Pill";
import {Textarea} from "../../../../ui/textarea/Textarea";
import clsx from "clsx";
import {TermStateInterface} from "../../../../../interfaces/TermStateInterface";
import {decode} from "html-entities";
import {Icon} from "../../../../ui/icon/Icon";
import {Accordion} from "../../../../ui/accordion/Accordion";

type QuestionEightProps = {
    question: QuestionInterface,
    onValid: (isValid: boolean) => void,
    fieldSlug?: string,
};

export const QuestionEight = ({
                                  onValid,
                                  question,
                                  fieldSlug = question.fieldSlug
                              }: QuestionEightProps) => {

    const [groups, setGroups] = useState<Array<{
        label: string | null,
        children: Array<TermInterface>
    }>>([]);

    const values = useAppSelector(selectUserFieldPractices) || [];
    const valueDescription = useAppSelector(selectUserFieldPracticesDescription) || "";
    const [currentParent, setCurrentParent] = useState<number | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        onValid(values.length >= 1);
    }, [values]);


    function handleChange(term: TermInterface) {

        if (currentParent && currentParent !== term.parent) {
           dispatch(
               setUserFieldPracticesEmpty()
           );
        }

        setCurrentParent(term.parent);

        dispatch(
            setUserFieldPractices(
                {
                    term_id: term.term_id,
                    name: term.name
                }
            )
        );
    }

    function handleChangeDescription(e: React.ChangeEvent<HTMLTextAreaElement>) {
        dispatch(
            setUserFieldPracticesDescription(e.target.value)
        )
    }

    useEffect(() => {
        const block = question.type[0];
        const blockTag = block.tag;
        const ch = blockTag ? block[blockTag] || [] : [];
        const t: Array<{
            label: string | null,
            children: Array<TermInterface>
        }> = [];

        ch.forEach((term) => {
            if (term.parent === 0) {
                const c: Array<TermInterface> = [];

                ch.forEach(termChild => {
                    if (termChild.parent === term.term_id) {
                        c.push(termChild);
                    }
                });

                t.push({
                    label: term.name,
                    children: [...c]
                })
            }
        });

        if (values.length > 0) {
            const firstValue = values[0];

            ch.forEach((term) => {
                if (term.term_id === firstValue.term_id ) {
                    setCurrentParent(term.parent);
                }
            });
        }

        setGroups(t);

    }, [question]);

    return (
        <div>
            {
                groups.map(({label, children}, index) => {
                    return (
                        <AccordionOld
                            key={index}
                            terms={children}
                            label={label || ""}
                            fieldSlug={fieldSlug}
                            handleChange={handleChange}
                            selected={values}
                        />
                    )
                })
            }

            <div className={styles.field}>
                <Textarea
                    maxLength={500}
                    id={fieldSlug + "_description"}
                    name={fieldSlug + "_description"}
                    onChange={handleChangeDescription}
                    defaultValue={valueDescription}
                    placeholder={"Optional: provide further detail on your practice specialisation (max 500 characters)"}
                />
            </div>

        </div>
    );
}

const AccordionOld = ({
                       terms,
                       label,
                       fieldSlug,
                       handleChange,
                       selected
                   }: {
    terms: Array<TermInterface>,
    label: string,
    fieldSlug: string,
    selected: Array<TermStateInterface>
    handleChange: (term: TermInterface) => void
}) => {

    const [done, setDone] = useState(false);

    useEffect(() => {
        let is = false;
        selected.map(item => {
            if (!is) {
                is = terms.filter(termLocal => termLocal.term_id === item.term_id).length > 0
            }
        })
        setDone( is );
        // console.log(selected);
    }, [selected])

    return (

        <Accordion label={
            <>
                {
                    done &&
                    <div className={styles.accordionBarCheck}>
                        <Icon icon={"check"} />
                    </div>
                }
                <span  className={clsx(
                    styles.accordionBarLabel, {
                        [styles.accordionBarLabelDone]: done
                    }
                )}>
                    {
                        decode(label)
                    }
                </span>
            </>
        }>
            <div
                className={clsx(
                    styles.accordionPills
                )}>
                <p className={styles.accordionDescription}>Please select all that apply</p>
                <div className={styles.accordionPillsList}>
                    {
                        terms.map((term: TermInterface) => {
                            return (
                                <Pill
                                    key={term.term_id}
                                    id={fieldSlug + "_" + term.term_id}
                                    name={fieldSlug}
                                    // defaultChecked={selected.findIndex(_item => _item.term_id === term.term_id) > -1}
                                    checked={selected.findIndex(_item => _item.term_id === term.term_id) > -1}
                                    label={decode(term.name)}
                                    value={term.term_id}
                                    onChange={() => handleChange(term)}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </Accordion>
    )
}
