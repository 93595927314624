export enum USER_STATUS {
    new = "new",
    activated = "activated",
    rejected = "rejected",
}

export enum VACANCY_TYPE {
    internal = "internal",
    external = "external",
}
