import React, {useState} from 'react';
import {Modal} from "../../ui/modal/Modal";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectModals} from "../../../store/base/baseSlice";
import {Typography} from "../../ui/typography/Typography";
import {Input} from "../../ui/input/Input";
import {Button} from "../../ui/button/Button";
import {useForm} from "react-hook-form";
import {requestUserResetPassword} from "../../../store/user/userApi";
import styles from "../../pages/login/login.module.scss";
import {emailValidation} from "../../../utils/validations";
import {Status} from "../../ui/status/Status";

interface PasswordResetInterface {
    emailResetPassword: string
}

const ModalResetPassword: React.FC = () => {

    const modals = useAppSelector(selectModals);
    const [isLoading ,setIsLoading] = useState(false);
    const [success ,setSuccess] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm<PasswordResetInterface>({
        mode: "onSubmit"
    });
    const dispatch = useAppDispatch();

    function onSuccess () {
        setSuccess(true);
        setIsLoading(false);
    }

    const onSubmit = (data: PasswordResetInterface) => {
        setIsLoading(true);

        dispatch(
            requestUserResetPassword(data.emailResetPassword, onSuccess, () => {})
        );
    };

    return (
        <Modal
            isOpen={modals.passwordReset ?? false}
            modalName={"passwordReset"}
            title={"Forgot your password?"}
            showCancel={true}
            showClose={true}
            cta={
                <Button
                    disabled={isLoading || success}
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}>
                    Submit
                </Button>
            }
        >
            {/*<Typography as={"h4"}>Provide an email & get the password reset link.</Typography>*/}
            <Typography as={"p"} margin={4}>Please type your registered email address below to reset your password.</Typography>

            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <Input
                    {...register(
                        "emailResetPassword",
                        {
                            required: "Field is required",
                            validate: {
                                positive: v => emailValidation(v),
                            },
                        }
                    )}
                    isRequired={true}
                    label={"E-mail"}
                    id={"emailResetPassword"}
                    name={"emailResetPassword"}
                    type={"email"}
                    autoComplete={"on"}
                    hasError={!!errors.emailResetPassword}
                    errorMessage={errors.emailResetPassword?.message}
                />
                {
                    success &&
                        <Status type={"success"} message={"Password reset requested"} />
                }
            </form>
        </Modal>
    );

}

export default ModalResetPassword;
